<script lang="ts" setup>
import {
  defineProps,
  type PropType
} from 'vue';
import {ButtonTypeEnum} from '@/constants/enums';

const props = defineProps({
  alignWText: {
    type: Boolean,
    default: false
  },
  color: {
    type: String
  },
  disable: {
    type: Boolean
  },
  href: {
    type: String
  },
  icon: {
    type: String
  },
  iconRight: {
    type: String
  },
  label: {
    type: String,
  },
  noCaps: {
    type: Boolean,
    default: true
  },
  replace: {
    type: Boolean
  },
  square: {
    type: Boolean,
    default: true
  },
  target: {
    type: String
  },
  to: {
    type: String as PropType<string | object>
  },
  /* Controls the Button `type`. Button `type` gets nullified if "href" or "to" prop is used. Options are:
   * "button"
   * "reset"
   * "submit"
   */
  type: {
    type: String as PropType<ButtonTypeEnum>,
    default: ButtonTypeEnum.button
  }
});
</script>

<template>
  <q-btn :class="[
           'q-btn-link',
           { 'q-btn-link--text': props.alignWText }
         ]"
         :disable="props.disable"
         :href="props.href"
         :icon="props.icon"
         :icon-right="props.iconRight"
         :label="props.label"
         :no-caps="props.noCaps"
         :replace="props.replace"
         :ripple="false"
         :square="props.square"
         :target="props.target"
         :text-color="props.color"
         :to="props.to"
         :type="props.type"
         unelevated>
    <slot name="default" v-if="$slots.default" />
  </q-btn>
</template>

<style scoped lang="scss">
@use "sass:math";

.q-btn-link { // .q-btn
  font-weight: map-get($text-weights, regular);
  font-size: map-get($font-sizes, '18');
  line-height: math.div(map-get($line-heights, '24'), map-get($font-sizes, '18'));
  color: map-get($co-brand-clrs, content-trg-secondary-def);
  text-decoration: none;
  padding: 0;

  :deep(.q-icon) {
    line-height: inherit;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.text-weight-bold {
    font-weight: map-get($text-weights, bold)
  }

  &.text-underline {
    text-decoration: underline;
  }

  // Text
  // Make the `<button>` text align baseline with a line of text.
  &--text { // .q-btn, .q-btn-link
    vertical-align: baseline;
  }
}
</style>
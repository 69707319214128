<script setup lang="ts">
import {
  defineModel,
  defineProps,
  type PropType
} from 'vue';
import { CustomRadioVariantEnum } from 'src/constants/enums';

const props = defineProps({
  id: {
    type: [String, Number],
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  radioVariation: {
    type: String as PropType<CustomRadioVariantEnum>,
    default: CustomRadioVariantEnum.default,
  },
  value: {
    type: [String, Number],
  },
});

const syncValue = defineModel();
</script>

<template>
  <q-radio v-model="syncValue"
           :class="props.radioVariation"
           color="secondary"
           :id="props.id"
           :name="props.name"
           :val="props.value">
    <div v-html="label"/>
  </q-radio>
</template>

import { type Svg } from '@/types/assets';

export const ankle: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path fill-rule="evenodd" d="M21 12V16.6508C21 17.7179 20.2285 18.6286 19.176 18.804C18.3972 18.9338 17.6447 19.1895 16.9481 19.561L11.6029 22.4118C10.8788 22.798 10.0707 23 9.25 23H8C6.89543 23 6 23.8954 6 25C6 26.1046 6.89543 27 8 27H8.5L9.65248 26.7119C10.206 26.5735 10.7875 26.5958 11.3288 26.7763L12.2211 27.0737C13.0659 27.3553 13.9568 27.4731 14.8458 27.4208L20.3047 27.0997C21.4329 27.0334 22.5646 27.0627 23.6878 27.1875L26.5 27.5H27.6981C30.074 27.5 32 25.574 32 23.1981C32 22.7357 31.9255 22.2764 31.7792 21.8377L30.9618 19.3855C30.656 18.4679 30.5 17.5068 30.5 16.5395V12H21ZM23.75 19.5C23.75 18.5335 24.5335 17.75 25.5 17.75C26.4665 17.75 27.25 18.5335 27.25 19.5C27.25 20.4665 26.4665 21.25 25.5 21.25C24.5335 21.25 23.75 20.4665 23.75 19.5ZM25.5 16.25C23.7051 16.25 22.25 17.7051 22.25 19.5C22.25 21.2949 23.7051 22.75 25.5 22.75C27.2949 22.75 28.75 21.2949 28.75 19.5C28.75 17.7051 27.2949 16.25 25.5 16.25Z" />',
  viewBox: '0 0 40 40'
}

export const foot: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M26.8117 13.5124C26.9199 11.9832 26.5577 11.3652 26.2132 11.1903C25.7008 10.9302 25.4467 11.0024 25.3133 11.1584C25.3971 10.8062 25.3845 10.4346 25.2719 10.084L25.2315 9.95824C25.1335 9.6531 24.8952 9.41341 24.5907 9.31364L24.3457 9.23341L24.0528 9.23267C23.7312 9.23187 23.4289 9.38578 23.2403 9.64628L23.1809 9.72824C23.3778 9.35382 23.5353 8.49266 22.5904 8.04335C21.6455 7.59405 21.0156 8.23056 20.8188 8.60498C20.8188 8.04335 20.677 6.95258 19.6558 6.72282C18.4081 6.44211 18.0629 7.29451 17.8661 7.48172C17.2755 5.79684 14.9134 5.23521 13.7323 7.48172C13.0475 8.78417 13.3553 10.0595 13.8014 10.9096C14.0443 11.3726 14.0338 11.9613 13.7559 12.4041C12.1842 14.9087 13.3056 18.4644 14.9134 21.5225C16.3307 24.2183 15.1102 26.7643 14.3228 27.7004C13.5354 28.8236 12.5512 31.5195 14.9134 33.3167C15.9368 34.0953 17.145 34.129 18.2978 33.8105C21.0664 33.0454 22.705 30.3512 24.0905 27.8351L25.0233 26.141C26.7026 23.0913 27.3371 19.5757 26.83 16.1315C26.7024 15.2653 26.7499 14.3857 26.8117 13.5124Z" />',
  viewBox: '0 0 40 40'
}

export const heart: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M30.1336 22.8096L21.0977 31.8662C20.4891 32.4549 19.5059 32.4549 18.9441 31.8662L9.8614 22.8096C7.23959 20.1831 7.38004 15.836 10.3296 13.3907C12.8578 11.2624 16.7437 11.6699 19.0846 13.9794L20.0209 14.9303L20.9105 13.9794C23.2514 11.6699 27.0904 11.2624 29.7123 13.3907C32.615 15.836 32.7554 20.1831 30.1336 22.8096Z" />',
  viewBox: '0 0 40 40'
}

export const hip: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M20.304 23.8697C18.6719 23.4413 17.4679 21.956 17.4679 20.1894C17.4679 18.0882 19.1713 16.3848 21.2725 16.3848C22.349 16.3848 23.3211 16.8319 24.0132 17.5505C24.8406 16.4485 25.3308 15.0788 25.3308 13.5947C25.3308 9.95256 22.3783 7 18.7361 7C16.2128 7 14.0205 8.41716 12.9117 10.499C12.0767 12.0667 12.6935 13.9155 13.6787 15.3934L13.8737 15.6859C14.0591 15.9641 14.3714 16.1312 14.7057 16.1312H15.8359C16.5971 16.1312 17.2142 16.7483 17.2142 17.5095C17.2142 17.9375 17.1146 18.3596 16.9232 18.7424L15.6545 21.2798C15.0231 22.5426 13.9723 23.5467 12.6821 24.1201L11.6027 24.5999C10.9158 24.9052 10.7878 25.8253 11.3652 26.3064L12.1255 26.94C12.8037 27.5052 13.6008 27.9099 14.4573 28.1241C16.1011 28.535 17.8224 27.8402 18.7204 26.4034L20.304 23.8697Z" />\n' +
    '<path d="M21.5925 23.9808C21.6496 24.3827 21.7369 24.7809 21.8543 25.1722L22.1103 26.0256C22.5589 27.5209 22.6166 29.1062 22.2779 30.6302L21.7798 32.8716H24.8236V29.7878C24.8236 28.8064 24.9818 27.8313 25.2921 26.9003L26.8914 22.1025C27.3503 20.7257 26.2854 19.4446 25.0006 19.4263C25.0508 19.6728 25.0772 19.9281 25.0772 20.1894C25.0772 22.1829 23.544 23.8183 21.5925 23.9808Z" />\n' +
    '<path d="M18.9897 20.1894C18.9897 18.9287 20.0118 17.9067 21.2725 17.9067C22.5333 17.9067 23.5553 18.9287 23.5553 20.1894C23.5553 21.4502 22.5333 22.4722 21.2725 22.4722C20.0118 22.4722 18.9897 21.4502 18.9897 20.1894Z" />',
  viewBox: '0 0 40 40'
}

export const injection: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M19.2308 8.46154C19.2308 8.20664 19.4374 8 19.6923 8C19.9472 8 20.1538 8.20664 20.1538 8.46154V12.6154H21.5385V13.5385H21.9615C22.2377 13.5385 22.4615 13.7623 22.4615 14.0385V24.6154C22.9713 24.6154 23.3846 25.0287 23.3846 25.5385C23.3846 26.0483 22.9713 26.4615 22.4615 26.4615H20.6154V30.6154H21.7692C22.1516 30.6154 22.4615 30.9253 22.4615 31.3077C22.4615 31.69 22.1516 32 21.7692 32H17.6154C17.233 32 16.9231 31.69 16.9231 31.3077C16.9231 30.9253 17.233 30.6154 17.6154 30.6154H18.7692V26.4615H16.9231C16.4133 26.4615 16 26.0483 16 25.5385C16 25.0287 16.4133 24.6154 16.9231 24.6154V14.0385C16.9231 13.7623 17.1469 13.5385 17.4231 13.5385H17.8462V12.6154H19.2308V8.46154Z" />',
  viewBox: '0 0 40 40'
}

export const knee: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 7L10 17.8841C11.5373 20.2464 14.2573 21.562 17.0636 21.3006L19.375 21.0854C18.5732 23.1388 18.6006 25.4233 19.4514 27.4569L21.875 33.25H30V19.774C30 16.2558 28.0302 13.0338 24.8988 11.4299L16.25 7ZM24.6774 14.6327C24.1888 14.4571 23.6538 14.7205 23.4824 15.2209C23.311 15.7214 23.5681 16.2695 24.0566 16.4451C25.1741 16.8468 25.9376 17.9314 25.9376 19.1647C25.9376 19.6951 26.3573 20.1251 26.8751 20.1251C27.3928 20.1251 27.8126 19.6951 27.8126 19.1647C27.8126 17.1094 26.5405 15.3024 24.6774 14.6327Z" />',
  viewBox: '0 0 40 40'
}

export const lowerExtremity: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M17.6154 30.7692C18.6766 31.8305 19.8141 31.9767 20.6334 31.9968C20.836 32.0018 21 31.8357 21 31.6331C21 31.483 20.9086 31.3481 20.7693 31.2923L19.4615 30.7692L17.5386 28.3656C17.2291 27.9787 17.2496 27.4235 17.5867 27.0605L24.3038 19.8267C24.741 19.3559 24.9279 18.7019 24.72 18.0939C24.351 17.0152 23.726 15.6275 23.1538 14.7692C21.9231 12.9231 21.3077 11.4872 21.3077 10.4615V8H15.1538C14.9487 8.82051 14.6615 10.7077 15.1538 11.6923C15.7692 12.9231 16.3846 12.9231 17 14.7692C17.4923 16.2462 19.2564 17.8462 20.0769 18.4615C19.4615 18.8718 18.1077 20.1846 17.6154 22.1538C17 24.6154 15.1538 26.4615 14.5385 27.0769C13.9231 27.6923 13.3077 28.9231 15.1538 29.5385C16.6308 30.0308 17.4103 30.5641 17.6154 30.7692Z" />',
  viewBox: '0 0 40 40'
}

export const shoulder: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path fill-rule="evenodd" d="M27 25.5L26 30H11V13.8421C11.1163 13.8234 11.2337 13.8074 11.352 13.7942L12.5 13.6667C13.354 13.5718 14 12.85 14 11.9908V9H18.5H23V11.9908C23 12.85 23.646 13.5718 24.5 13.6667L25.648 13.7942C29.2642 14.196 32 17.2526 32 20.891C32 21.2965 31.9712 21.7014 31.9139 22.1028L31.6508 23.9447C31.5504 24.6473 31.5 25.3562 31.5 26.066V30H27V25.5ZM24.75 19.5C24.75 18.5335 25.5335 17.75 26.5 17.75C27.4665 17.75 28.25 18.5335 28.25 19.5C28.25 20.4665 27.4665 21.25 26.5 21.25C25.5335 21.25 24.75 20.4665 24.75 19.5ZM26.5 16.25C24.7051 16.25 23.25 17.7051 23.25 19.5C23.25 21.2949 24.7051 22.75 26.5 22.75C28.2949 22.75 29.75 21.2949 29.75 19.5C29.75 17.7051 28.2949 16.25 26.5 16.25Z" />',
  viewBox: '0 0 40 40'
}

export const spine: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9181 28.6841C12.5908 29.3551 12.7137 30.1588 13.2267 30.7013C14.0723 31.5957 15.5471 31.3912 16.1174 30.3004L16.4092 29.7424C16.5883 29.3999 16.8622 29.1179 17.1961 28.9288C17.2213 29.0092 17.2364 29.0926 17.2407 29.177L17.3312 30.9264C17.3631 31.5435 17.9415 31.9843 18.5449 31.8514L26.1899 30.1675C26.7934 30.0346 27.1331 29.3916 26.9028 28.8182L26.2498 27.1926C26.2087 27.0902 26.1848 26.9817 26.1791 26.8715L26.0887 25.1221C26.0567 24.505 25.4784 24.0642 24.8749 24.1971L18.2556 25.6551C17.7499 25.2289 17.0423 25.1156 16.4224 25.376L15.7773 25.6471C14.6601 26.1165 13.7515 26.9756 13.2203 28.0647L12.9181 28.6841Z" />\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3882 18.6759C11.9242 19.2608 11.8714 20.0721 12.2556 20.7122C12.889 21.7676 14.3733 21.8851 15.1649 20.9425L15.5699 20.4604C15.8185 20.1644 16.1466 19.9479 16.5133 19.8351C16.5207 19.919 16.5175 20.0037 16.5036 20.087L16.2156 21.815C16.114 22.4245 16.584 22.9794 17.202 22.9794H25.0302C25.6482 22.9794 26.1182 22.4245 26.0166 21.815L25.7286 20.087C25.7105 19.9782 25.7105 19.8671 25.7286 19.7582L26.0166 18.0303C26.1182 17.4207 25.6482 16.8659 25.0302 16.8659H18.2522C17.8501 16.3409 17.1834 16.078 16.522 16.199L15.8336 16.325C14.6417 16.5431 13.5695 17.1866 12.8165 18.136L12.3882 18.6759Z" />\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1334 9.53545C13.5767 10.0329 13.3866 10.8234 13.6563 11.5195C14.101 12.6673 15.5436 13.0356 16.484 12.2415L16.9651 11.8353C17.2605 11.5858 17.6206 11.4283 18.0012 11.3796C17.9942 11.4636 17.9766 11.5464 17.9488 11.6262L17.371 13.28C17.1672 13.8633 17.536 14.4901 18.1449 14.5952L25.859 15.927C26.4679 16.0322 27.0255 15.5654 27.0291 14.9474L27.0393 13.1957C27.0399 13.0853 27.0588 12.9758 27.0952 12.8717L27.673 11.2179C27.8768 10.6345 27.508 10.0078 26.8991 9.90267L20.2199 8.74952C19.913 8.16381 19.3008 7.79128 18.6284 7.79802L17.9286 7.80504C16.7169 7.81718 15.5509 8.26893 14.6473 9.07628L14.1334 9.53545Z" />',
  viewBox: '0 0 40 40'
}

export const upperExtremity: Svg = {
  vectorPaths: '<!-- Copyright 2022 Zimmer Biomet  -->\n' +
    '<path d="M14.5817 20.9349C14.5817 16.2485 15.7426 15.7278 16.0743 15.2071H19.0545C19.6068 15.2071 20.0545 14.7594 20.0545 14.2071V11C20.0545 10.4477 19.6068 10 19.0545 10H14.6775C14.3117 10 13.9741 10.1987 13.8058 10.5234C11.1057 15.7326 8.99726 23.6726 9 28.5786C9.00056 29.5748 9.84949 30.3077 10.8458 30.3077H30C30.5523 30.3077 31 29.86 31 29.3077V26.4086C31 25.8887 30.9106 25.3672 30.6464 24.9195C29.9001 23.655 28.3405 21.9763 26.0248 21.9763C23.2386 21.9763 21.547 23.712 21.0495 24.5799C20.8837 24.2327 19.955 23.5385 17.5668 23.5385C15.1787 23.5385 14.25 24.2327 13.5866 24.5799C13.5866 24.5799 14.5817 23.8509 14.5817 20.9349Z" />',
  viewBox: '0 0 40 40'
}
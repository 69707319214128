import { type AxiosResponse } from 'axios';
import { type ApiResponse } from 'src/types/apiTypes';
import {
  type PatientDailyStatisticType,
  type PatientDataSourcesResponse,
  type PatientProgressResponse,
  type PatientStatisticsResponse
} from 'src/types/webContracts'
import axios from 'src/api/axiosConfig'
import { ApiRoutes } from '../ApiRoutes'

export async function getPatientDataSources(): Promise<AxiosResponse<ApiResponse<PatientDataSourcesResponse>>> {
  return await axios.get(ApiRoutes.Statistics.PatientDataSources);
}

export async function getPatientMeasurementStats(startDate: string, endDate: string, measurementType: string, source: string, device: number): Promise<AxiosResponse<ApiResponse<Record<string, PatientDailyStatisticType>>>> {
  return await axios.get(ApiRoutes.Statistics.PatientMeasurementStats, { params: { startDate, endDate, measurementDataTypeNames: measurementType, source, device } });
}

export async function getPatientProgressComparison(eocId: number, metric: string, eocDeviceId?: number): Promise<AxiosResponse<ApiResponse<PatientProgressResponse>>> {
  return await axios.get(ApiRoutes.Statistics.PatientProgress, { params: { eocId, metric, eocDeviceId } });
}

export async function getPatientStatisticSummaries(source?: string, device?: number): Promise<AxiosResponse<ApiResponse<PatientStatisticsResponse>>> {
  return await axios.get(ApiRoutes.Statistics.PatientStatisticSummaries,  { params: { source, device } });
}
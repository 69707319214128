import Box from '@/components/BoxContainer.vue';
import BtnIcon from '@/components/BtnIcon.vue';
import BtnLink from '@/components/BtnLink.vue';
import BtnStandard from '@/components/BtnStandard.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import DatePickerWithValidation from '@/components/form/DatePickerWithValidation.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import InputWithValidation from '@/components/form/InputWithValidation.vue';
import MultiViewLayout from '@/components/layouts/MultiViewLayout.vue';
import NumberInputWithValidation from '@/components/form/NumberInputWithValidation.vue';
import PasswordInputWithValidation from '@/components/form/PasswordInputWithValidation.vue';
import PhoneTelNumberInput from '@/components/form/PhoneTelNumberInput.vue';
import SelectWithValidation from '@/components/form/SelectWithValidation.vue';
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import Button from '@/components/StyledButton.vue';
import Dropdown from '@/components/Dropdown.vue';
import AsyncContent from '@/components/AsyncContent.vue';

export function registerCustomComponents(app) {
  app.component('zbm-async-content', AsyncContent)
  app.component('zbm-box', Box)
  app.component('zbm-btn-icon', BtnIcon)
  app.component('zbm-btn-link', BtnLink)
  app.component('zbm-btn', BtnStandard)
  app.component('zbm-button', Button)
  app.component('zbm-checkbox', Checkbox)
  app.component('zbm-dropdown', Dropdown)
  app.component('zbm-date-picker', DatePickerWithValidation)
  app.component('zbm-dialog', DialogDefault)
  app.component('zbm-input-with-validation', InputWithValidation)
  app.component('zbm-multi-view-layout', MultiViewLayout)
  app.component('zbm-number-input-with-validation', NumberInputWithValidation)
  app.component('zbm-password-input-with-validation', PasswordInputWithValidation)
  app.component('zbm-telephone-with-validation', PhoneTelNumberInput)
  app.component('zbm-select-with-validation', SelectWithValidation)
  app.component('zbm-skeleton-loader', SkeletonLoader)
}
<script lang="ts" setup>
import {
  defineModel,
  defineProps,
  type PropType,
  ref
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import { type ValidationRule } from 'quasar';

const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off',
  },
  dark: {
    type: Boolean,
    default: false
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormElFull - sets width to `100%`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl125 - sets width to `125px`.
   * qFormEl300 - sets width to `300px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl300
  },
  label: {
    type: String
  },
  name: {
    type: String
  },
  outlined: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  lazyRules: {
    type: String as PropType<boolean | 'ondemand'>,
    default: false
  }
});

const toggleShow = ref(false);
const syncValue = defineModel<string | number | null | undefined>();

function toggleHidePassword() {
  toggleShow.value = !toggleShow.value;
}
</script>

<template>

  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}
  </label>
  
  <!-- Field -->
  <q-input v-model="syncValue" :autocomplete="props.autocomplete" :class="props.inputWidth" :dark="props.dark"
    hide-bottom-space :name="props.name" no-error-icon :outlined="props.outlined" :placeholder="placeholder"
    :rules="rules" :lazyRules="props.lazyRules" :type="toggleShow ? 'text' : 'password'">

    <!-- Eyecon -->
    <template v-slot:append>
      <q-icon @click="toggleHidePassword" class="cursor-pointer" :name="toggleShow ? 'far fa-eye-slash' : 'far fa-eye'"
        size="20px" />
    </template>
  </q-input>
</template>

<style scoped lang="scss"></style>

import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { EducationLibraryItem, RosaLibraryItem } from 'src/types/webContracts';
import { i18n } from 'src/plugins/i18n'
import _ from 'lodash';

export const useEducationLibraryStore = defineStore('library', () => {
  const preSurgeryItems: Ref<EducationLibraryItem[]> = ref([]);
  const postSurgeryItems: Ref<EducationLibraryItem[]> = ref([]);
  const rosaItems: Ref<RosaLibraryItem[]> = ref([]);

  const clear = () => {
    preSurgeryItems.value = []
    postSurgeryItems.value = []
    rosaItems.value = []
  }

  const setPreSurgeryItems = (items: Array<EducationLibraryItem>) => {
    const t = items.filter(item => item.day < 0);
    preSurgeryItems.value = _.sortBy(t, (i) => {
      return i.sequence;
    });
  }

  const setPostSurgeryItems = (items: Array<EducationLibraryItem>) => {
    const t = items.filter(item => item.day >= 0);
    postSurgeryItems.value = _.sortBy(t, (i) => {
      return i.sequence;
    });
  }

  const setRosaItems = (items: Array<RosaLibraryItem>) => {
    rosaItems.value = addStaticRosaItems(items);
  }

  const addStaticRosaItems = (items: Array<RosaLibraryItem>) => {
    //add what is ROSA
    //add the legal stuff.
    const startIndex = items.length - 1; //id is used to fetch a remote asset but for these we will simply render the values...somehow.

    const basic: RosaLibraryItem = {
      id: startIndex,
      name: i18n.global.t('rosa_robotic').toString(),
      url: 'html',
      content: i18n.global.t('rosa_robotic_content').toString()
    };

    const legal: RosaLibraryItem = {
      id: startIndex + 1,
      name: i18n.global.t('rosa_legal').toString(),
      url: 'html',
      content: i18n.global.t('rosa_legal_content').toString()
    };

    //add legal to the end
    items[items.length] = legal;

    // add basic to the front.
    items.unshift(basic);

    //try to replace this with that
    let idx = items.findIndex((item: RosaLibraryItem) => item.name === 'EducationItemimporting-KM20');
    if (idx > 0) {
      items[idx].name = i18n.global.t('rosa_before').toString();
      items[idx].content = i18n.global.t('rosa_before_content').toString();
      items[idx].url = 'html';
    } else {
      items.splice(1, 0, {
        id: items.length + 1,
        name: i18n.global.t('rosa_before').toString(),
        url: 'html',
        content: i18n.global.t('rosa_before_content').toString()
      });
    }

    //try to replace this with that
    idx = items.findIndex((item) => item.name === 'EducationItemimporting-KM21');
    if (idx > 0) {
      items[idx].name = i18n.global.t('rosa_during').toString();
      items[idx].content = i18n.global.t('rosa_during_content').toString();
      items[idx].url = 'html';
    }
    else {
      items.splice(2, 0, {
        id: items.length + 1,
        name: i18n.global.t('rosa_during').toString(),
        url: 'html',
        content: i18n.global.t('rosa_during_content').toString()
      });
    }

    return items;
  }

  return {
    preSurgeryItems,
    postSurgeryItems,
    rosaItems,

    clear,
    setPreSurgeryItems,
    setPostSurgeryItems,
    setRosaItems
  }
})
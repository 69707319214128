<template>
  <router-link class="tech-edu-lib-cat" :to="{ name: path }">
    <!-- Left -->
    <div class="tech-edu-lib-cat__left">
      <!-- Title -->
      <div class="tech-edu-lib-cat__title">
        <img :src="titleGraphic" :alt="titleGraphicAlt" />
      </div>
      <!-- Arrow -->
      <div class="tech-edu-lib-cat__arrow">
        <q-icon name="fas fa-arrow-right" size="24px" color="white" />
      </div>
    </div>
    <!-- Image -->
    <div class="tech-edu-lib-cat__image">
      <img :src="categoryGraphic" />
    </div>
  </router-link>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  path: String,
  titleGraphic: String,
  titleGraphicAlt: String,
  categoryGraphic: String,
});
</script>
<template>
  <q-page padding class="rate-routine">
    <MultiViewLayout :onBackClick="onBackClick">

      <!-- Title -->
      <template v-slot:title>
        {{ $t('routine_rate') }}
      </template>

      <!-- Heading -->
      <template v-slot:heading>
        <SkeletonLoader type="text" width="200px" height="34px" :loading="isLoading" :repeatCount="1">
          <div v-if="!isLoading">
            {{ selectedRoutine?.title }}
          </div>
        </SkeletonLoader>
      </template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- Message -->
        <div class="row">
          <div class="col">
            <p>{{ $t('routine_rate_message') }}</p>
          </div>
        </div>

        <!-- Question -->
        <div class="row rate-routine__question">
          <div class="col">
            <p>{{ $t('routine_rate_difficulty') }}</p>
          </div>
        </div>

        <!-- Slider -->
        <div class="row justify-center rate-routine__slider">
          <div class="col-auto">
            <div class="survey-slider survey-slider__default survey-slider--ltr">

              <!-- Select/Value -->
              <div class="survey-slider__select">

                <!-- Value -->
                <div v-html="displayValue" class="survey-slider__select-value"></div>
              </div>

              <!-- Slider -->
              <div class="survey-slider__slider">

                <!-- Q Slider -->
                <q-slider v-model="value" :style="'padding: 8px 30px;'" track-size="25px" thumb-size="25px"
                  track-color="dark-teal" thumb-color="accent" selection-color="transparent" :min="0" :max="4" markers
                  :tooltip="'none'" :marker-labels="arrayMarkerLabel" snap>
                </q-slider>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="row justify-center">
          <div class="col-auto">
            <zbm-btn @click="onSubmitClick" :color="$zb.enums.ButtonStdColorEnum.accent"
              :height="$zb.enums.ButtonHeightEnum.lg" :width="$zb.enums.ButtonWidthEnum.w270">
              {{ $t('gen_submit') }}
            </zbm-btn>
          </div>
        </div>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref, defineProps } from 'vue';
import { useDashboardStore, useGeneralPurposeModalStore, useSessionStore } from 'src/store';
import { getRoutine, setRoutineComplete } from 'src/api/endpoints/routineWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import type { RoutineModel, SetRoutineCompleteRequest, Task } from 'src/types/webContracts';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { getPatientStatisticSummaries } from 'src/api/endpoints/statisticsWeb';
import { getTasks } from 'src/api/endpoints/userWeb';
import { GeneralPurposeVariant } from 'src/constants/enums';
import { i18n } from 'src/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const sessionStore = useSessionStore();
const generalPurposeModalStore = useGeneralPurposeModalStore()
const dashboardStore = useDashboardStore()
const router = useRouter();
const $i18nt = i18n.global.t;
const $q = useQuasar()

const selectedRoutine = ref<RoutineModel | null>(null);
const value = ref<number | null>(null);
const isLoading = ref(true)

// JustRight, TooHeavy, TooLight, TroubleWithMotion, Injury, LovedIt, HatedIt
const arrayMarkerLabel = [
  { value: 0, label: $i18nt('routine_too_easy').toString() },
  { value: 1, label: $i18nt('routine_easy').toString(), classes: 'hidden' },
  { value: 2, label: $i18nt('routine_right').toString(), classes: 'hidden' },
  { value: 3, label: $i18nt('routine_hard').toString(), classes: 'hidden' },
  { value: 4, label: $i18nt('routine_too_hard').toString() },
];

const props = defineProps({
  tickSignature: {
    type: String,
    required: true,
  },
  itemId: {
    type: Number,
    required: true,
  },
});

const displayValue = computed(() => {
  if (value.value === null) {
    return '&nbsp;';
  } else {
    return arrayMarkerLabel.find(m => m.value == value.value)?.label;
  }
});

onMounted(async () => {
  isLoading.value = true;
  const languageId = sessionStore.user?.preferredLanguageId || 0;

  const routine = await handleApiRequest(() =>
    getRoutine(props.itemId, languageId, props.tickSignature), null, $q
  );

  selectedRoutine.value = routine?.result ?? null;
  isLoading.value = false;
});

// methods
function onBackClick() {
  router.push({
    name: 'Routine.Routine',
    params: { itemId: props.itemId.toString(), tickSignature: props.tickSignature },
  });
}

async function onSubmitClick() {
  if (value.value === null) {
    generalPurposeModalStore.setModal({
      title: $i18nt('routine_rate_your').toString(),
      template: $i18nt('routine_rate_slide').toString(),
      variant: GeneralPurposeVariant.Decision,
    });
    return;
  }

  $q.loading.show()

  const countExercisesCompleted =
    selectedRoutine.value?.exercises.filter((x) => x.isCompleted).length ?? 0;

  const rating = getCalculatedRatingValue(value.value);

  const languageId = sessionStore.user?.preferredLanguageId || 0;

  const { eventDate } =
    dashboardStore.tasks?.find((item) => item.tickSignature === props.tickSignature) ||
    ({} as Task);

  const setRoutineCompleteRequest: SetRoutineCompleteRequest = {
    languageId: languageId,
    tickSignature: props.tickSignature,
    eventDate,
    itemId: props.itemId,
    feedback: rating,
  };

  const routineCompletionResponse = await handleApiRequest(() =>
    setRoutineComplete(setRoutineCompleteRequest), null, $q
  );

  if (routineCompletionResponse?.result) {
    await updatePatientSummaries();
    await updateTasks();
    router.push({
      name: 'Routine.Completed',
      params: {
        completed: countExercisesCompleted?.toString(),
        rating: arrayMarkerLabel.find(m => (m.value ?? 0) == value.value).label
      },
    });
  }
  $q.loading.hide()
}

async function updatePatientSummaries() {
  const statsResponse = await handleApiRequest(() => getPatientStatisticSummaries(), null, $q);
  if (statsResponse) {
    dashboardStore.loadPatientWearables(statsResponse.result);
  }
}

async function updateTasks() {
  const taskResponse = await handleApiRequest(() => getTasks(), null, $q);
  if (taskResponse) {
    dashboardStore.loadTasks(taskResponse.result.tasks);
    dashboardStore.loadNextTaskDates(taskResponse.result);
  }
}

// This may need to be aligned to match with how iOS maps these values.
function getCalculatedRatingValue(value: number | null): string {
  let result = '';

  switch (value) {
    case 0:
      result = 'TooLight';
      break;
    case 1:
      result = 'LovedIt';
      break;
    case 2:
      result = 'JustRight';
      break;
    case 3:
      result = 'HatedIt';
      break;
    case 4:
      result = 'TooHeavy';
      break;

    default:
      break;
  }

  return result;
}

</script>

<style scoped lang="scss">
@use "sass:math";

.survey__slider {
  // .row

  >[class*="col"] {

    @media (max-width: 525px) {
      flex: 10000 1 0;
    }

    @media (min-width: 526px) {
      width: 480px;
    }

    @media (min-width: $breakpoint-md-min) {
      width: 500px;
    }
  }
}

.survey-slider {
  // <div>

  // Bottom to Top Specific
  &.survey-slider--btt {
    // <div>
    display: flex;
    align-items: center;
  }

  // Select/Value
  &__select {
    // <div>
    $value-font-size: 56px;

    text-align: center;

    // Value
    &-value {
      // <div>
      font-size: $value-font-size;
      line-height: 1;
      color: $secondary;
    }
  }
}
</style>
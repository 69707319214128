<script lang="ts" setup>
import { onMounted } from 'vue';
import { useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { setProcedureConsent } from 'src/api/endpoints/userWeb';
import MultiViewLayout from '@/components/layouts/MultiViewLayout.vue';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';
import Svg from '@/components/Svg.vue';
import {shieldHeart} from '@/assets';

const sessionStore = useSessionStore()
const router = useRouter()
const $q = useQuasar()


onMounted(() => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  sessionStore.setIsHeaderVisible(true);
})

const onOkayClicked = async () => {
  // Mark the "About Your Data" interrupt as completed.
  await handleApiRequest(() => setProcedureConsent(), null, $q);
  router.push({ name: 'Interrupts', params: { reload: 'false' } });
}

</script>

<template>
  <q-page id="the-about-your-data" padding>
    <MultiViewLayout :showBackArrow="false" :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
      :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.default" :box-width="$zb.enums.BoxWidthEnum.xs">

      <!-- Heading -->
      <h2>{{ $t('gen_privacy') }}</h2>

      <!-- Shield Heart Icon -->
      <div class="shield-heart-icon">
        <div class="shield-heart-circle">
          <Svg :view-box="shieldHeart.viewBox"
               :vector-paths="shieldHeart.vectorPaths" />
        </div>
      </div>

      <!-- Content -->
      <h3>{{ $t('about_your_data_header') }}</h3>
      <p>{{ $t('about_your_data_body_upper') }}</p>
      <p class="last">{{ $t('about_your_data_body_lower') }}</p>

      <!-- Okay Button -->
      <zbm-btn :color="$zb.enums.ButtonStdColorEnum.darkTeal" @click="onOkayClicked"
        :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" />
    </MultiViewLayout>
  </q-page>
</template>
<script setup lang="ts">
import {defineProps} from 'vue';
import Avatar from '@/components/Avatar.vue';

const props = defineProps({
  avatarIconBorder: {
    type: Boolean,
    default: true
  },
  avatarIconColor: {
    type: String
  },
  avatarIconFontSize: {
    type: String
  },
  avatarIconName: {
    type: String
  },
  avatarIconTopMargin: {
    type: String
  },
  avatarSize: {
    type: String,
    required: true
  },
  avatarUrl: {
    type: String
  },
  color: {
    type: String,
    required: true
  },
  hasAvatar: {
    type: Boolean,
    default: false
  },
  href: {
    type: String
  },
  is: {
    type: String,
    required: true
  },
  margin: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  nameFontSize: {
    type: String
  },
  onRight: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <component :class="['row no-wrap items-center', { 'reverse' : props.onRight }]"
             :href="props.href"
             :is="props.is">
    
    <!-- Avatar -->
    <Avatar :avatar-url="avatarUrl"
            :color="props.avatarIconColor ? props.avatarIconColor : props.color"
            :class="props.onRight ? null : props.margin"
            :display-name="props.name"
            :has-avatar-image="props.hasAvatar"
            :icon-border="props.avatarIconBorder"
            :icon-font-size="props.avatarIconFontSize"
            :icon-name="props.avatarIconName"
            :icon-top-margin="props.avatarIconTopMargin"
            :size="props.avatarSize" />

    <!-- Name -->
    <span :class="['block', props.nameFontSize, `text-${props.color}`, props.onRight ? props.margin : null]">
      {{ props.name }}
    </span>
  </component>
</template>

<style scoped lang="scss">
a:focus,
a:hover {
  
  &:not(.q-btn):not(.q-item):not(.q-tab) {
    text-decoration: none;
  }
}

.mr-22 {
  margin-inline-end: 22px;
}
</style>
<script setup lang="ts">
import { useQuasar } from 'quasar';
import {
  computed,
  defineModel,
  defineProps,
  onMounted,
  type PropType,
  ref
} from 'vue';
import { getLocalizedPolicy, updatePolicy } from 'src/api/endpoints/registrationWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { UserPolicyType } from 'src/constants/enums';
import type { UserPolicy } from 'src/types/webContracts';

const $q = useQuasar()

const props = defineProps({
  disclaimerId: {
    type: String
  },
  optOut: {
    type: Boolean
  },
  policyType: {
    type: Number as PropType<UserPolicyType>,
    required: true,
  },
  showOnActivate: {
    type: Boolean,
    default: true
  },
  titleKey: {
    type: String,
    required: true
  }
});

const showDisclaimerModal = defineModel({
  type: Boolean,
  default: false
});

const disclaimerModel = ref({
  id: 0,
  header: '',
  description: '',
  type: 'UserPolicyMessagingDisclaimer',
  consented: false,
  dismissed: false
} as UserPolicy);

const isDismissed = computed(() => disclaimerModel.value.dismissed);

async function onOkayClicked() {
  await handleApiRequest(() =>
    updatePolicy(props.policyType, props.optOut, disclaimerModel.value.dismissed), null, $q
  );
  showDisclaimerModal.value = false;
}

function show() {
  showDisclaimerModal.value = true;
}

onMounted(async () => {
  if (!disclaimerModel.value.id && props.policyType !== undefined) {
    const messagingPolicy = await handleApiRequest(() => getLocalizedPolicy(props.policyType), null, $q);
    
    if (messagingPolicy?.result) {
      let description = messagingPolicy.result.description;
      
      description = description.replace(/\n/gm, '<br /><br />');
      
      disclaimerModel.value = {
        ...messagingPolicy.result,
        description,
      };
      
      document.querySelectorAll('html')[0].scrollTop = 0;
    }
  }
  
  if (!disclaimerModel.value.dismissed && props.showOnActivate) {
    show();
  }
});

defineExpose({
  onOkayClicked,
  show,
  isDismissed,
});
</script>

<template>
  <q-dialog v-model="showDisclaimerModal"
            class="disclaimer"
            no-backdrop-dismiss
            no-esc-dismiss>
    <div>
      
      <!-- Header -->
      <div class="row justify-center mb-20">
        <div class="col-auto">
          <h3>
            <slot name="title">{{ $t(titleKey) }}</slot>
          </h3>
        </div>
      </div>
      
      <div class="row mb-20">
        <div class="col">
          <p>
            <slot name="description">
              <span v-html="disclaimerModel.description" />
            </slot>
          </p>
        </div>
      </div>
      
      <!-- Checkbox -->
      <div class="row mb-45">
        <div class="col-auto">
          <zbm-checkbox v-model="disclaimerModel.dismissed"
                        :label="$t('gen_user_policy_do_not_show')" />
        </div>
      </div>
        
      <!-- Continue Button --> 
      <div class="row justify-center">
        <div class="col-auto">
          <zbm-btn @click="onOkayClicked()"
                   :color="$zb.enums.ButtonStdColorEnum.accent"
                   :height="$zb.enums.ButtonHeightEnum.lg"
                   :label="$t('activate_continue_text')" />
        </div>
      </div>
      
    </div>
  </q-dialog>
</template>

<style lang="scss" scoped>
@use "sass:math";

.disclaimer { // .q-dialog
  
  .q-dialog__inner--minimized { // .q-dialog__inner
    
    > div {
      width: 100%;
      max-width: 700px;
      
      p {
        line-height: math.div(map-get($line-heights, '28'), map-get($font-sizes, '16'));
      }
    }
  }
}
</style>
<template>
  <zbm-async-content class="col" :loading="isLoading">
    <template v-slot:loading>
      <q-skeleton type="rect" width="100%" height="36px" class="mb-24" />
      <q-skeleton type="rect" width="250px" height="26px" class="mb-40" />

      <template v-for="i in 2" :key="i">
        <q-skeleton type="rect" width="100px" height="16px" class="mb-12" />
        <q-skeleton type="rect" width="100%" height="220px" class="mb-24" />
      </template>
    </template>
    <SubNavigationLayout :class="'the-dashboard-my-stats'" title="stats">

      <!-- More Information -->
      <template #more-info>
        <div v-if="showPIQ" class="col-auto">
          
          <!-- Button -->
          <zbm-btn-icon @click="showMoreInfo = true"
                        icon="fal fa-question-circle"
                        size="26px"
                        text-color="dark-teal" />
          
          <!-- Dialog -->
          <zbm-dialog v-model="showMoreInfo">

            <!-- Header -->
            <div class="row q-dialog__header">
              <div class="col-auto">

                <!-- Question Circle Icon -->
                <q-icon color="content-assets-hero-def"
                        name="fas fa-question-circle"
                        size="42px" />
              </div>
            </div>

            <!-- Body -->
            <div class="row q-dialog__body">
              <div class="col">
                <h3 class="text-weight-semibold q-mb-lg">{{ $t('stats_info') }}</h3>
                <div v-html="moreInfoDialogContent" class="text-left" />
              </div>
            </div>

            <!-- Footer (CTA button(s)) -->
            <div class="row q-dialog__footer">

              <!-- Okay Button -->
              <div class="col-auto">
                <zbm-btn v-close-popup
                         :color="$zb.enums.ButtonStdColorEnum.accentText"
                         :height="$zb.enums.ButtonHeightEnum.lg"
                         :label="$t('gen_okay')" />
              </div>
            </div>
          </zbm-dialog>
        </div>
      </template>

      <template v-slot:navigation>
        <div class="row">
          <div class="col-auto">

            <!-- Dropdown -->
            <zbm-dropdown v-model="selectedNavItem" class="font-size-14" :options="subNavItems" omitDefaultOption
              v-if="sources.length" />
          </div>
        </div>
      </template>

      <template v-slot:content>
        <template v-if="selectedNavItem">
          <component :is="componentName" :ready-signal="triggerGraphs" :device-id="selectedNavItem.eocDeviceId"
            :comparison-metrics="selectedNavItem.comparisonMetrics" />
        </template>
        <template v-else-if="!hasPiq || !sources.length">
          <p>{{ $t('my_stats_no_access') }}</p>
        </template>
      </template>
    </SubNavigationLayout>
  </zbm-async-content>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick, onActivated } from 'vue';
import { useRoute } from 'vue-router';
import { useDashboardStore, useGeneralPurposeModalStore, useSessionStore } from 'src/store';
import { getPatientDataSources } from 'src/api/endpoints/statisticsWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { type DataSourceResponse } from 'src/types/webContracts';
import SubNavigationLayout from 'src/components/layouts/SubNavigation/SubNavigationLayout.vue';
import { type DropdownItem } from 'src/components/PWEDropdown.vue';
import { useQuasar } from 'quasar';
import { GeneralPurposeVariant } from '@/constants/enums';
import { i18n } from '@/plugins/i18n';
import { reformatString } from '@/utils/stringUtils';
import ThePersonaIQMyStats from './ThePersonaIQMyStats.vue';
import { StatTypeEnum } from '@/constants/measurementTypes';

const $q = useQuasar()
const sessionStore = useSessionStore();
const dashboardStore = useDashboardStore()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const route = useRoute();

const props = defineProps({
  sourceType: String
})

const sources = ref<DataSourceResponse[]>([]);
const isFetchingSources = ref<boolean>(false);
const selectedNavItem = ref<DropdownItem>();
const triggerGraphs = ref(0);
const showMoreInfo = ref<boolean>(false);

const showPIQ = computed(() => {
  return props.sourceType === StatTypeEnum.PersonaIQ
});

const componentName = computed(() => {
  switch (props.sourceType) {
    case StatTypeEnum.PersonaIQ: return ThePersonaIQMyStats
  }
})

const moreInfoDialogContent = computed(() => {
  return reformatString(i18n.global.t('piq_pwe_pme_information_modal').toString());
});

const subNavItems = computed(() => {
  const array: Array<DropdownItem> = [];

  if (sources.value !== null) {
    sources.value.forEach(function (source) {
      array.push({
        label: source.localizedName,
        value: source.eocId,
        eocDeviceId: source.eocDeviceId,
        sourceType: source.sourceType,
        comparisonMetrics: source.comparisonMetrics
      });
    });
  }

  return array;
});

const isLoading = computed(() => {
  return !dashboardStore.patient || isFetchingSources.value;
});

watch(() => selectedNavItem.value, (nV) => {
  nextTick(() => triggerGraphs.value++)
})

onActivated(async () => {
  if (!selectedNavItem.value) {
    isFetchingSources.value = true;

    const dataSources = await handleApiRequest(() => getPatientDataSources(), null, $q);

    if (dataSources?.result && dataSources?.result.dataSources) {
      sources.value = dataSources.result.dataSources.filter(p => p.sourceType !== 'Wearable');

      sources.value = sources.value.sort((a, b) =>
        a.eocId === b.eocId
          ? (a.eocDeviceId || 0) - (b.eocDeviceId || 0)
          : (b.eocId || 0) - (a.eocId || 0)
      );
    }
  }

  const navItemFromRoute = subNavItems.value.find(
    (p) => p.eocDeviceId == route.params.eocDeviceId
  );

  const navItemFromEOC = subNavItems.value.find((s) => s.eocId === sessionStore.user?.selectedEocId);

  selectedNavItem.value = navItemFromRoute || navItemFromEOC || subNavItems.value[0];

  isFetchingSources.value = false;
})
</script>
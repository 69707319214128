import axios from 'src/api/axiosConfig'
import { ApiResponse } from 'src/types/apiTypes'
import {
  SurveyProgressRequest,
  SurveyProgressResponse,
  EducationCompleteRequest,
  EducationAssetResponse,
  EducationLibraryItem, RosaLibraryItem, SurveyDefinitionWrapperResponse, CanaryPatientCaregiver
} from 'src/types/webContracts'
import { AxiosResponse } from 'axios'
import { ApiRoutes } from '../ApiRoutes'

export async function getSurveyDefinition(surveyId: number, languageId: number): Promise<AxiosResponse<ApiResponse<SurveyDefinitionWrapperResponse>>> {
  const response = await axios.get(ApiRoutes.Calendar.GetSurveyDefinition, { params: { surveyId, languageId } })
  return response;
}

export async function updateSurveyProgress(request: SurveyProgressRequest): Promise<AxiosResponse<ApiResponse<SurveyProgressResponse>>> {
  const response = await axios.post(ApiRoutes.Calendar.UpdateSurveyProgress, request)
  return response;
}

//fetch an ed item
export async function getEducationAsset(eventId: number, languageId: number): Promise<AxiosResponse<ApiResponse<EducationAssetResponse>>> {
  const response = await axios.get(ApiRoutes.Calendar.GetEducationAsset, { params: { eventId, languageId } })
  return response;
}

//report the item as updated.
export async function updateEducationStatus(request: EducationCompleteRequest): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Calendar.UpdateEducationStatus, request)
  return response;
}

//fetch a list of ed items
export async function getLibraryEdItems(protocolId: number | undefined, languageId: number | undefined, procedureDate: string | undefined): Promise<AxiosResponse<ApiResponse<EducationLibraryItem[]>>> {
  const response = await axios.get(ApiRoutes.Calendar.GetLibraryEdItems, { params: { protocolId, languageId, procedureDate } })
  return response;
}

//fetch a library of rosa ed items
export async function getLibraryRosaItems(): Promise<AxiosResponse<ApiResponse<RosaLibraryItem[]>>> {
  const response = await axios.get(ApiRoutes.Calendar.GetLibraryRosaItems);
  return response;
}

//fetch a rosa video asset
export async function getRosaVideoAsset(eventId: number, languageId: number): Promise<AxiosResponse<ApiResponse<EducationAssetResponse>>> {
  const response = await axios.get(ApiRoutes.Calendar.GetRosaVideoAsset, { params: { eventId, languageId } })
  return response;
}

<template>
  <q-page padding>
    <MultiViewLayout showFooter :onBackClick="onBackClick">
      <template v-slot:heading>
        {{ $t('checkin_complete_title') }}
      </template>

      <template v-slot:default>
        <p>{{ $t('') }}</p>

        <div class="row">
          <div class="col text-center q-ma-sm">
            <q-btn flat round style="border: 2px solid" color="primary">
              <q-icon name="fas fa-check" size="30px" class="q-ma-sm" />
            </q-btn>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <p>
              {{ $t('piq_profilethankyou') }}
            </p>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
          :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
          <ProgressBar :progress="90" :nextEnabled="true" :nextTextKey="'piq_profilecomplete'"
            @on-back-click="onBackClick" @on-next-click="onNextClick" />
        </Box>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>

import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { useDashboardStore } from 'src/store';
import { TaskType } from 'src/constants/enums';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const dashboardStore = useDashboardStore()

const props = defineProps({
  finalizeSetupTaskId: {
    type: Number,
    default: 1
  }
});

onMounted(async () => {
  dashboardStore.markTaskCompleted({ id: props.finalizeSetupTaskId, taskType: TaskType.piq });
});

//  methods
function onNextClick() {
  router.push({ name: 'Dashboard' });
}

function onBackClick() {
  router.go(-1);
}

</script>
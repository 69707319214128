<template>
  <q-btn @click="buttonClick" :to="to" :label="label" no-caps no-wrap rounded outline :type="type"
         :class="[buttonStyle, buttonHeight, buttonWidth]" :disable="disabled" :icon="icon">
    <slot />
  </q-btn>
</template>

<script setup lang="ts">
  import { defineComponent } from 'vue';;
  import {
    ButtonTypeEnum,
    ButtonHeightEnum,
    ButtonWidthEnum,
  } from '@/constants/enums';

  const props = defineProps({
    label: {
      type: String,
    },
    /*
     * Controls the `type` value of the button. Options are:
     * button - sets the button type to `button`.
     * submit - sets the button type to `submit`.
     * reset - sets the button type to `reset`.
     */
    type: {
      type: String,
      default: ButtonTypeEnum.button
    },
    to: {
      type: [String, Object],
      default: ''
    },
    /*
     * Controls the color values of the button. Options are:
     * primary - sets the button style to a magenta background with white
     *           text.
     * secondary - sets the button style to a white background, with dark
     *           teal border and text.
     * secondWhite - sets the button styles to transparent background,
     * 							 with white border and text. Basically the 'secondary'
     * 							 button with color adjusment for dark backgrounds.
     * logIn - sets the button style to a dark teal background with white
     *         text and dropshadow.
     * link - sets the button style to look and behove like an anchor.
     * linkTryAgain - sets the button style to look and behave like an anchor
     *                that is the same color as content, bold and underlined.
     */
    buttonStyle: {
      type: String
    },
    /*
     * Controls the color values of the button. Options are:
     * none - does not provide any new height or font-size values.
     * sm - sets the button height to 30px tall with 14px font-size.
     * lg - sets the button height to 40px tall with 14px font-size.
     * xl - sets the button height to 44px tall with 18px font-size.
     */
    buttonHeight: {
      type: String,
      default: ButtonHeightEnum.lg
    },
    /*
     * Controls the color values of the button. Options are:
     * default - No width is set, the gets its width from text width and
     *           it's left & right padding values.
     * w270 - sets the buttons width to 270px wide.
     * w300 - sets the buttons width to 300px wide.
     */
    buttonWidth: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  });

  const emit = defineEmits(['on-click']);
  function buttonClick() {
    emit('on-click');
  }
</script>

<script lang="ts" setup>
import {
  computed,
  type PropType,
  ref
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import { type ValidationRule } from 'quasar';
import { phoneTelInput } from '@/plugins/quasar-validators';

const initialized = ref(false);
const props = defineProps({
  dark: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  inputWidth: {
    type: String as PropType<FormElWidthEnum>,
    default: FormElWidthEnum.qFormEl300
  },
  label: {
    type: String
  },
  modelValue: {
    type: Object,
    required: true
  },
  outlined: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
  },
  patientCountryCode: {
    type: String
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue'])

const syncValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!initialized.value) {
      initialized.value = true;
    } else {
      emit('update:modelValue', value)
    }
  }
})

const syncRules = [...props.rules, phoneTelInput('piq_error')];

const onInput = (number, phoneObject) => {
  syncValue.value = {
    value: number,
    phoneObject: { ...phoneObject },
  };
} 

</script>

<template>

  <!-- Label -->
  <label v-if="props.label" :for="props.for">
    {{ props.label }}
    <slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncValue.phoneObject" :class="[props.inputWidth]" :dark="props.dark" hide-bottom-space
    no-error-icon :outlined="props.outlined" :rules="syncRules" tag="div">
    <template #control>
      <vue-tel-input v-model="syncValue.value" v-bind="{
    defaultCountry: patientCountryCode,
    inputOptions: {
      type: 'tel',
      placeholder,
      showDialCode: true,
    },
    mode: 'national',
  }" @on-input="onInput" :auto-default-country="false" :disabled="props.disable" />
    </template>
  </q-field>
</template>

<style scoped lang="scss">
// QField
.q-field {

  // Native
  :deep(.q-field__native) {
    background-color: initial;
    padding-block: 0;
    border-block-end: none;
  }

  // Vue Tel Input
  :deep(.vue-tel-input) {
    width: 100%;
    background-color: $white;
    border: none;
    border-block-end: $generic-border-form;
    border-radius: 0;
    box-shadow: none;

    .vti {

      &__dropdown {
        padding: 0;
        padding-block: 8px 7px;
        padding-inline-end: 15px;

        &:hover,
        &.open {
          background-color: transparent;
        }
      }

      &__flag {
        margin-left: 0;
      }

      &__dropdown-list {
        border-color: $body-txt-clr;
        border-radius: $generic-border-radius-sm;

        &.below {
          top: 42px;
        }
      }

      &__dropdown-item {
        font-size: 1rem;

        strong {
          font-weight: map-get($text-weights, medium);
        }
      }

      &__input {
        line-height: $body-line-height;
        color: $dark-teal;
        padding: 0;
        padding-block: $field-padding-block $field-padding-block-outlined;
        padding-inline: 0;
        border-radius: 0;

        &::placeholder {
          color: $input-placeholder-clr;
          opacity: 1;
        }
      }
    }
  }

  // Outlined
  &--outlined {
    // .q-field

    // Native
    :deep(.q-field__native) {
      padding-block-start: 0;
      padding-inline: 0;
      border: none;
      border-radius: 0;
    }

    // Vue Tel Input
    :deep(.vue-tel-input) {
      border: $generic-border-form;
      border-radius: $generic-border-radius-sm;

      .vti {

        &__dropdown {
          padding-block-start: 7px;
          padding-inline-start: $field-padding-inline;
        }

        &__input {
          padding-block-start: $field-padding-block-outlined;
          padding-inline-end: $field-padding-inline;
          border-radius: 0 $generic-border-radius-sm $generic-border-radius-sm 0;

          &::placeholder {
            color: $input-placeholder-clr;
            opacity: 1;
          }
        }
      }
    }
  }

  // Auto Height
  &--auto-height {
    // .q-field

    // Labeled & Outlined
    &.q-field--labeled,
    &.q-field--outlined {
      // .q-field

      // Native
      :deep(.q-field__native) {
        padding-block-start: 0;
      }
    }
  }

  // Error
  &--error {
    // .q-field

    :deep(.vue-tel-input) {
      background-color: $notify-yellow;

      .vti__input {
        background-color: $notify-yellow;
      }
    }
  }

  // Dark
  &--dark {
    // .q-field

    // Vue Tel Input
    :deep(.vue-tel-input) {
      background-color: transparent;
      border-color: $white;

      .vti {

        &__dropdown {

          &-arrow {
            color: $white;
          }

          &-list {
            background-color: $white;
            color: $dark-teal;
            border-color: $white;
          }
        }

        &__input {
          background-color: transparent;
          color: $white;

          &::placeholder {
            color: $white !important;
          }
        }
      }
    }
  }
}
</style>
<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { UserPolicyType } from 'src/constants/enums';
import { useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { updatePolicy, getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import UserPolicySignOff from 'src/pages/Activation/components/UserPolicySignOff.vue';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();
const policyType = ref(UserPolicyType.UserPolicyGlobalConsent);
const sessionModule = useSessionStore();
const isClinicalStudyUser = computed(() => sessionModule.user?.isClinicalStudyUser);
const consentModal = ref(false);
const isLoading = ref(true);

async function onAcceptClicked(optOut: boolean) {
  $q.loading.show()
  const response = await handleApiRequest(() => updatePolicy(policyType.value, optOut), null, $q);

  let nextRoute = '';

  if (response?.result) {
    const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
    nextRoute = translateStepToRoute(step?.result as string);
    $q.loading.hide()
    router.push({
      name: nextRoute,
    });
  } else {
    $q.loading.hide()
  }
}

async function onOptOutClicked() {
  consentModal.value = false;
  onAcceptClicked(true);
}

onMounted(() => {
  isLoading.value = false;
});
</script>

<template>
  <q-page padding>
    <UserPolicySignOff :headingLocalizerKey="'consent_use_of_information'" :policyType="policyType" :progress="65">

      <!-- Actions/Progress -->
      <template #actions>
        <div class="row no-gutters items-center justify-center actions">

          <!-- Question -->
          <div class="col">{{ $t('consent_anonymization') }}</div>
          <div class="flex-br" />

          <!-- No -->
          <div v-if="!isClinicalStudyUser" class="col-auto">
            <zbm-skeleton-loader height="44px" :loading="isLoading" width="107px">
              <zbm-btn @click="consentModal = true" :color="$zb.enums.ButtonStdColorEnum.accent"
                :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_no')" />
            </zbm-skeleton-loader>
          </div>

          <!-- Yes -->
          <div class="col-auto">
            <zbm-skeleton-loader height="44px" :loading="isLoading" width="111px">
              <zbm-btn @click="onAcceptClicked(false)" :color="$zb.enums.ButtonStdColorEnum.accent"
                :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_yes')" />
            </zbm-skeleton-loader>
          </div>


          <!-- No Modal -->
          <zbm-dialog v-model="consentModal">

            <!-- Header -->
            <div class="row q-dialog__header">
              <div class="col-auto">
                <!-- Exclamation Circle Icon -->
                <q-icon color="dark-teal" name="fas fa-exclamation-circle" size="42px" />
                <h5>{{ $t('consent_confirm_title') }}</h5>
              </div>
            </div>

            <!-- Body -->
            <div class="q-dialog__body">
              <div class="row justify-center">
                <div class="col-auto">
                  <p>{{ $t('consent_confirm_desc') }}</p>
                </div>
              </div>
            </div>

            <!-- Footer (CTA button(s)) -->
            <div class="row q-dialog__footer q-gutter-sm">
              <div class="col-auto">
                <zbm-btn :label="$t('consent_confirm_goback')" v-close-popup
                  :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.md" />
              </div>
              <div class="col-auto">
                <zbm-btn :label="$t('consent_confirm_imsure')" v-close-popup
                  :color="$zb.enums.ButtonStdColorEnum.accent" :height="$zb.enums.ButtonHeightEnum.md"
                  @click="onOptOutClicked()" />
              </div>
            </div>
          </zbm-dialog>
        </div>
      </template>
    </UserPolicySignOff>
  </q-page>
</template>

<style lang="scss" scoped>
.actions {
  // .row
  $actions-gutter: 10px;

  text-align: center;
  margin-inline: -$actions-gutter;

  >[class*="col"] {
    padding-inline: $actions-gutter;
  }

  .flex-br {
    margin-block-end: $actions-gutter;
  }

  @media (min-width: 525px) {
    text-align: left;

    .flex-br {
      display: none;
    }
  }
}
</style>
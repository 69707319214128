<template>
  <!-- Education Library Technology Navigation -->
  <div class="the-dashboard-education__tech-navigation">
    
    <!-- ROSA Knee -->
    <TheTechnologyEduLibCatItem
      :class="'rosa-knee'"
      :path="'Education.Technology.ROSAKnee'"
      :titleGraphic="'/img/rosa-knee-logo.png'"
      :titleGraphicAlt="$t('rosa_education_rosa_knee_system_ios')"
      :categoryGraphic="'/img/rosa-knee-edu-lib-graphic.png'"
    />
  </div>
</template>

<script setup lang="ts">
import TheTechnologyEduLibCatItem from 'src/pages/Education/components/TheTechnologyEduLibCatItem.vue';
</script>
export enum QuestionCategoryEnum {
  DataEntry = 'DataEntry',
  Instruction = 'Instruction',
  MultipleChoice = 'MultipleChoice',
  MultipleChoiceChooseMany = 'MultipleChoiceChooseMany',
}

export enum QuestionCategoryResponseEnum {
  Unknown,
  Instruction,
  MultipleChoice,
  MultipleManyChoiceType,
  MultipleChoiceChooseOne,
  MultipleChoiceChooseOneSingleNest,
  DataEntry,
  MultipleChoiceChooseMany,
  Assessment,
  SliderRangeP10,
  NumericRangeFAAM,
  SliderRangeVAS,
  SliderRangeNRS,
  SliderIncrements,
  SliderNoIncrements,
  SliderIncrementsVertical,
  SliderNoIncrementsVertical,
  SliderIncrementsVerticalNoLabel,
  SliderIncrementsHoizontalText,
  SliderDefinition,
  ImageDefinition,
  Declinable
}

export enum AnswerTypeEnum {
  DetailHeading = 'DetailHeading',
  ExclusiveNone = 'ExclusiveNone',
  Heading = 'Heading',
  Item = 'Item',
  SubIntValue = 'SubIntValue',
  SubStringValue = 'SubStringValue',
  SubItem = 'SubItem',
  IntValue = 'IntValue',
  None = 'None', //legacy value pre-PMH survey when answerType didn't exist
  Footer = 'Footer',
  SubHeading = 'SubHeading',
  TextNoSpecialCharsValue = 'TextNoSpecialCharsValue',
  AllowOther = 'Allow Other',
  Weight = 'Weight',
  Height = 'Height',
  DeclineToComplete = 'Decline_To_Complete'
}

export enum OnSlideEnum {
  None,
  Checkmark,
  Text,
  NumberValue
}

export enum SurveyTypeEnum {
  Unknown = 0,
  HOOS = 1,
  KOOS = 2,
  LEFS = 3,
  QuickDASH = 4,
  DASH = 5,
  PROMIS_10 = 6,
  MSMOC_Survey = 7,
  FAAM = 8,
  KOOS_12 = 9,
  VR_12 = 10,
  KOOS_Jr_ = 11,
  HOOS_Jr_ = 12,
  HOOS_12 = 13,
  VAS = 14,
  NRS = 15,
  OHS = 16,
  OKS = 17,
  JHEQ = 18,
  JKOM = 19,
  HOOS_PS = 20,
  KOOS_PS = 21,
  VDS = 22,
  ODI = 23,
  NDI = 24,
  Anchor_Knee = 25,
  Anchor_Hip = 26,
  NRS_Satisfaction = 27,
  NRS_Pain_At_Rest = 28,
  NRS_Pain_Loaded = 29,
  EQ_5D_5L_Health = 30,
  PPP_33_DACH = 31,
  IKDC = 32,
  Pain_and_Opioid_Pre_Op = 33,
  Pain_and_Opioid_Post_Op = 34,
  OARS = 35,
  OACS = 36,
  OSS = 37,
  Knee_Expectations_Preoperative = 38,
  Knee_General_Questions = 39,
  Knee_Postoperative_Questions = 40,
  Hip_Expectations_Preoperative = 41,
  Hip_General_Questions = 42,
  Hip_Postoperative_Questions = 43,
  Shoulder_Expectations_Preoperative = 44,
  Shoulder_General_Questions = 45,
  Shoulder_Postoperative_Questions = 46,
  LEAS = 47,
  KPS = 48,
  Knee_Society_Score = 49,
  PMH = 50,
  HPS = 51,
  SPS = 52,
  SST = 53,
  ASES = 54,
  Post_Op_Check_In = 55,
  rom_shoulder_left_flexion = 56,
  rom_shoulder_left_extension = 57,
  rom_shoulder_left_standing_abduction = 58,
  rom_shoulder_left_front_internal_rotation = 59,
  rom_shoulder_left_external_rotation = 60,
  rom_shoulder_right_flexion = 61,
  rom_shoulder_right_extension = 62,
  rom_shoulder_right_standing_abduction = 63,
  rom_shoulder_right_front_internal_rotation = 64,
  rom_shoulder_right_external_rotation = 65,
  Predicted_Progress_Predictions_Engagement = 81,
  Predicted_Progress_Comparisons_Engagement = 82,
  Predicted_Progress_Day_42_Engagement = 83,
  BMI = 90,
  Predicted_Progress_Day_42_Engagement_No_Predictions = 96
}

export enum SurveyComponentTypeEnum {
  checkbox = 'checkbox',
  footer = 'footer',
  heading = 'heading',
  label = 'label',
  radio = 'radio',
  textbox = 'textbox',
  number = 'number',
  unknown = 'unknown',
  dropdown = 'dropdown',
  button = 'button'
}

export enum SurveyComponentQuestionTypeEnum {
  choice = 'choice',
  slider = 'slider',
  button = 'button',
  unknown = 'unknown'
}

export enum SliderValueBehaviorEnum {
  checkmark = 'checkmark',
  show = 'show',
  hide = 'hide'
}

export enum SliderOrientationEnum {
  horizontal = 'ltr',
  vertical = 'btt'
}

export enum EngagementSurveyTypeEnum {
  comparisons = 'Predicted_Progress_Comparisons_Engagement',
  predictions = 'Predicted_Progress_Predictions_Engagement',
  day42 = 'Predicted_Progress_Day_42_Engagement',
  day42NoPredictions = 'Predicted_Progress_Day_42_Engagement_No_Predictions'
}

export interface PasswordConfirmWithValidationResult {
  errors: Record<string, string[]>;
  fields: Record<string, unknown>;
  isValid: boolean;
  password: string;
  confirmPassword: string;
}

export function makeEmptyPasswordConfirmWithValidationResult(): PasswordConfirmWithValidationResult {
  return {
    errors: {},
    fields: {},
    isValid: false,
    password: '',
    confirmPassword: '',
  };
}

export interface UsernameConfirmWithValidationResult {
  errors: Record<string, string[]>;
  fields: Record<string, unknown>;
  isValid: boolean;
  username: string;
  confirmUsername: string;
}

export function makeEmptyUsernameConfirmWithValidationResult(): UsernameConfirmWithValidationResult {
  return {
    errors: {},
    fields: {},
    isValid: false,
    username: '',
    confirmUsername: ''
  };
}
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick"
                     showFooter>

      <!-- Title -->
      <template v-slot:heading>
        {{ $t('piq_mailing_address_header') }}
      </template>

      <!-- View Unique Content -->
      <template v-slot:default>
        
        <!-- Instructions -->
        <p>{{ $t('piq_address_info') }}</p>

        <q-form ref="addressForm" greedy>
          
          <!-- Address -->
          <div class="row form-row">
            <div class="col">
              <zbm-input-with-validation v-model="patientAddress.address1"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_mailing_address')"
                                         outlined
                                         :rules="[$zb.validators.required(), $zb.validators.isNotPOBox()]" />
            </div>
          </div>

          <!-- City -->
          <div class="row form-row">
            <div class="col">
              <zbm-input-with-validation v-model="patientAddress.city"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_address_city')"
                                         outlined
                                         :rules="[$zb.validators.required()]" />
            </div>
          </div>

          <!-- State/Province & Postal Code -->
          <div class="row form-row">

            <!-- State/Province -->
            <div class="col">
              <zbm-input-with-validation v-model="patientAddress.state"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_address_state')"
                                         outlined
                                         :rules="[$zb.validators.required()]" />
            </div>

            <!-- Postal Code -->
            <div class="col">
              <zbm-input-with-validation v-model="patientAddress.zipCode"
                                         :customMessages="{postalCodeFormat: $t('activate_profile_postal_code_invalid')}"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormEl125"
                                         :label="$t('piq_address_postal_code')"
                                         outlined
                                         :rules="[$zb.validators.required(), $zb.validators.maxLen(25), $zb.validators.postalCode()]" />
            </div>
          </div>

          <!-- Country -->
          <div class="row form-row">
            <div class="col">
              <zbm-select-with-validation v-model="patientAddress.addressCountry"
                                          :label="$t('piq_country')"
                                          optionLabel="text"
                                          :options="countryList.countryOptions"
                                          optionValue="value"
                                          outlined
                                          :rules="[$zb.validators.required()]" />
            </div>
          </div>
        </q-form>
      </template>

      <template v-slot:footer>
        <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
          :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
          <ProgressBar :progress="40" :nextEnabled="true" @on-back-click="onBackClick" @on-next-click="onNextClick" />
        </Box>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useActivationStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { type CanaryPatientMailingAddress } from 'src/types/webContracts';
import { getNextActivationRoute } from 'src/services/piqSetupService';

// components
import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { getCanaryAddress, updateCanaryAddress } from 'src/api/endpoints/patientWeb';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();
const activationStore = useActivationStore()

const addressForm = ref(null);
const getDefaultEmptyAddress = () => {
  return {
    address1: '',
    city: '',
    state: '',
    zipCode: '',
    addressCountry: 'US',
  } as CanaryPatientMailingAddress;
}

const patientAddress = ref(getDefaultEmptyAddress());

onMounted(async () => {
  $q.loading.show()
  await getCanaryAddress()
    .then((response) => {
      patientAddress.value = toCanaryPatientMailingAddress(response?.data.result);
    })
    .finally(() => {
      $q.loading.hide()
    });
});

function toCanaryPatientMailingAddress(address: any): CanaryPatientMailingAddress {
  if (!address || !address.address1) return getDefaultEmptyAddress();

  return {
    address1: address.address1,
    city: address.city,
    state: address.state,
    zipCode: address.zipcode,
    addressCountry: address.addressCountry,
  } as CanaryPatientMailingAddress;
}


const countryList = computed(() => activationStore.supportedCountries);

async function onNextClick() {
  const isValid = await addressForm.value.validate();
  if (!isValid) return;

  $q.loading.show()
  const response = await handleApiRequest(() => updateCanaryAddress(patientAddress.value), null, $q).finally(
    () => {
      $q.loading.hide()
    }
  );

  if (!response?.result) {
    // error
  } else {
    var nextRoute = await getNextActivationRoute($q);
    router.push({ name: nextRoute }).catch(() => { });
  }
}

async function onBackClick() {
  router.go(-1);
}

</script>
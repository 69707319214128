import { type EpisodeOfCareInfo } from 'src/types/webContracts';
import type {Svg} from '@/types/assets';
import {
  ankle,
  foot,
  heart,
  hip,
  injection,
  knee,
  lowerExtremity,
  shoulder,
  spine,
  upperExtremity
} from '@/assets';


export function factorProcedures(eocs: EpisodeOfCareInfo[]): EpisodeOfCareInfo[] {
  if (eocs.length === 1) {
    return eocs;
  }
  
  eocs.filter(p => p.status !== 1);

  eocs.forEach(function(episode) {
    episode.bodyPartIcon = factorProcedureIcon(episode.bodyPartName);
    episode.totalAlerts = episode.alerts.tasks + episode.alerts.messages + episode.alerts.videoVisit;
  });
  
  return eocs;
}

function factorProcedureIcon(bodyPartName: string): Svg {
  switch (bodyPartName) {
    case 'Ankle':
      return ankle;
    case 'Foot':
      return foot;
    case 'Cardio_Thoracic':
    case 'Heart':
      return heart;
    case 'Hip':
      return hip;
    case 'Injection':
      return injection;
    case 'Knee':
      return knee;
    case 'Lower_Extremity':
      return lowerExtremity;
    case 'Shoulder':
      return shoulder;
    case 'Cervical_Spine':
    case 'Lumbar_Spine':
      return spine;
    case 'Upper_Extremity':
      return upperExtremity;
    default:
      return {vectorPaths: '', viewBox: ''};
  }
}
import { GeneralPurposeVariant } from 'src/constants/enums';
import { type GeneralPurposeModalModel } from 'src/types/interfaces';

export function createInformationModal(title: string, template: string): GeneralPurposeModalModel {
  return {
    title,
    template,
    variant: GeneralPurposeVariant.Information
  }
}

export function createDecisionModal(title: string, template: string, onClose?: () => void): GeneralPurposeModalModel {
  return {
    title,
    template,
    onClose,
    variant: GeneralPurposeVariant.Decision
  }
}

export function createActionModal(title: string, template: string, onClose: () => void): GeneralPurposeModalModel {
  return {
    title,
    template,
    variant: GeneralPurposeVariant.Decision,
    onClose
  }
}
<script lang="ts" setup>
import { computed, onMounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useActivationStore, useSessionStore, useLocalizationStore } from 'src/store';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { Workflow } from 'src/constants/enums';
import { userLogout } from '@/services/userService';
import { useQuasar } from 'quasar';
import Svg from '@/components/Svg.vue';
import {mymobilityLogo} from '@/assets'

const $q = useQuasar()
const localizationStore = useLocalizationStore();
const sessionModule = useSessionStore();
const activationStore = useActivationStore()

const phoneFormatIsValid = ref(false);
const router = useRouter();
const showActivateStartInfoDialog = ref(false);
const phoneTelNumberInputModel = ref({
  value: '',
  phoneObject: {}
})
const isLoading = ref(false);
const activateStartForm = ref();

onMounted(async () => {
  isLoading.value = true;

  try {
    if (sessionModule.user) {
      await userLogout($q);
    }

  } finally {
    isLoading.value = false;
  }

  (document.querySelectorAll('input')[0] as HTMLElement).focus();
 
});

const countryList = computed(() => {
  return activationStore.supportedCountries;
});

const derivedCountryCode = computed(() => {
  return localizationStore.region;
});

const dateOfBirth = computed({
  get() {
    return activationStore.activateStart.dateOfBirth;
  },
  set(newValue: string) {
    activationStore.setActivateStartDateOfBirth(newValue);
  }
});

const countryCode = computed({
  get() {
    return activationStore.activateStart.countryCode as string;
  },
  set(newValue: string) {
    activationStore.setActivateStartCountryCode(newValue);
  }
});

function validatePhoneNumberOnChange() {
  if (phoneTelNumberInputModel.value) {
    let phoneObject = phoneTelNumberInputModel.value.phoneObject;
    phoneFormatIsValid.value = phoneObject.valid;
    if (phoneFormatIsValid.value) {
      activationStore.setActivateStartPhoneNumber({
        phoneCountryCode: phoneObject.countryCallingCode,
        phoneNumber: phoneObject.nationalNumber,
        formattedPhoneNumber: phoneObject.phoneNumber,
      });
    }
  }
}

function validateForm() {
  return phoneFormatIsValid.value && dateOfBirth.value && countryCode.value ? true : false;
}

function onNextClick() {
  activateStartForm.value.validate().then(() => {
    const params = {
      workflow: Workflow.registration.toString(),
      backRoute: 'Activate.Start',
      nextRoute: 'SetPassword',
      phoneCountryCode: activationStore.activateStart.phoneCountryCode,
      dateOfBirth: activationStore.activateStart.dateOfBirth,
      phoneNumber: activationStore.activateStart.phoneNumber,
      country: activationStore.activateStart.countryCode,
      progress: '26',
    };
    router.push({
      name: 'ConfirmIdentity',
      query: params,
    });
  })
}
</script>

<template>
  <q-page class="activate-start" padding>
    <AuthenticateLayout :content-box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
                        dark
                        :loading="isLoading"
                        withHeader>

      <!-- View Unique Content -->
      <template #default>
  
        <!-- Heading -->
        <div class="row no-wrap bs-gutter activate-start__heading">
          <div class="col" />
  
          <!-- mymobility Logo -->
          <div class="col-auto mymo-logo">
            <Svg :view-box="mymobilityLogo.viewBox"
                 :vector-paths="mymobilityLogo.vectorPaths" />
          </div>
  
          <!-- Question Circle Button -->
          <div class="col question">
            <zbm-skeleton-loader dark
                                 :loading="isLoading"
                                 size="30px">
              <zbm-btn-icon @click="showActivateStartInfoDialog = true"
                            icon="fal fa-question-circle"
                            size="30px"
                            color="transparent" />
            </zbm-skeleton-loader>

            <!-- Question Circle Dialog -->
            <zbm-dialog v-model="showActivateStartInfoDialog">
              
              <!-- Header -->
              <div class="row q-dialog__header">
                <div class="col-auto">

                  <!-- Question Circle Icon -->
                  <q-icon color="dark-teal"
                          name="fas fa-question-circle"
                          size="42px" />
                </div>
              </div>

              <!-- Body -->
              <div class="row q-dialog__body">
                <div class="col">
                  <h3 class="text-weight-semibold q-mb-lg">{{ $t('sign_up_help') }}</h3>
                  <p>
                    {{ $t('activate_auth_help_ios') }}&nbsp;
                    <a href="https://support.zbmymobilitysolutions.com/start/" target="_blank">{{ $t('activate_auth_help_link_ios') }}</a>
                  </p>
                </div>
              </div>

              <!-- Footer (CTA button(s)) -->
              <div class="row q-dialog__footer">
                <div class="col-auto">
                  <zbm-btn v-close-popup
                           :color="$zb.enums.ButtonStdColorEnum.accentText"
                           :height="$zb.enums.ButtonHeightEnum.lg"
                           :label="$t('gen_okay')" />
                </div>
              </div>
            </zbm-dialog>
          </div>
        </div>

        <!-- Form -->
        <q-form ref="activateStartForm" greedy>
  
          <!-- Phone Number -->
          <div class="row justify-center mb-40">
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300"
                                 dark
                                 height="40px"
                                 :loading="isLoading">
              <zbm-telephone-with-validation v-model="phoneTelNumberInputModel"
                                             v-on:keyup.enter="onNextClick"
                                             @update:model-value="validatePhoneNumberOnChange"
                                             dark
                                             :patientCountryCode="derivedCountryCode"
                                             :placeholder="$t('activate_phone_number')"
                                             :rules="[$zb.validators.phoneRequired()]" />
            </zbm-skeleton-loader>
          </div>
    
          <!-- Date of Birth -->
          <div class="row justify-center mb-40">
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300"
                                 dark
                                 height="40px"
                                 :loading="isLoading">
              <zbm-date-picker v-model="dateOfBirth"
                               v-on:keyup.enter="onNextClick"
                               dark
                               :placeholder="$t('activate_date_of_birth')"
                               :rules="[$zb.validators.required()]" />
            </zbm-skeleton-loader>
          </div>
    
          <!-- Country Selector -->
          <div class="row flex-center country">
  
            <!-- Label -->
            <div class="col-auto font-size-20 text-white text-no-wrap">
              <zbm-skeleton-loader dark
                                   height="30px"
                                   :loading="isLoading"
                                   width="107px">
                {{ $t('provider_select_country_button') }}
              </zbm-skeleton-loader>
            </div>
            <div class="flex-br" />
  
            <!-- Select -->
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300"
                                 dark
                                 height="40px"
                                 :loading="isLoading">
              <zbm-select-with-validation v-model="countryCode"
                                          dark
                                          option-label="text"
                                          :options="countryList.countryOptions"
                                          option-value="value"
                                          :rules="[$zb.validators.required()]" />
            </zbm-skeleton-loader>
          </div>
        </q-form>
      </template>

      <!-- Actions/Progress -->
      <template #actions>
        <ProgressBar @on-next-click="onNextClick"
                     backRoute="Activate"
                     :loading="isLoading"
                     :nextEnabled="validateForm()"
                     :progress="13" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<style lang="scss" scoped>
  .activate-start { // .q-page
    padding-inline: 0;

    // Heading
    &__heading { // .row
      margin-bottom: 50px;
      
      // mymobility Logo
      .mymo-logo { // [class*="col"]
        line-height: 0;
        color: map-get($co-brand-clrs, content-grd-bg-content);
        
        svg {
          width: 100%;
          max-width: 150px;
          height: auto;
        }
      }

      // Question Circle Button
      .question { // [class*="col"]
        text-align: end;
      }
    }

    @media (max-width: 350px) {

      // Heading
      &__heading { // .row
        
        > [class*="col"]:first-child {
          display: none;
        }
      }
    }

    @media (min-width: 600px) {

      // Form
      .q-form {

        // Country Selector
        .country {

          .flex-br {
            display: none;
          }

          // Label
          [class*=col]:first-child {
            padding-inline-end: 10px;
          }
        }
      }
    }
  }
</style>
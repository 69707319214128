<template>
  <q-page padding>
    <zbm-async-content :loading="isLoading">
      <template v-slot:loading>
        <div class="row justify-center">
          <template v-for="i in 5" :key="i">
            <div :class="`col-auto pb-16 pt-${i > 1 ? '16' : ''}`">
              <q-skeleton type="rect" width="540px" height="80px" />
            </div>
          </template>
        </div>
      </template>
      <TechnologyEduLibLayout heading="pwe_technology_library" :headingGraphic="'/img/rosa-knee-edu-lib-graphic.png'"
        :rawLibraryItems="libraryItems" />
    </zbm-async-content>
  </q-page>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

import TechnologyEduLibLayout from 'src/components/layouts/TechnologyEduLibLayout.vue';
import { useEducationLibraryStore } from 'src/store/educationLibraryModule';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLibraryRosaItems } from 'src/api/endpoints/calendarWeb';
import { useQuasar } from 'quasar';

const $q = useQuasar()

const isLoading = ref(false);

const educationLibraryStore = useEducationLibraryStore()

onMounted(async () => {
  if (!educationLibraryStore.rosaItems.length) {
    const response = await handleApiRequest(() => getLibraryRosaItems(), null, $q);
    if (response != undefined) {
      educationLibraryStore.setRosaItems(response.result);
    }
    isLoading.value = false;
  }
});

const libraryItems = computed(() => {
  return educationLibraryStore.rosaItems;
});
</script>
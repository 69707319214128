import type { ApiResponse } from 'src/types/apiTypes';
import axios, { AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';
import axiosInstance from '@/api/axiosConfig'
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory'
import { useGeneralPurposeModalStore } from 'src/store';
import { i18n } from 'src/plugins/i18n'


interface WorkCallback<TResult> {
  (axiosInstance: AxiosInstance): Promise<AxiosResponse<ApiResponse<TResult>>>;
}

interface FailureCallback {
  (response: Error | AxiosError): void;
}

export async function handleApiRequest<TResult>(work: WorkCallback<TResult>, fail?: FailureCallback | null, $q): Promise<ApiResponse<TResult> | undefined> {
  try {
    const response = await work(axiosInstance)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {

      if (error.response) {
        const errMsg = i18n.global.t(error.response.data.errorMessageKey || error.response.data.errorMessage)
        if (error.response.status === 400) {
          $q.notify({ type: 'error', message: errMsg })
        } else if (error.response.status === 422) {
          const generalPurposeModalStore = useGeneralPurposeModalStore()
          const model = createDecisionModal('', errMsg, () => fail && fail(error as AxiosError))
          generalPurposeModalStore.setModal(model)
          return //dont call the fail callback
        }
        else { $q.notify({ type: 'error', message: errMsg }) }
      } else {
        $q.notify({ type: 'error', message: error.message })
      }

      if (fail) {
        fail(error)
      }
    }
    return;
  }
}
//i18n-setup.js
import { createI18n } from 'vue-i18n'

const config = {
  legacy: true,
  allowComposition: false,
  fallbackLocale: 'en',
}

if (process.env.NODE_ENV === 'development') {
  config.missing = () => { return; }
  config.silentTranslationWarn = true
}


export const i18n = createI18n(config)
<script setup lang="ts">

</script>

<template>
  
  <!-- Hero -->
  <div class="row justify-center mb-20 no-data__hero">
    <div class="col-auto">
      <img src="/img/pat-prog-comparison-pending-hero.svg" />
    </div>
  </div>

  <!-- Title -->
  <h2 class="text-center mb-20">{{ $t('compare_progress_screen_no_data_title') }}</h2>

  <!-- Content -->
  <p class="no-data__content">{{ $t('compare_progress_screen_no_data_text') }}</p>

  <!-- Calculating -->
  <p class="text-primitives-foundation-100 text-center mb-16">{{ $t('comparisons_calculating_text') }}</p>

  <!-- "Got it!" Button -->
  <div class="row justify-center">
    <div class="col-auto">
      <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accent"
               :height="$zb.enums.ButtonHeightEnum.lg"
               :label="$t('got_it_button')"
               :width="$zb.enums.ButtonWidthEnum.w300"
               :to="{ name: 'Dashboard.Home' }" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-data {

  // Hero
  &__hero img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }

  // Content
  &__content { // <p>
    color: $content-txt-secondary;
    text-align: center;
    margin-block-end: 150px;
  }
}
</style>
import { SurveyPageModel } from 'src/types/model';
import { SurveyDefinitionWrapperResponse, SurveyPageResponse } from 'src/types/webContracts';
import { toSurveyQuestionModel } from './surveyQuestionAdapter'
import { SurveyComponentQuestionTypeEnum } from 'src/constants/survey';

export function toSurveyPageModel(page: SurveyPageResponse, response: SurveyDefinitionWrapperResponse): SurveyPageModel {
  const { pageId, header, renderQuestionsTogether } = page
  const renderHeaderOfEachQuestionIndividually = !renderQuestionsTogether

  const questions = page.questions.map((q, i) => {
    const isFirstQuestionOnPage = i === 0
    return toSurveyQuestionModel(q, renderHeaderOfEachQuestionIndividually || isFirstQuestionOnPage, response)
  })

  const result = {
    pageId,
    header,
    questions,
    renderingConfig: {
      combineIntoSingleQuestion: page.renderQuestionsTogether,
      hasCondition: page.hasCondition,
      hidePage: page.hidePage,
      order: page.sequence,
      hasButtons: questions.some(q => q.renderingConfig.type == SurveyComponentQuestionTypeEnum.button)
    },
    footer: decodeHtml(response.survey.footer)
  }
  return result;
}

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
<script lang="ts" setup>
import {defineProps} from 'vue';

const props = defineProps({
  value: {
    type: Number,
    default: 0
  }
});
</script>

<template>
  <q-circular-progress color="secondary"
                       rounded
                       show-value
                       size="130px"
                       :thickness="0.22"
                       track-color="cir-prog"
                       :value="props.value" />
</template>

<style lang="scss" scoped>
:deep(.text-cir-prog) {
  color: $nav-separator-clr;
}
</style>
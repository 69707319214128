<script setup lang="ts">
import { useSessionStore } from 'src/store';
import {
  computed,
  defineModel,
} from 'vue';
import EocNavBtn from 'src/components/EocNavBtn.vue';

const sessionStore = useSessionStore();

const procedures = computed(() => sessionStore.procedures);

const showDialog = defineModel({
  type: Boolean,
  required: true
});
</script>

<template>
  <zbm-dialog v-model="showDialog" :close-button="true">

    <!-- Header -->
    <div class="row q-dialog__header">
      <div class="col-auto">
        <h5>{{ $t('eoc_my_procedures') }}</h5>
      </div>
    </div>

    <!-- Body -->
    <div class="column q-dialog__body">
      <EocNavBtn v-for="procedure in procedures" :key="procedure.eocId" :procedure="procedure" />
    </div>
  </zbm-dialog>
</template>

<style lang="scss" scoped></style>
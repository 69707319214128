<template>
  <q-page id="the-communication-preferences" padding>
    <MultiViewLayout :onBackClick="onBackClick">

      <q-form @submit.prevent="onOkayClicked">
        <div v-bind:style="props.interrupt ? userResearchEnabled ? 'padding-bottom: 15px;' : 'padding-bottom: 25px;' : 'padding-bottom: 15px;'">
          <!-- Heading -->
          <h2>{{ $t('subscriptions') }}</h2>
          <p>{{ $t('subscription_source') }}</p>

          <hr />
          <!-- User Research -->
          <q-toggle v-model="userResearchValue" :label="$t('user_research_opportunities')" color="green" @click="toggleUserResearch" />
          <hr />

          <!-- User Research Opt In -->
          <p>{{ $t('communication_preferences_opt_in') }}</p>
          <p><b>{{ $t('user_research_activities') }}</b></p>
        </div>

        <div v-if="userResearchEnabled" v-bind:style="props.interrupt ? 'padding-bottom: 25px;' : ''">
          <!-- Contact Methods -->
          <h6>{{ $t('contact_methods') }}</h6>
          <p>{{ $t('contact_methods_source') }}</p>

          <hr />
          <!-- Email -->
          <q-toggle v-model="emailValue" :label="$t('email')" color="green" @click="toggleEmail" />
          <hr />
          <!-- Phone -->
          <q-toggle v-model="phoneValue" :label="$t('text')" color="green" @click="togglePhone" />
          <hr />
        </div>

        <!-- Save Preferences Button -->
        <zbm-btn @click="onOkayClicked" v-if="props.interrupt"
                 :color="$zb.enums.ButtonStdColorEnum.accent" :height="$zb.enums.ButtonHeightEnum.lg"
                 :label="$t('save_preferences')" style="display: block; margin: 0 auto;" />
      </q-form>

      <!-- Save Preferences Button -->
      <q-dialog v-model="savePreferencesDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('save_changes') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('save_selections')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn class="saveSelectionsButton" flat :label="$t('yes_save_selections')" v-close-popup @click="savePreferencesClick" />
            <q-btn class="reviewSelectionsButton" flat :label="$t('review_selections')" v-close-popup />
            <q-btn class="doNotSaveSelectionsButton" flat :label="$t('do_not_save_selections')" v-close-popup @click="dontContactMeClick" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Skip For Now Dialog -->
      <q-dialog v-model="skipForNowDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('skip_for_now') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('update_communication_status')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn class="skipForNowButton" flat :label="$t('gen_okay')" @click="skipForNowClick" v-close-popup />
            <q-btn class="goBackButton" flat :label="$t('go_back')" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Contact Method Dialog -->
      <q-dialog v-model="contactMethodRequiredDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('contact_method_required') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('select_method')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn class="contactMethodButton" flat :label="$t('select_contact_method')" v-close-popup />
            <q-btn class="doNotContactMeButton" flat :label="$t('do_not_contact_me')" @click="dontContactMeClick" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
  import { useQuasar } from 'quasar';
  import { setCommunicationPreferences, getCommunicationPreferences } from 'src/api/endpoints/userWeb';
  import { handleApiRequest } from 'src/api/handleApiRequest';
  import { useSessionStore } from 'src/store';
  import { useDashboardStore } from 'src/store/dashboardModule';
  import { onMounted, ref, computed, defineProps } from 'vue';
  import { useRouter } from 'vue-router';
  import MultiViewLayout from '@/components/layouts/MultiViewLayout.vue';

  const sessionStore = useSessionStore();
  const dashboardStore = useDashboardStore();
  const router = useRouter();
  const $q = useQuasar();

  const props = defineProps({
    interrupt: {
      type: Boolean,
      default: false
    }
  });

  const emailValue = ref(false),
    phoneValue = ref(false),
    userResearchValue = ref(false);

  let skipForNowDialog = ref(false);
  let contactMethodRequiredDialog = ref(false);
  let savePreferencesDialog = ref(false);

  onMounted(async () => {
    $q.loading.show()

    try {
      document.querySelectorAll('html')[0].scrollTop = 0;

      sessionStore.setIsHeaderVisible(true);

      const communicationPreferencesResponse = await handleApiRequest(() => getCommunicationPreferences(), null, $q);

      $q.loading.hide()

      if (communicationPreferencesResponse?.result) {
        emailValue.value = communicationPreferencesResponse.result.emailEnabled;
        phoneValue.value = communicationPreferencesResponse.result.phoneEnabled;
        userResearchValue.value = communicationPreferencesResponse.result.userResearchEnabled;
      }
    } catch (error) {
      $q.loading.hide()
    }
  })

  const userResearchEnabled = computed(() => {
    return userResearchValue.value;
  })

  const toggleUserResearch = async () => {
    emailValue.value = false;
    phoneValue.value = false;

    if (props.interrupt != true)
      await saveCommunicationPreferences();
  }

  const toggleEmail = async () => {
    if (props.interrupt != true)
      await saveCommunicationPreferences();
  }

  const togglePhone = async () => {
    if (props.interrupt != true)
      await saveCommunicationPreferences();
  }

  function isContactMethodValid() {
    if (userResearchValue.value != true)
      return true;

    return emailValue.value == true || phoneValue.value == true;
  }

  const onOkayClicked = async () => {
    if (!isContactMethodValid())
      contactMethodRequiredDialog.value = true;
    else
      saveCommunicationPreferences();
  }

  const onBackClick = async () => {
    if (props.interrupt && userResearchValue.value != true)
      skipForNowDialog.value = true;
    else if (!isContactMethodValid())
      contactMethodRequiredDialog.value = true;
    else
      router.push({ name: 'Settings.LegalAndPrivacy' });
  }

  const skipForNowClick = async () => {
    dashboardStore.setSkipCommunicationPreferencesInterrupt(true);
    router.push({ name: 'Interrupts', params: { reload: 'false' } });
  }

  const dontContactMeClick = async () => {
    let communicationPreferencesRequest: CommunicationPreferencesRequest = {
      emailEnabled: false,
      phoneEnabled: false,
      userResearchEnabled: false
    };
    await handleApiRequest(() => setCommunicationPreferences(communicationPreferencesRequest), null, $q);

    if (props.interrupt)
      router.push({ name: 'Interrupts' });
    else
      router.push({ name: 'Settings.LegalAndPrivacy' });
  }

  const saveCommunicationPreferences = async () => {
    let communicationPreferencesRequest: CommunicationPreferencesRequest = {
      emailEnabled: emailValue.value,
      phoneEnabled: phoneValue.value,
      userResearchEnabled: userResearchValue.value
    };
    // Mark the Communication Preferences interrupt as completed.
    await handleApiRequest(() => setCommunicationPreferences(communicationPreferencesRequest), null, $q);

    if (props.interrupt)
      router.push({ name: 'Interrupts' });
  }
</script>


<style lang="scss" scoped>
  .skipForNowButton,
  .contactMethodButton,
  .saveSelectionsButton, {
    color: $accent;
  }

  .goBackButton,
  .doNotContactMeButton,
  .reviewSelectionsButton,
  .doNotSaveSelectionsButton {
    color: $secondary;
  }
</style>
<script lang="ts" setup>
import {
  defineProps,
  type PropType
} from 'vue';
import {
  BoxBackgroundColorEnum,
  BoxBorderRadiusEnum,
  BoxDropShadowEnum,
  BoxMarginEnum,
  BoxPaddingEnum,
  BoxWidthEnum
} from 'src/constants/enums';

const props = defineProps({
  /*
   * Controls the background color of the box. Options are:
   * accent - sets background color to `#fafafa`; common light grey
   *     used as a background color.
   * linearGradient - sets background color to a linear gradient from
   *     light teal (0%) to blue (100%).
   * secondary - sets background color to blue.
   * transparent - sets background color to transparent.
   * white - sets background color to white.
   */
  boxBkgdColor: {
    type: String as PropType<BoxBackgroundColorEnum>,
    default: BoxBackgroundColorEnum.white
  },
  /*
   * Controls the border radius of the box. Options are:
   * none - does not set a border radius.
   * default - sets the border radius to 12px, which is the most
   *     common border radius size for PWE.
   * lg - sets the border radius to 20px.
   */
  boxBrdrRadius: {
    type: String as PropType<BoxBorderRadiusEnum>,
    default: BoxBorderRadiusEnum.default,
  },
  /*
   * Controls the box shadow of the box. Options are:
   * normal - sets the dropshadow to: 0 0 8px 0 rgba($black, 0.2).
   * lg - sets the dropshadow to: 0 0 15px 0 rgba($black, 0.12).
   * xl - sets the dropshadow to: 0 2px 50px 0 rgba($black, 0.23).
   */
  boxDropShadow: {
    type: String as PropType<BoxDropShadowEnum>
  },
  /*
   * Controls the margin of the box. Options are:
   * mb20 - sets the bottom margin to 20px.
   * mb40 - sets the bottom-margin to 40px.
   */
  boxMargin: {
    type: String as PropType<BoxMarginEnum>
  },
  /*
   * Controls the padding of the box. Options are:
   * p20 - sets the padding to 20px on all sides.
   * p40 - sets the padding to 40px on all sides.
   * p2018 - sets the padding of the vertical sides to 25px and the
   *     horixontal sides to 20px.
   * p303055 - sets the top-padding to 30px, the horizontal sides to
   *     30px and the bottom-padding to 55px.
   * p453565 - sets the top-padding to 50px, the horizontal sides to
   *     35px and the bottom-padding to 65px.
   * p504535 - sets the top-padding to 50px, the horizontal sides to
   *     45px and the bottom-padding to 35px.
   * p505065 - sets the top-padding to 50px, the horizontal sides to
   *     50px and the bottom-padding to 65px.
   */
  boxPadding: {
    type: String as PropType<BoxPaddingEnum>
  },
  /*
   * Controls the width value of the box. Options are:
   * xs - sets width to 386px; common width for Exercise Reps in
   *     Exercise views.
   * md - sets width to 700px; common width for Login, Activate, &
   *     Forgot workflows.
   * lg - sets width to 750px; common width for Exercise Videos in
   *     Exercise views, Survey views & Video Visit Start workflow.
   * xl - sets width to 1024px; common width for Dashboard views.
   */
  boxWidth: {
    type: String as PropType<BoxWidthEnum>
  }
})
</script>

<template>
  <div :class="[
      'box',
      props.boxBkgdColor,
      props.boxBrdrRadius,
      props.boxDropShadow,
      props.boxMargin,
      props.boxPadding,
      props.boxWidth
  ]">
    <slot />
  </div>
</template>

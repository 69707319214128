import ePub from 'epubjs';

const Base64Binary = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  /* will return a  Uint8Array type */
  decodeArrayBuffer: function (input: string) {
    const bytes = (input.length / 4) * 3;
    const ab = new ArrayBuffer(bytes);
    this.decode(input, ab);

    return ab;
  },

  removePaddingChars: function (input: string) {
    const lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
    if (lkey == 64) {
      return input.substring(0, input.length - 1);
    }
    return input;
  },

  decode: function (input: string, arrayBuffer: ArrayBuffer) {
    //get last chars to see if are valid
    input = this.removePaddingChars(input); //from the beginning
    input = this.removePaddingChars(input); //from the end

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const bytes = parseInt((input.length / 4) * 3, 10);

    let uarray;
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    let j = 0;

    if (arrayBuffer)
      uarray = new Uint8Array(arrayBuffer);
    else
      uarray = new Uint8Array(bytes);

    input = input.replace(/[^A-Za-z0-9+/=]/g, '');

    for (i = 0; i < bytes; i += 3) {
      //get the 3 octects in 4 ascii chars
      enc1 = this._keyStr.indexOf(input.charAt(j++));
      enc2 = this._keyStr.indexOf(input.charAt(j++));
      enc3 = this._keyStr.indexOf(input.charAt(j++));
      enc4 = this._keyStr.indexOf(input.charAt(j++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      uarray[i] = chr1;
      if (enc3 != 64) uarray[i + 1] = chr2;
      if (enc4 != 64) uarray[i + 2] = chr3;
    }

    return uarray;
  }
}

class FileReader2 extends FileReader {
  targetDomNode = '';
}

function openBook(e: Event) {
  const book = ePub();
  const fileReader = e.target as FileReader2;

  if (fileReader) {
    book.open(fileReader.result as ArrayBuffer);
  } else {
    return
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rendition = book.renderTo(fileReader.targetDomNode as string, {
    flow: 'scrolled-doc',
    width: '100%',
    height: '100%',
    allowScriptedContent: true,
    allowPopups: true
  });

  rendition.display();

  rendition.themes.default({
    body: {
      'font-size': '.5rem'
    },
    p: {
      'margin-bottom': '10px !important',
      'color': '#5F5F5F !important'
    }
  });
}

export function loadEpub(base64String: string, areaId: string) {
  const byteArray = Base64Binary.decodeArrayBuffer(base64String);
  const txt = new Blob([byteArray], { type: 'application/octet-stream' });
  const targetDomNode = document.getElementById(areaId);
  if (window.FileReader && targetDomNode) {
    targetDomNode.innerHTML = '';
    const reader = new FileReader2();
    reader.targetDomNode = areaId;
    reader.onload = openBook;
    reader.readAsArrayBuffer(txt);
  }
}
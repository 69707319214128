<template>
  <q-scroll-area v-if="scrollable" visible class="legal">
    <div ref="displayElement"></div>
  </q-scroll-area>
  <div v-else ref="displayElement" class="legal" />
</template>

<script lang="ts" setup>
import { onMounted, defineProps, ref, defineEmits } from 'vue';
import { decode } from 'html-entities';
import { UserPolicyType } from 'src/constants/enums';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLocalizedPolicy } from 'src/api/endpoints/registrationWeb';
import { useQuasar } from 'quasar';

const $q = useQuasar()

const displayElement = ref(null);
const emit = defineEmits(['loaded']);
const props = defineProps({
  policyType: {
    type: Number as () => UserPolicyType,
    required: true,
  },
  scrollable: {
    type: Boolean,
    default: false,
  }
})

onMounted(async () => {
  $q.loading.show();

  const response = await handleApiRequest(() => getLocalizedPolicy(props.policyType), null, $q);

  if (response?.result) {
    const html = decode(response.result.description);
    if (props.policyType !== UserPolicyType.CanaryPiqPrivacyPolicy) {
      html.replace(/\n/gm, '<br><br>')
    }

    displayElement.value.innerHTML = html;
    displayElement.value
      .querySelectorAll('meta, title, link')
      .forEach((elm: any) => elm.remove());
  }

  emit('loaded');
  $q.loading.hide();
})

</script>

<style lang="scss" scoped>
.q-scrollarea {
  height: 450px;
  padding-inline-end: 20px;
  padding-bottom: 20px;
}
</style>
<template>
  <zbm-multi-view-layout :onBackClick="onBackClick">
    <!-- Title -->
    <template v-slot:title><span class="fas fa-walking"></span></template>
    <!-- Heading Text/Content -->
    <template v-slot:heading>{{ $t('gait_walking_progress') }}</template>
    <!-- Daily Walking Goal Data -->
    <template v-slot:default></template>
  </zbm-multi-view-layout>
</template>

<script lang="ts" setup>
  import { useRouter } from 'vue-router';
  const router = useRouter();

  function onBackClick() {
    router.push({ name: 'Dashboard.Home' });
  }
</script>
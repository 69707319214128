export const hasValue = (val) => {
  return (typeof val !== 'undefined' && val !== null && val.toString().trim() !== '');
}

export const isNumber = (val) => {
  return (typeof val !== 'undefined' && val !== '' && /^[0-9]*$/.test(val));
}

export const isAlphaNumeric = (val) => {
  return (typeof val !== 'undefined' && /^[a-zA-Z0-9]*$/.test(val))
}

export const isEmailFormat = (val) => {
  const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return val && emailRegex.test(val);
}

export const isPoBox = (val) => {
  const poBoxRegEx = /\b(?:p\.?o\.?|post\s*office)\s*box\b/i;
  return val && poBoxRegEx.test(val);
}

export const minLength = (val, len) => {
  return (val && val.length >= len);
}

export const maxLength = (val, len) => {
  return (!val || val.length <= len);
}

export const equals = (val, val2) => {
  return val === val2;
}

export const hasValidSpecialCharacter = (val) => {
  return (typeof val !== 'undefined' && /[!@#$%&()_\-.,\/:?]/.test(val));
}

export const hasInvalidSpecialCharacter = (val) => {
  return (typeof val !== 'undefined' && (/[`\^&\*+=<>;'\[\]{}\\~]/.test(val)));
}

export const hasNum = (val) => {
  return (typeof val !== 'undefined' && /\d/.test(val));
}

export const hasLowercase = (val) => {
  return (typeof val !== 'undefined' && /[a-z]/.test(val));
}

export const hasUppercase = (val) => {
  return (typeof val !== 'undefined' && /[A-Z]/.test(val));
}

export const hasNoSpaces = (val) => {
  return (typeof val !== 'undefined' && /^\S*$/.test(val));
}
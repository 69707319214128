
import type { SurveyState } from 'src/types/storeTypes'
import type { SurveyProgressResponse } from 'src/types/webContracts'
import { emptySurveyPageModel } from 'src/services/surveys/utils'
import { defineStore } from 'pinia' // Import defineStore from Pinia
import { decode } from 'html-entities'
import type {
  SurveyDefinitionModel,
  SurveyPageModel,
  SurveyProgressDenormalizedQuestionAndAnswerModel,
  SurveyProgressModel,
} from 'src/types/model'

export const useSurveyStore = defineStore({
  id: 'survey',
  state: (): SurveyState => ({
    surveyDefinition: undefined,
    surveyProgress: {
      surveyId: 0,
      eventDate: '',
      isComplete: false,
      actualAnswers: [],
      progressPercentage: 0,
      tickSignature: '',
    },
    currentPage: emptySurveyPageModel(),
  }),
  getters: {
    allPermittedAnswersOnCurrentPage() {
      const parentAnswers = this.currentPage.questions.map(q => q.permittedAnswers).flat()
      const subAnswers = parentAnswers.map(pa => pa.subAnswers || []).flat()
      return [...parentAnswers, ...subAnswers].sort((a, b) => a.answerId > b.answerId ? 1 : -1)
    },
  },
  actions: {
    clear() {
      this.surveyDefinition = undefined;
      this.surveyProgress = {
        surveyId: 0,
        eventDate: '',
        isComplete: false,
        actualAnswers: [],
        progressPercentage: 0,
        tickSignature: '',
      }
      this.currentPage = emptySurveyPageModel()
    },
    setSurveyDefinition(surveyDefinition: SurveyDefinitionModel | undefined) {
      this.surveyDefinition = { ...surveyDefinition as SurveyDefinitionModel }
    },
    setSurveyProgress(surveyProgress: SurveyProgressModel) {
      this.surveyProgress = surveyProgress
    },
    setCurrentPage(currentPage: SurveyPageModel | undefined) {
      this.currentPage = currentPage || emptySurveyPageModel()
    },
    setProgressPercentage(percentage: number) {
      this.surveyProgress.progressPercentage = percentage
    },
    setAddSurveyProgressAnswer(answer: SurveyProgressDenormalizedQuestionAndAnswerModel) {
      this.surveyProgress.actualAnswers.push(answer);
    },
    setRemoveSurveyProgressLastPageAnswers() {
      const lastAnswer = this.surveyProgress.actualAnswers.slice(-1).pop()
      if (lastAnswer) {
        this.surveyProgress.actualAnswers = this.surveyProgress.actualAnswers.filter(p => p.page !== lastAnswer.page)
      }
    },
    setIsComplete(isComplete = false) {
      this.surveyProgress.isComplete = isComplete;
    },
    setCurrentPageToPreviousPage() {
      if (this.surveyProgress.actualAnswers.length > 0) {
        const lastQuestionAnswered = this.surveyProgress.actualAnswers.slice(-1).pop() as SurveyProgressDenormalizedQuestionAndAnswerModel;
        this.setRemoveSurveyProgressLastPageAnswers();
        this.setCurrentPageToNextVisiblePage({ removedPage: lastQuestionAnswered.page })
      }

      this.setIsComplete(false)
    },
    setCurrentPageToNextVisiblePage({ removedPage }: { removedPage?: SurveyPageModel }) {
      let pagesToSearch: SurveyPageModel[] = []
      const targetPage = removedPage || this.currentPage

      if (targetPage.questions.length > 0) {
        const currentPageIndex = this.surveyDefinition?.pages.findIndex(p => p === targetPage) || 0

        const indexOffset = removedPage ? 0 : 1
        pagesToSearch = this.surveyDefinition?.pages.slice(currentPageIndex + indexOffset) || []
      } else {
        pagesToSearch = this.surveyDefinition?.pages || []
      }

      const firstVisiblePage = pagesToSearch.find(p => !p.renderingConfig.hidePage)
      const firstVisiblePagePositionIndex = this.surveyDefinition?.pages.findIndex(p => p === firstVisiblePage) as number
      const totalPages = this.surveyDefinition?.pages.length || 1
      const currentPage = firstVisiblePagePositionIndex > -1 ? (firstVisiblePagePositionIndex + 1) : totalPages

      this.setCurrentPage(firstVisiblePage)
      this.setProgressPercentage(Math.round((currentPage / totalPages) * 100))
    },
    setSyncPageVisibilityAndQuestionText(response: SurveyProgressResponse) {
      response.surveyData.questionnaire.pageList.forEach(responsePage => {
        const pageDefinition = this.surveyDefinition?.pages.find(pd => pd.pageId === responsePage.pageId)
        if (pageDefinition) {
          pageDefinition.renderingConfig.hidePage = responsePage.hidePage
          pageDefinition.questions.forEach(question => {
            const questionDefinition = responsePage.questions.find(q => q.questionId === question.questionId);
            if (questionDefinition) {
              question.questionSubText = decode(questionDefinition.questionSubText)
                .replace(/\n/gm, '<br />')
                .replace(/\\n/gm, '<br />')
              question.questionText = decode(questionDefinition.questionText)
                .replace(/\n/gm, '<br />')
                .replace(/\\n/gm, '<br />')
              question.permittedAnswers.forEach(ans => ans.name = decode(ans.name))
            }
          })
        }
      });
    },
  },
});

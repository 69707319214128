<script setup lang="ts">
import {
  computed,
  defineEmits,
  defineProps,
  type PropType,
  ref
} from 'vue';
import type {Alert} from 'src/types/webContracts';
import {AlertType} from 'src/constants/enums';
import Svg from '@/components/Svg.vue';
import {baseStation} from '@/assets';

const props = defineProps({
  alert: {
    type: Object as PropType<Alert>
  }
});

const emits = defineEmits(['dismissNotif']);

const isCanary = computed(() => props.alert?.type === AlertType.lostContact);

const canaryDialog = ref(false);

function sanitizeMessage(message: string | undefined) {
  return message?.replace(/(<([^>]+)>)/gi, '');
}
</script>

<template>
  <q-banner inline-actions
            rounded>
    
    <!-- Icon -->
    <template #avatar>
      
      <!-- Canary -->
      <img v-if="isCanary"
           class="canary-icon"
           src="/img/icon-canary.svg" />
      
      <!-- "i" circle -->
      <q-icon v-else name="fas fa-info-circle" />
    </template>
    
    <!--  Content -->
    <template #default>
      <p v-html="isCanary ? sanitizeMessage(props.alert?.message) : props.alert?.message"
         @click="isCanary ? canaryDialog = true : null"
         :class="[{ 'cursor-pointer' : isCanary }]"/>

      <!-- Canary Base Station Dialog -->
      <zbm-dialog v-if="isCanary"
                  v-model="canaryDialog"
                  :close-button="true">

        <!-- Header -->
        <div class="row q-dialog__header">
          
          <!-- Icon -->
          <div class="col-auto row flex-center">
            <Svg :view-box="baseStation.viewBox"
                 :vector-paths="baseStation.vectorPaths" />
          </div>
        </div>

        <!-- Body -->
        <div class="row q-dialog__body">
          <div class="col">
            <h3 class="mb-18">{{ $t('header_base_station_piq') }}</h3>
            <p>{{ $t('base_station_reconnect_piq') }}</p>
          </div>
        </div>

        <!-- Footer (CTA button(s)) -->
        <div class="row q-dialog__footer">

          <!-- Call Support Button -->
          <div class="col-auto">
            <p>1-844-799-8208</p>
          </div>
        </div>
      </zbm-dialog>
    </template>
    
    <!-- Action(s) -->
    <template #action>
      <zbm-btn-icon @click="emits('dismissNotif', props.alert)"
                    icon="fas fa-times" />
    </template>
  </q-banner>
</template>

<style lang="scss" scoped>
// Canary Base Station Dialog Header Icon
.q-dialog__header > [class*="col"] {
  $q-dialog-icon-size: 74px;
  
  width: $q-dialog-icon-size;
  height: $q-dialog-icon-size;
  background-color: map-get($co-brand-clrs, content-assets-hero-def);
  color: $white;
  border-radius: 50%;
  
  svg {
    width: 50px;
    height: auto;
  }
}
</style>
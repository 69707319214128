<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick" class="" :boxWidth="$zb.enums.BoxWidthEnum.xl"
      :boxBkgdColor2="$zb.enums.BoxBackgroundColorEnum.white" :boxPadding2="$zb.enums.BoxPaddingEnum.p20"
      :boxBorderRadius2="$zb.enums.BoxBorderRadiusEnum.lg">

      <!-- Title -->
      <template v-slot:title>
        {{ $t('piq_bs_setup') }}
      </template>

      <!-- Heading -->
      <template v-slot:heading>
      </template>

      <!-- Main Content -->
      <template v-slot:default>

        <div> {{ $t('piq_bs_intro') }}</div>
        <ol>
          <li>{{ $t('piq_bs_step_one') }}</li>
          <li>{{ $t('piq_bs_step_two') }}</li>
          <li>{{ $t('piq_bs_step_three') }}</li>
          <li v-html="$t('piq_bs_step_four')"></li>
          <li>
            {{ $t('piq_bs_step_five') }}
            <ol>
              <li>
                <a href="https://apps.microsoft.com/store/detail/basestationsetuptool/9NXD0V891FWP">
                  {{ $t('piq_bs_step_five_pc') }}
                </a>
              </li>
              <li>
                <a href="https://apps.apple.com/us/app/bsst-canary-medical/id1562317016">
                  {{ $t('piq_bs_step_five_mac') }}
                </a>
              </li>
            </ol>
          </li>
          <li>{{ $t('piq_bs_step_six') }}</li>
          <li>{{ $t('piq_bs_step_seven') }}</li>
          <li v-html="$t('piq_bs_step_eight')"></li>
          <li>{{ $t('piq_bs_step_nine') }}</li>

        </ol>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import { updateCanaryCompleteBaseStationSetup } from 'src/api/endpoints/patientWeb';
import { useDashboardStore } from 'src/store';
import { TaskType } from 'src/constants/enums';
import { onMounted } from 'vue';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';

const router = useRouter();
const $q = useQuasar()
const dashboardStore = useDashboardStore()

const props = defineProps({
  itemId: {
    type: Number,
    required: true,
  }
})

onMounted(async () => {
  $q.loading.show()
  await updateCanaryCompleteBaseStationSetup().then(() => {
    dashboardStore.markTaskCompleted({ id: Number(props.itemId), taskType: TaskType.piq });
  }).finally(() => {
    $q.loading.hide()
  });
})

function onBackClick() {
  router.go(-1);;
}

</script>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import {
  useActivationStore,
  useLocalizationStore
} from 'src/store';
import {
  computed,
  onMounted,
  ref
} from 'vue';
import { Workflow } from 'src/constants/enums';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';

const router = useRouter();

const activationStore = useActivationStore(),
  localizationModule = useLocalizationStore();

const forgotPasswordForm = ref();

const countryCode = ref(''),
  isLoading = ref(true),
  phoneTelNumberInputModel = ref({
    value: '',
    phoneObject: {}
  }),
  username = ref('');

const countryOptions = computed(() => activationStore.supportedCountries.countryOptions);
const derivedCountryCode = computed(() => localizationModule.region);

async function onNextClick() {
  const isValid = await forgotPasswordForm.value?.validate();

  if (isValid) {
    router.push({
      name: 'ConfirmIdentity',
      query: {
        workflow: Workflow.forgotPassword.toString(),
        backRoute: 'ForgotPassword',
        nextRoute: 'SetPassword',
        phoneCountryCode: phoneTelNumberInputModel.value.phoneObject.countryCallingCode,
        username: username.value,
        phoneNumber: phoneTelNumberInputModel.value.phoneObject.nationalNumber,
        country: countryCode.value,
        progress: '66',
      },
    });
  }
}

function onBackClick() {
  activationStore.clear();
}

onMounted(() => {
  countryCode.value = activationStore.supportedCountries.selectedCountryCode;

  document.querySelectorAll('html')[0].scrollTop = 0;
  (document.querySelectorAll('input')[0] as HTMLElement).focus();

  isLoading.value = false;
})
</script>

<template>
  <q-page class="forgot-password" padding>
    <AuthenticateLayout :loading="isLoading" withHeader>

      <!-- Heading -->
      <template #heading>{{ $t('forgot_password_title') }}</template>

      <!-- View Unique Content -->
      <template #default>

        <!-- Instructions -->
        <p class="font-size-20 mb-30">
          <zbm-skeleton-loader height="30px" :loading="isLoading" width="100%">
            {{ $t('forgot_password_instructions') }}
          </zbm-skeleton-loader>
        </p>

        <!-- Form -->
        <q-form @submit.prevent="onNextClick" ref="forgotPasswordForm">

          <!-- Username -->
          <div class="row form-row">
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-input-with-validation v-model="username" :placeholder="$t('activate_username')"
                  :rules="[$zb.validators.required()]" />
              </zbm-skeleton-loader>
            </div>
          </div>

          <!-- Phone Number -->
          <div class="row form-row">
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-telephone-with-validation v-model="phoneTelNumberInputModel"
                  :patient-country-code="derivedCountryCode" :placeholder="$t('activate_phone_number')"
                  :rules="[$zb.validators.phoneRequired()]" />
              </zbm-skeleton-loader>
            </div>
          </div>

          <!-- Country Selector -->
          <div class="row items-center country">

            <!-- Label -->
            <div class="col-auto font-size-20 text-no-wrap">
              <zbm-skeleton-loader height="30px" :loading="isLoading" width="107px">
                {{ $t('provider_select_country_button') }}
              </zbm-skeleton-loader>
            </div>
            <div class="flex-br" />

            <!-- Select -->
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-select-with-validation v-model="countryCode" :name="$t('provider_select_country_button')"
                  option-label="text" :options="countryOptions" optionValue="value"
                  :rules="[$zb.validators.required()]" />
              </zbm-skeleton-loader>
            </div>
          </div>
        </q-form>
      </template>

      <!-- Actions/Progress -->
      <template #actions>
        <ProgressBar @on-back-click="onBackClick" @on-next-click="onNextClick" backRoute="Login" :loading="isLoading"
          :nextEnabled="true" :progress="33" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<style lang="scss" scoped>
.forgot-password {
  padding-inline: 0;

  @media (min-width: 600px) {

    // Form
    .q-form {

      // Country Selector
      .country {

        .flex-br {
          display: none;
        }

        [class*=col]:first-child {
          padding-inline-end: 10px;
        }
      }
    }
  }
}
</style>
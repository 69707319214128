import { useLocalizationStore } from '@/store';

export function reformatString(str: string): string {
  return str.replace(/\\n/g, '<br />').replace(/\\/g, '');
}

export function localizedNumberValue(value: number | string) {
  if (value === null || value === undefined || typeof value === 'undefined') {
    return '--'
  }
  const localizationStore = useLocalizationStore();
  return Number(value).toLocaleString(localizationStore.locale);
}

// Search the message content for <a> & </a> tags and remove them. Get URL as a regex
// result.
export function linkify(content: string) {
  const anchorOpen = /<a.*?>/g,
    anchorClose = /<\/a>/g,
    url = /(http:\/\/|https:\/\/)([\S]+)/g,
    str = content.replace(anchorOpen, '').replace(anchorClose, '');
  
  return str.replace(url, formatLink);
}

// Search the "match" of `linkify()` and see if it ends with a punctuation
// mark. Add an <a> to the "match" and if needed move the punctuation mark.
function formatLink(match: string) {
  if (/[.!?]$/.test(match)) {
    const urlWithMark = /(http:\/\/|https:\/\/)([\S]+)([.!?]$)/g;
    
    return match.replace(urlWithMark, '<a href="$1$2" target="_blank">$1$2</a>$3');
  }
  
  return '<a href="' + match + '" target="_blank">' + match + '</a>';
}
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick" showFooter>
      <template v-slot:heading>
        {{ $t('piq_account_finalize_profile') }}
      </template>

      <template v-slot:default>
        <div class="row">
          <div>
            <p>{{ $t('piq_account_finalize_profile') }}</p>
            <div class="col">
              <p>{{ $t('piq_finalize_profile_intro') }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <Box class="col" :box-width="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20">
          <ProgressBar :progress="10" :backEnabled="true" v-model:nextEnabled="formIsValid" @on-back-click="onBackClick"
            @on-next-click="onNextClick" />
        </Box>
      </template>

    </MultiViewLayout>
  </q-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { getNextActivationRoute } from 'src/services/piqSetupService';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();

const formIsValid = ref(true);

async function onNextClick() {
  await getNextActivationRoute($q).then((result) => {
    router.push({ name: result });
  });
}

async function onBackClick() {
  router.go(-1);
}

</script>
import moment from 'momentCultured';

export const LOCAL_DATETIME_FORMAT = 'L[,] LT';
export const LOCAL_DATE_FORMAT = 'L';
export const LOCAL_TIME_FORMAT = 'LT';
const tempLocale = navigator.languages[0]; //use the browser setting as a default

export function toUtcDateString(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

export function toUserFacingDate(localDateString: string | null | undefined, includeTime = false, locale = tempLocale): string {
  const format = includeTime ? LOCAL_DATETIME_FORMAT : LOCAL_DATE_FORMAT;
  switch (locale.toLowerCase()) {
    case 'en-ch': //this is a fake Swiss-English
      locale = 'de-CH';
      break;
    case 'sv-fi': //this is a Swedish in Finland
    case 'en-za':
      locale = 'zh-cn'; //just picking sv since they're similar
      break;
    default:
      break;
  }
  return localDateString
    ? moment(localDateString).locale(locale).format(format)
    : '';
}

export function toUserFacingTime(localTimeString: string | null | undefined, locale = tempLocale): string {
  return localTimeString
    ? moment(localTimeString).locale(locale).format(LOCAL_TIME_FORMAT)
    : '';
}

export function getISO8601Date(): string {
  return moment().toISOString();
}

export function localizedStatDate(date: string, locale = tempLocale) {
  return moment(date).utc().locale(locale).format('MMM D');  //'Dec 10'
}
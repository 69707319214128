<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import {
  computed,
  onMounted,
  ref,
  watch
} from 'vue';
import { useRouter } from 'vue-router';
import routes from 'src/router/routes';
import { useQuasar } from 'quasar';
import {
  useActivationStore,
  useLocalizationStore,
  useSessionStore
} from 'src/store';
import { registerSyntheticMomentCultures } from 'momentCultured';
import Header from '@/components/header/Header.vue';
import TheGeneralPurposeModal from '@/components/TheGeneralPurposeModal.vue';
import Footer from '@/components/Footer.vue';

import { bus } from '@/utils/eventBus';
import { setupInactivityRedirect } from '@/utils/idleLogout';
import { userLogout } from '@/services/userService';
import { handleApiRequest } from './api/handleApiRequest';
const activationStore = useActivationStore(),
  localizationStore = useLocalizationStore(),
  sessionStore = useSessionStore(),
  $q = useQuasar();

const bootstrappingApp = ref(true),
  leftDrawerOpen = ref(false),
  rightDrawerOpen = ref(false);

const isFooterVisibile = computed(() => sessionStore.isFooterVisible),
  isHeaderVisible = computed(() => sessionStore.isHeaderVisible),
  // Keep-alive for the dashboard is disabled when users are not authenticated to prevent data from persisting after logout.
  keepAliveIncludes = computed(() => {
    if(sessionStore.isAuthenticated === true) 
      return ['TheDashboard', 'Authenticate'];
    return ['Authenticate'];
  });

(async () => {
  await activationStore.loadCountries();
  await localizationStore.loadBestFitCulture();
  await localizationStore.loadLocalizedStrings();
  const cobrandingResp = await handleApiRequest((axios) => axios.get('/Login/GetClientStyleCSS'), null, $q)
  if (cobrandingResp?.result) {
    sessionStore.setCobranding(cobrandingResp.result.imageUrls, cobrandingResp.result.theme)
  }

  bootstrappingApp.value = false;
})();

onMounted(async () => {
  const $router = useRouter(),
    $q = useQuasar();

  // eventBus handler
  bus.on('user-log-out', async (routeName = 'Welcome') => {
    try {
      await userLogout($q);
    } finally {
      if (routeName)
        $router.push({ name: routeName });
    }
  });

  bus.on('user-redirect', async (routeName: string) => {
    $router.push({ name: routeName });
  });

  // start user idle 
  setupInactivityRedirect();
})

</script>

<template>
  <q-layout view="lhr LpR lfr">

    <!-- Header -->
    <Header v-if="isHeaderVisible" />

    <!-- Left Drawer -->
    <q-drawer v-model="leftDrawerOpen" side="left" bordered overlay>
      <!-- Left drawer content would go here. -->
    </q-drawer>

    <!-- Right Drawer -->
    <q-drawer v-model="rightDrawerOpen" side="right" bordered overlay>
      <!-- Right drawer content would go here. -->
    </q-drawer>

    <!-- Page Container -->
    <q-page-container>

      <!-- Page Specific Content -->
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveIncludes">
          <component :is="Component" />
        </keep-alive>
      </router-view>

      <!-- Don't think we are using this anymore? I think we will use QNotify now ... -->
      <!-- <AlertContainer :alert="{ purpose: $zb.enums.AlertTypeEnum.Error }" /> -->

      <TheGeneralPurposeModal />
    </q-page-container>

    <!-- Footer -->
    <Footer v-if="isFooterVisibile" />
  </q-layout>
</template>
<template>
  <div :class="['the-expandable-my-stat', stat]">
    <!-- Title -->
    <div class="row the-expandable-my-stat__title mb-8">
      <div class="col">
        {{ $t(title) }}<template v-if="measurementUnit"> ({{ $t(measurementUnit) }})</template>
      </div>
    </div>

    <q-card flat bordered class="the-expandable-my-stat__data">

      <!-- Heading -->
      <q-card-section class="row px-none items-center the-expandable-my-stat__title">
        <!-- Icon -->
        <div class="col-auto px-16 data__heading-icon">
          <q-icon name="fa fa-shoe-prints" v-if="headingIcon === null" />
          <Svg v-else :vector-paths="props.headingIcon?.vectorPaths" :view-box="props.headingIcon?.viewBox" />
        </div>
        <!-- Total -->
        <div class="col-auto pr-16 data__heading-total">{{ headingTotal }}</div>
        <!-- Date -->
        <div class="col-auto pr-16 data__heading-date">{{ piq ? headingDate : $t(headingDate) }}</div>
      </q-card-section>

      <!-- Averages -->
      <q-card-section class="row data__averages px-none items-center">
        <!-- Left -->
        <div class="col pa-16">
          <!-- Type -->
          <p v-if="piq">{{ $t('stats_last_month_piq') }}</p>
          <p v-else>{{ $t('education_pre_surgery') }}</p>

          <!-- Value -->
          <p class="data__averages-value">
            {{ piq ? lastMonth : avgPerDay
            }}<span v-if="!piq && avgPerDayTrending" :class="avgPerDayTrending"></span>
          </p>

          <!-- Unit -->
          <p v-if="piq">{{ $t('stats_average_piq') }}</p>
          <p v-else>{{ $t('stats_steps_day_avg') }}</p>
        </div>
        <!-- Right -->
        <div class="col pr-16">
          <!-- Type -->
          <p v-if="piq">{{ $t('stats_last_week_piq') }}</p>
          <p v-else>{{ $t('education_post_surgery') }}</p>

          <!-- Value -->
          <p class="data__averages-value">
            {{ piq ? lastWeek : fiveDayAvg
            }}<span v-if="!piq && fiveDayAvgTrending" :class="fiveDayAvgTrending"></span>
          </p>

          <!-- Unit -->
          <p v-if="piq">{{ $t('stats_average_piq') }}</p>
          <p v-else>{{ $t('stats_steps_5day_avg') }}</p>
        </div>
      </q-card-section>
      <!-- Show/Hide Details -->
      <q-card-section class="row px-none py-30 justify-center" v-show="visible">
        <div class="col">
          <PiqGraph class="col" :measurementType="measurementType" :source="sourceType" :device="deviceId"
            ref="graphComponent" />
        </div>
      </q-card-section>
      <q-card-section v-if="rankedComparison"
                      class="row justify-center px-none py-12 the-expandable-my-stat__comparison">
        <div class="col-auto">
          <zbm-btn class="text-weight-regular font-size-16"
                   :outline="false"
                   :rounded="false"
                   text-color="accent"
                   :to="{ name: 'Dashboard.MyStats.MyProgress', params: { deviceId: props.deviceId, metric: rankedComparison.comparisonDataType } }">
            <Svg class="mr-8"
                 style="width: 20px; height: auto"
                 :vector-paths="comparison.vectorPaths"
                 :view-box="comparison.viewBox" />
            {{ $t('stats_view_comparisons') }}
          </zbm-btn>
        </div>
      </q-card-section>
      <q-card-section class="row justify-center px-none py-12 the-expandable-my-stat__view-details">
        <div class="col-auto">
          <zbm-btn @click="toggleDetails"
                   class="text-weight-regular font-size-16"
                   icon="fas fa-chart-bar"
                   :label="$t('stats_view_activity')"
                   :outline="false"
                   :rounded="false"
                   text-color="accent" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref, watch } from 'vue';
import PiqGraph from 'src/pages/DashboardMyStats/components/PiqGraph.vue';
import Svg from '@/components/Svg.vue';
import { type DataSourceRankedMetric } from '@/types/webContracts';
import { comparison } from '@/assets';

const visible = defineModel('visible')
const graphComponent = ref();

const props = defineProps({
  stat: String,
  title: String,
  sourceType: String,
  deviceId: { type: Number, required: false },
  measurementType: { type: String, required: false },
  measurementUnit: String,
  headingIcon: { type: Object as PropType<Svg>, default: null },
  headingTotal: { type: String, default: '--' },
  headingDate: String,
  piq: String,
  lastMonth: { type: String, default: '--' },
  lastWeek: { type: String, default: '--' },
  avgPerDay: { type: String, default: '--' },
  avgPerDayTrending: { type: String, default: '--' },
  fiveDayAvg: { type: String, default: '--' },
  fiveDayAvgTrending: { type: String, default: '--' },
  readySignal: Number,
  rankedComparison: { type: Object as PropType<DataSourceRankedMetric | null | undefined> }
});

watch(() => props.readySignal, (nV) => {
  if (nV > 1) {
    graphComponent.value.reset()
  }
})

function toggleDetails() {
  visible.value = !visible.value
  graphComponent.value.initialize()
}
</script>
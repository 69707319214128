<script setup lang="ts">
import {
  computed,
  defineProps,
  type PropType,
  ref,
  watch
} from 'vue';
import { i18n } from '@/plugins/i18n';
import { type PatientProgressResponse } from '@/types/webContracts';
import {
  AIProgressDataTypes,
  AIProgressRanks,
  ButtonWidthEnum
} from '@/constants/enums';
import moment from 'momentCultured';

const $i18nt = i18n.global.t;

const props = defineProps({
  isLoading: {
    type: Boolean
  },
  metric: {
    type: String
  },
  metricData: {
    type: Object as PropType<PatientProgressResponse>
  }
});

const patFactorsVisible = ref(false),
  readMore = ref(false);

const archived = computed(() => props.metricData?.dataCollectingStatus === 'post'),
  isWearables = computed(() => props.metric === AIProgressDataTypes.WalkSessionCountAvg || props.metric === AIProgressDataTypes.StepsAvg),
  moreText = computed(() => readMore.value ? $i18nt('show_less') : $i18nt('show_more')),
  viewMyPredictions = computed(() => false);

const comparisonHeading = computed(() => {
  let heading = undefined;

  if (props.metric === AIProgressDataTypes.StepsAvg) { // HK Steps
    switch (props.metricData?.progress.rankInCohortDataType) {
      case AIProgressRanks.Low:
        heading = $i18nt('steps_low_rank_title');
        break;
      case AIProgressRanks.OnTrack:
        heading = $i18nt('steps_ontrack_rank_title');
        break;
      case AIProgressRanks.High:
        heading = $i18nt('steps_high_rank_title');
        break;
      default:
        break;
    }
  } else if (props.metric === AIProgressDataTypes.MaxStepsAvg) { // PIQ Qualified Steps
    switch (props.metricData?.progress.rankInCohortDataType) {
      case AIProgressRanks.Low:
        heading = $i18nt('steps_piq_low_title');
        break;
      case AIProgressRanks.OnTrack:
        heading = $i18nt('steps_piq_ontrack_title');
        break;
      case AIProgressRanks.High:
        heading = $i18nt('steps_piq_high_title');
        break;
      default:
        break;
    }
  } else if (props.metric === AIProgressDataTypes.KneeROMAvg) { // PIQ Walking Motion
    switch (props.metricData?.progress.rankInCohortDataType) {
      case AIProgressRanks.Low:
        heading = $i18nt('rom_piq_low_title');
        break;
      case AIProgressRanks.OnTrack:
        heading = $i18nt('rom_piq_ontrack_title');
        break;
      case AIProgressRanks.High:
        heading = $i18nt('rom_piq_high_title');
        break;
      default:
        break;
    }
  } else if (props.metric === AIProgressDataTypes.WalkSessionCountAvg) { // HK Walking Sessions
    switch (props.metricData?.progress.rankInCohortDataType) {
      case AIProgressRanks.Low:
        heading = $i18nt('walking_low_rank_title');
        break;
      case AIProgressRanks.OnTrack:
        heading = $i18nt('walking_ontrack_rank_title');
        break;
      case AIProgressRanks.High:
        heading = $i18nt('walking_high_rank_title');
        break;
      default:
        break;
    }
  }

  return heading;
});

const comparisonSubHeading = computed(() => {
  let subHeading = undefined;

  switch (props.metric) {
    case AIProgressDataTypes.StepsAvg: // HK Steps
      subHeading = $i18nt('walking_high_rank_subheader');
      break;
    case AIProgressDataTypes.MaxStepsAvg: // PIQ Qualified Steps
      subHeading = $i18nt('steps_piq_high_subheader');
      break;
    case AIProgressDataTypes.KneeROMAvg: // PIQ Walking Motion
      subHeading = $i18nt('rom_piq_high_title_subheader');
      break;
    case AIProgressDataTypes.WalkSessionCountAvg: // HK Walking Sessions
      subHeading = $i18nt('steps_high_rank_subheader');
      break;
    default:
      break;
  }

  return subHeading;
});

const metricDescription = computed(() => {
  let description = undefined;

  switch (props.metric) {
    case AIProgressDataTypes.MaxStepsAvg: // PIQ Qualified Steps
      description = $i18nt('qualified_step_description');
      break;
    case AIProgressDataTypes.KneeROMAvg: // PIQ Walking Motion
      description = $i18nt('walking_rom_description');
      break;
    default:
      break;
  }

  return description;
});

const markerLeft = computed(() => {
  let left = 0;

  if (props.metricData?.patientValueAsPercentageOfMax) {
    left = props.metricData?.patientValueAsPercentageOfMax * 100;
  }

  return `${left}%`;
});

const comparisonRanking = computed(() => {
  let key = '',
    ranking = `<span class="${props.metricData?.progress.rankInCohortDataType.toLowerCase()}">${props.metricData?.progress.rankInCohortDataType}</span>`;

  switch (props.metric) {
    case AIProgressDataTypes.StepsAvg: // HK Steps
      key = 'steps_ontrack_rank_msg';
      break;
    case AIProgressDataTypes.MaxStepsAvg: // PIQ Qualified Steps
      key = 'steps_piq_rank_msg';
      break;
    case AIProgressDataTypes.KneeROMAvg: // PIQ Walking Motion
      key = 'rom_piq_rank_msg';
      break;
    case AIProgressDataTypes.WalkSessionCountAvg: // HK Walking Sessions
      key = 'walking_ontrack_rank_msg';
      break;
    default:
      break;
  }

  return $i18nt(key, [ranking]);
});

const comparisonUpdated = computed(() => {
  const now = moment();

  let interpolatedVal: string,
    key: string;

  if (props.metricData?.isDataStale && !archived.value) { // Stale
    interpolatedVal = now.diff(props.metricData?.lastUpdatedDateTime, 'days').toString();
    key = 'based_on_data';

    if (isWearables.value) {
      key = 'based_on_data_wearables';
    }
  } else if (archived.value) { // Archived
    interpolatedVal = moment(props.metricData?.lastUpdatedDateTime).format('LL');
    key = 'based_on_data_archived';

    if (isWearables.value) {
      key = 'based_on_data_archived_wearables';
    }
  } else {
    const lastUpdated = now.diff(props.metricData?.lastUpdatedDateTime, 'days'),
      oneDay = lastUpdated === 1;

    interpolatedVal = lastUpdated.toString();
    key = oneDay ? 'based_on_data_single' : 'based_on_data';

    if (isWearables.value) {
      key = oneDay ? 'based_on_data_wearables_single' : 'based_on_data_wearables';
    }
  }

  return $i18nt(key, [interpolatedVal]);
});

const comparisonUpdatedConnection = computed(() => {
  let key = $i18nt('reconnect_base');

  if (isWearables.value) {
    key = $i18nt('reconnect_mobile');
  }

  return key;
});

const explanationTitle = computed(() => {
  let title = $i18nt('what_this_means_walkai_text');

  if (props.metricData?.progress.rankInCohortDataType === AIProgressRanks.Low) {
    title = $i18nt('steady_improve_low_rank_title');
  }

  return title;
});

const youVthemMeasurement = computed(() => {
  let measurement = '';

  switch (props.metric) {
    case AIProgressDataTypes.StepsAvg: // HK Steps
      measurement = $i18nt('steps_metric');
      break;
    case AIProgressDataTypes.WalkSessionCountAvg: // HK Walking Sessions
      measurement = $i18nt('session_text');
      break;
    default:
      break;
  }

  return measurement;
});

function determineFactorsIcon(type: string) {
  let icon = '';

  switch (type) {
    case 'Cohort_Attribute_Type_Age': // Age
      icon = 'fas fa-calendar-alt';
      break;
    case 'BMI cohort attribute': // BMI
      icon = 'fal fa-tachometer-fast';
      break;
    case 'Cohort_Attribute_Type_Sex': // Gender/Sex
      icon = 'fas fa-user';
      break;
    case 'Cohort_Attribute_Type_Preop_Activity': // Preop Activity
      icon = 'far fa-history';
      break;
    case 'Procedure cohort attribute': // Procedure
    case 'Cohort_Attribute_Type_Specific_Procedure':
      icon = 'fas fa-clipboard-list';
      break;
    default:
      break;
  }

  return icon;
}

watch(() => props.metric, (newMetric, oldMetric) => {
  if (newMetric !== oldMetric) {
    patFactorsVisible.value = false;
    readMore.value = false;
  }
});
</script>

<template>

  <!-- Metric Description -->
  <div v-if="metricDescription" class="description mb-30">

    <!-- Text -->
    <p :class="['text', { 'all': readMore }]">
      {{ metricDescription }}
      <span v-if="!readMore">...</span>
    </p>

    <!-- Read More -->
    <p class="read-more">
      <span @click="readMore = !readMore">{{ moreText }}</span>
    </p>
  </div>

  <!-- Comparison -->
  <zbm-box class="mb-24 comparison">

    <!-- Heading -->
    <h4 :class="[
      'text-center',
      props.metricData?.progress.rankInCohortDataType === AIProgressRanks.High
        ? 'mb-16'
        : 'mb-0'
    ]">
      {{ comparisonHeading }}
    </h4>

    <!-- SubHeading -->
    <p v-if="props.metricData?.progress.rankInCohortDataType === AIProgressRanks.High" class="text-center mb-0">
      {{ comparisonSubHeading }}
    </p>

    <!-- Graph -->
    <div class="comparison__graph mb-30">

      <!-- Track -->
      <div class="track">

        <!-- Marker -->
        <div :class="['marker', props.metricData?.progress.rankInCohortDataType.toLowerCase()]">

          <!-- Marker Label -->
          <div class="marker__label">
            {{ $t('you_text') }}
          </div>
        </div>
      </div>

      <!-- Labels -->
      <div class="row items-center labels">

        <!-- Low -->
        <div class="col-auto low">{{ $t('low_rank_text') }}</div>

        <!-- On Track -->
        <div class="col">{{ $t('on_track_rank_text') }}</div>

        <!-- High -->
        <div class="col-auto high">{{ $t('high_rank_text') }}</div>
      </div>
    </div>

    <!-- Ranking -->
    <p v-html="comparisonRanking" class="mb-24 comparison__ranking" />

    <!-- Updated -->
    <div class="row items-center mb-24 comparison__updated">
      <div v-html="comparisonUpdated"
        :class="['col', props.metricData?.isDataStale || archived ? 'text-right pr-8' : 'text-center']" />

      <!-- Stale -->
      <div v-if="props.metricData?.isDataStale && !archived" class="col row items-center">

        <!-- Icon -->
        <div class="col-auto">
          <q-icon name="fal fa-sync" size="16px" />
        </div>

        <!-- String -->
        <div class="col-auto pl-4">{{ comparisonUpdatedConnection }}</div>
      </div>

      <!-- Archived -->
      <div v-else-if="archived" class="col row items-center">

        <!-- Icon -->
        <div class="col-auto">
          <q-icon name="fal fa-book" size="16px" />
        </div>

        <!-- String -->
        <div class="col-auto pl-4">{{ $t('archived_data') }}</div>
      </div>
    </div>

    <!-- Normal Recovery -->
    <p class="comparison__normal">{{ $t('normal_recovery_static_text') }}</p>

    <!-- Patient Factors -->
    <template v-if="metricData?.cohortFactors.length">

      <!-- Button -->
      <div class="text-center">
        <zbm-btn @click="patFactorsVisible = !patFactorsVisible" class="comparison__factors-btn"
          :icon-right="`fal fa-angle-${patFactorsVisible ? 'up' : 'down'}`"
          :label="patFactorsVisible ? $t('hide_patient_factors') : $t('show_patient_factors')" :outline="false"
          :square="true" text-color="content-trg-primary-def" />
      </div>

      <!-- Factors -->
      <q-expansion-item v-model="patFactorsVisible" :duration="0" :hide-expand-icon="true">
        <div v-for="(factor, i) in metricData?.cohortFactors" class="row items-center" :key="i">

          <!-- Icon -->
          <div class="col-auto flex flex-center icon">
            <q-icon :name="determineFactorsIcon(factor.dataType)" />
          </div>

          <!-- Title/SubTitle -->
          <div class="col pl-16">

            <!-- Title -->
            <p class="mb-none title">{{ factor.localizedTitle }}</p>

            <!-- SubTitle -->
            <p class="subtitle">{{ factor.localizedSubtitle }}</p>
          </div>
        </div>
      </q-expansion-item>
    </template>
  </zbm-box>

  <!-- Explanation -->
  <zbm-box v-if="props.metricData?.progress.rankInCohortDataType"
    :class="[{ 'mb-24': isWearables || viewMyPredictions }, 'explanation']">

    <!-- Title -->
    <p class="mb-16">{{ explanationTitle }}</p>

    <!-- List of ... -->
    <ul>

      <!-- Low -->
      <template v-if="props.metricData.progress.rankInCohortDataType === AIProgressRanks.Low">
        <li>{{ $t('explain_low_rank_one') }}</li>

        <!-- Walking Motion or Walking Sessions -->
        <li
          v-if="props.metric === AIProgressDataTypes.KneeROMAvg || props.metric === AIProgressDataTypes.WalkSessionCountAvg">
          {{ $t('explain_walk_low_rank_two') }}
        </li>

        <!-- Qualified Steps or Steps -->
        <li v-else-if="AIProgressDataTypes.MaxStepsAvg || AIProgressDataTypes.StepsAvg">
          {{ $t('explain_steps_low_rank_two') }}
        </li>
        <li>{{ $t('explain_low_rank_three') }}</li>
      </template>

      <!-- On Track -->
      <template v-else-if="props.metricData.progress.rankInCohortDataType === AIProgressRanks.OnTrack">
        <li>{{ $t('explain_ontrack') }}</li>
      </template>

      <!-- High -->
      <template v-else-if="props.metricData.progress.rankInCohortDataType === AIProgressRanks.High">
        <li>{{ $t('explain_high_one') }}</li>
        <li>{{ $t('explain_high_two') }}</li>
        <li>{{ $t('explain_high_three') }}</li>
      </template>
    </ul>
  </zbm-box>

  <!-- You vs. Patients Like You -->
  <div v-if="isWearables" :class="['row no-wrap', { 'mb-24': viewMyPredictions }, 'you-v-them']">

    <!-- You -->
    <zbm-box class="col-auto">

      <!-- Title -->
      <p class="mb-12 title">{{ $t('you_text') }}</p>

      <!-- Metric Value -->
      <div class="mb-12 row flex-center text-primitives-values-100">

        <!-- Value -->
        <div class="col-auto value">{{ props.metricData?.patientValue }}</div>

        <!-- Measurement -->
        <div class="col-auto text-weight-semibold">{{ youVthemMeasurement }}</div>
      </div>

      <!-- Caption -->
      <p class="caption">{{ $t('average_per_day_text') }}</p>
    </zbm-box>

    <!-- Patients Like You -->
    <zbm-box>

      <!-- Title -->
      <p class="mb-12 title">{{ $t('patients_like_you') }}</p>

      <!-- Metric Value -->
      <div class="mb-12 row flex-center text-primitives-values-100">

        <!-- Value -->
        <div class="col-auto value">{{ props.metricData?.cohortValue }}</div>

        <!-- Measurement -->
        <div class="col-auto text-weight-semibold">{{ youVthemMeasurement }}</div>
      </div>

      <!-- Caption -->
      <p class="caption">{{ $t('average_per_day_text') }}</p>
    </zbm-box>
  </div>

  <!-- Predictions -->
  <zbm-btn v-if="isWearables && viewMyPredictions" class="prediction" color="primitives-pop-100"
    icon-right="far fa-angle-right" label="Predict my future walk speed" :outline="false" :rounded="false"
    :to="{ name: 'Dashboard.Home' }" :width="ButtonWidthEnum.wFull" />
</template>

<style scoped lang="scss">
@use "sass:math";

// Metric Description
.description {
  // <div>

  p {
    font-size: map-get($font-sizes, '16');

    // Text
    &.text {
      // <p>
      position: relative;
      height: 48px;
      color: $content-txt-secondary;
      overflow: hidden;
      margin-block-end: 0;

      &.all {
        // <p>
        height: auto;
        overflow: visible;
      }

      span {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $white;
      }
    }

    // Read More
    &.read-more {
      // <p>
      text-align: right;

      span {
        cursor: pointer;
        color: $content-trg-inline-link;
      }
    }
  }
}

.box {
  border: $generic-border;
}

// Comparison & Means for You
.comparison,
.explanation {
  // .box
  padding-block: 24px;
  padding-inline: 16px;
}

// Comparison
.comparison {
  // .box
  padding-block-end: 6px;

  // Graph
  &__graph {
    // <div>
    padding-block-start: 64px;

    // Track
    .track {
      // <div>
      $track-h: 18px;

      position: relative;
      width: 100%;
      height: $track-h;
      background-image: linear-gradient(to right, $content-low 0%, $content-low 14%, $white 14%, $white 16%, $content-on-track 16%, $content-on-track 84%, $white 84%, $white 86%, $content-high 86%, $content-high 100%);

      // Marker
      .marker {
        // <div>
        $marker-size: 28px;
        $marker-brdr-w: 4px;

        position: absolute;
        top: -(math.div($marker-size - $track-h, 2));
        left: v-bind(markerLeft);
        width: $marker-size;
        height: $marker-size;
        transform: translateX(math.div(-$marker-size, 2));
        background-color: $white;
        border-width: $marker-brdr-w;
        border-style: solid;
        border-radius: 50%;

        &.low {
          border-color: $content-low;
        }

        &.on-track {
          border-color: $content-on-track;
        }

        &.high {
          border-color: $content-high;
        }

        &__label {
          position: absolute;
          top: -35px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $cnt-widgets-alerts-def;
          font-size: map-get($font-sizes, '14');
          line-height: 20px;
          color: $content-txt-badge-content;
          padding: 2px 4px;
          border-radius: $generic-border-radius-sm;

          &::after {
            $tooltip-brdr-inline-w: 3px;

            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-$tooltip-brdr-inline-w);
            content: '';
            border-inline: $tooltip-brdr-inline-w solid transparent;
            border-block-start: 4px solid $cnt-widgets-alerts-def;
          }
        }
      }
    }

    // Labels
    .labels {
      // .row
      height: 27px;
      font-size: map-get($font-sizes, '12');
      color: $content-txt-secondary;
      text-align: center;

      .low,
      .high {
        // [class*="col"]
        width: 14%;
        max-width: 14%;
        flex-basis: 14%;
      }
    }
  }

  // Ranking
  &__ranking {
    // <p>
    font-size: map-get($font-sizes, '16');
    color: $content-txt-secondary;
    text-align: center;

    :deep(span) {
      font-weight: map-get($text-weights, bold);
      padding: 2px 8px;

      &.low {
        // <span>
        background-color: $cnt-widgets-alerts-low;
        color: $content-low;
      }

      &.on-track {
        // <span>
        background-color: $cnt-widgets-alerts-on-track;
        color: $content-on-track;
      }

      &.high {
        // <span>
        background-color: $cnt-widgets-alerts-high;
        color: $content-high;
      }
    }
  }

  // Updated
  &__updated {
    // .row
    background-color: map-get($co-brand-clrs, primitives-foundation-10);
    padding-block: 6px;
    padding-inline: 16px;

    >[class*="col"] {
      font-size: 10px;
      line-height: 1;
      text-transform: uppercase;
      color: map-get($co-brand-clrs, content-txt-ttl);

      &:last-child:not(:first-child) {
        padding-inline-start: 8px;
        border-inline-start: 1px solid currentColor;
      }
    }
  }

  // Normal Recovery
  &__normal {
    // <p>
    line-height: 20px;
    color: $content-txt-secondary;
    text-align: center;
    margin-block-end: 22px;
  }

  // Patient Factors Button
  &__factors-btn {
    // <button>
    font-size: map-get($font-sizes, '16');
    margin-inline: auto;
    margin-block-end: 11px;

    :deep(.q-icon) {
      // <i>
      font-size: 24px;
    }
  }

  // Patient Factors
  .q-expansion-item {

    :deep(.q-item) {
      display: none;
    }

    :deep(.q-expansion-item__content) {
      padding: 20px;

      >.row:not(:last-child) {
        margin-block-end: 24px;
      }

      // Icon
      .icon {
        // [class*="col"]
        $comp-pat-factors-icon-size: 46px;

        width: $comp-pat-factors-icon-size;
        height: $comp-pat-factors-icon-size;
        background-color: $content-assets-icon-bg;
        font-size: map-get($font-sizes, '18');
        color: map-get($co-brand-clrs, content-assets-hero-def);
        border-radius: 50%;
      }

      // Title & SubTitle
      p {
        color: $content-txt-def;

        // Title
        &.title {
          line-height: 18px;
        }

        // SubTitle
        &.subtitle {
          font-weight: map-get($text-weights, bold);
          line-height: 22px;
        }
      }
    }
  }
}

// Explanation
.explanation {
  // .box

  // Title
  p {
    font-weight: map-get($text-weights, bold);
    color: map-get($co-brand-clrs, content-txt-ttl);
  }

  // List of ...
  ul {
    padding-inline-start: 18px;

    li {
      color: $content-txt-def;
      line-height: 22px;

      &:not(:last-child) {
        margin-block-end: 8px;
      }

      &::marker {
        color: map-get($co-brand-clrs, content-txt-ttl);
      }
    }
  }
}

// You vs. Patients Like You
.you-v-them {
  overflow-x: auto;

  .box {
    // [class*="col"]
    $you-v-them-box-size: 210px;

    min-width: $you-v-them-box-size;
    width: $you-v-them-box-size;
    max-width: $you-v-them-box-size;
    flex-basis: $you-v-them-box-size;
    text-align: center;
    padding-block: 16px;
    padding-inline: 18px;

    &:not(:last-child) {
      margin-inline-end: 10px;
    }

    p {
      color: map-get($co-brand-clrs, content-txt-subtitle);

      // Title
      &.title {
        // <p>
        font-weight: map-get($text-weights, semibold);
        line-height: 22px;
      }

      // Caption
      &.caption {
        // <p>
        font-size: map-get($font-sizes, '14');
        line-height: 20px;
      }
    }

    // Value
    .value {
      // [class*="col"]
      font-weight: map-get($text-weights, bold);
      font-size: 34px;
      line-height: 41px;
      margin-inline-end: 4px;
    }
  }

  @media (min-width: 490px) {
    justify-content: center;
  }
}

// Prediction
.prediction {
  // .q-btn
  font-weight: map-get($text-weights, regular);
  font-size: map-get($font-sizes, '16');
  border-radius: $generic-border-radius;
  padding-block: 15px;
  padding-inline: 12px 24px;

  :deep(.q-btn__content) {
    flex-wrap: nowrap;

    span {
      flex: 1 0 0%;
      width: 100%;
      max-width: 100%;
      text-align: left;
    }

    i {
      // .q-icon
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      font-size: 24px;
      margin-inline-start: 24px;
    }
  }
}
</style>
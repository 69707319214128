<script lang="ts" setup>
  import { ref } from 'vue';
  import { ClientFeatureType, UserPolicyType } from 'src/constants/enums';
  import UserPolicySignOff from 'src/pages/Activation/components/UserPolicySignOff.vue';
  import { useSessionStore} from 'src/store';

  const sessionStore = useSessionStore();
  const consentLabelLocalizerKey = sessionStore.user.clientFeatures.includes(ClientFeatureType.NonBAAPrivacyConsent)
    ? 'activate_privacy_policy_read_personal_data'
    : 'activate_privacy_policy_read';

  const policyType = ref(UserPolicyType.UserPolicyPrivacyPolicy);
</script>

<template>
  <q-page padding>
    <UserPolicySignOff :checkBoxLabelLocalizerKey="consentLabelLocalizerKey"
                       :headingLocalizerKey="'activate_privacy_policy'"
                       :modalBodyLocalizerKey="'activate_privacy_policy_checkbox_text'"
                       :nextRoute="'Activate.EULA'"
                       :policyType="policyType"
                       :progress="39"
                       :scrollToRead="true" />
  </q-page>
</template>
import type { GeneralPurposeModalModel } from 'src/types/interfaces';

import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'

export const useGeneralPurposeModalStore = defineStore('generalPurposeModal', () => {
  const modal: Ref<GeneralPurposeModalModel | null> = ref(null);

  const setModalMutation = (modalParam: GeneralPurposeModalModel | null) => {
    modal.value = modalParam;
  }

  const setModal = (modal: GeneralPurposeModalModel | null) => {
    setModalMutation(modal);
  }

  return {
    modal,
    setModal,
    setModalMutation
  }
})
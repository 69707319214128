<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { SurveyProgressAnswer, SurveyQuestionModel } from 'src/types/model';
import { SliderValueBehaviorEnum, SliderOrientationEnum } from 'src/constants/survey';

const props = defineProps({
  question: {
    type: Object as PropType<SurveyQuestionModel>,
    required: true,
  }
});

const tickMarks = computed(() => props.question.renderingConfig.tickMarks);

const numberOfTickMarks = computed(() => props.question.renderingConfig.numberOfTickMarks)

const key = computed(() => `${props.question.questionId}-slider`);
const isVertical = computed(() => props.question.renderingConfig.orientation === SliderOrientationEnum.vertical);
const isCheckmarkSliderBehavior = computed(() => props.question.renderingConfig.sliderValueBehavior === SliderValueBehaviorEnum.checkmark);
const valueScale = computed(() => props.question.renderingConfig.scale ?? 1);
const displayValue = computed(() => {
  if (props.question.renderingConfig.sliderValueBehavior === SliderValueBehaviorEnum.hide) {
    return '&nbsp;';
  } else if (
    props.question.renderingConfig.sliderValueBehavior === SliderValueBehaviorEnum.checkmark
  ) {
    return '';
  }

  if (valueScale.value > 0 && valueScale.value < 1) {
    var precision = Math.log10(valueScale.value) * -1
    if (precision % 1 === 0) {
      return sliderValue.value?.toFixed(precision);
    }
  }

  return sliderValue.value;
});
const sliderValue = computed({
  get(): number {
    return selectedValue.value[key.value];
  },
  set(newVal: number | null) {
    if (typeof selectedValue.value[key.value] === 'undefined') {
      selectedValue.value[key.value] = null;
    } else {
      selectedValue.value[key.value] = newVal;
    }
  }
});

const selectedValue = defineModel();

async function onDragEnd(index: number) {
  if (sliderValue.value == null && index == 0) {
    sliderValue.value = index;
  }
}

async function validate() {
  return true;
}

function getResponse(): SurveyProgressAnswer {
  var result;

  if (sliderValue.value != null) {
    //Revert scaling to get value we can send to API.
    result = (sliderValue.value as number) / valueScale.value;
    result = Math.round(result);
  }

  return {
    question: props.question,
    //We create a fake permitted answer because sliders only come from the server defined with records
    //for min/max values as well as values associated with labels.
    //The API expects the selected slider value to be submitted as the answerId
    permittedAnswer: {
      answerId: 0,
      name: '',
      renderingConfig: props.question.permittedAnswers[0].renderingConfig,
    },
    value: result,
  };
}

defineExpose({
  getResponse,
  validate
});
</script>

<template>
  <div class="row justify-center survey__slider">
    <div class="col-auto">

      <!-- Survey Slider -->
      <div :class="[
        'survey-slider survey-slider__default',
        'survey-slider--' + question.renderingConfig.orientation,
      ]">

        <!-- Select/Value -->
        <div class="survey-slider__select">

          <!-- Value -->
          <div v-if="sliderValue !== undefined" class="survey-slider__select-value">
            <q-icon v-if="isCheckmarkSliderBehavior" color="secondary" name="far fa-check" size="56px" />
            <div v-else v-html="displayValue" />
          </div>

          <!-- Text -->
          <div v-else class="survey-slider__select-text">
            {{ $t('routine_rate_select') }}
          </div>

          <!-- Unit -->
          <div v-if="isVertical && question.renderingConfig.verticalSliderUnitText" class="survey-slider__select-unit">
            {{ question.renderingConfig.verticalSliderUnitText }}
          </div>
        </div>

        <!-- Slider -->
        <div class="survey-slider__slider">

          <!-- Answers ($zb.enums.SliderOrientationEnum.vertical) -->
          <div v-if="isVertical" class="survey-slider__slider-answers">
            <div>{{ question.renderingConfig.tickLabels[2] }}</div>
          </div>

          <!-- Q Slider -->
          <q-slider v-model="sliderValue" :markers="numberOfTickMarks" :marker-labels="tickMarks"
            :max="question.renderingConfig.maxValue * valueScale" :min="question.renderingConfig.minValue * valueScale"
            thumb-size="25px" :tooltip="'none'" track-size="25px" snap :step="valueScale" switch-marker-labels-side
            :reverse="isVertical" :vertical="isVertical" />

          <!-- Answers -->
          <div class="survey-slider__slider-answers">

            <!-- Answers ($zb.enums.SliderOrientationEnum.vertical) -->
            <template v-if="isVertical">
              <div>{{ question.renderingConfig.tickLabels[0] }}</div>
            </template>

            <!-- Answers ($zb.enums.SliderOrientationEnum.horizontal) -->
            <template v-else>
              <div v-for="(label, i) in question.renderingConfig.tickLabels" :key="i">
                {{ label }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "sass:math";

.survey__slider {
  // .row

  >[class*="col"] {

    @media (max-width: 525px) {
      flex: 10000 1 0;
    }

    @media (min-width: 526px) {
      width: 480px;
    }

    @media (min-width: $breakpoint-md-min) {
      width: 500px;
    }
  }
}

.survey-slider {
  // <div>

  // Bottom to Top Specific
  &.survey-slider--btt {
    // <div>
    display: flex;
    align-items: center;
  }

  // Select/Value
  &__select {
    // <div>
    $value-font-size: 56px;

    text-align: center;

    // Bottom to Top Specific
    @at-root .survey-slider--btt & {
      $size: 200px;

      flex-basis: $size;
      width: $size;
      max-width: $size;
    }

    >div {

      // Bottom to Top Specific
      @at-root .survey-slider--btt &:not(:last-child) {
        margin-block-end: 20px;
      }
    }

    // Value
    &-value {
      // <div>
      font-size: $value-font-size;
      line-height: 1;
      color: $secondary;
    }

    // Text
    &-text {
      // <div>
      font-style: italic;
      font-weight: map-get($text-weights, bold);
      font-size: 26px;
      line-height: $value-font-size;
      color: map-get($co-brand-clrs, primitives-foundation-50);
    }

    // Unit
    &-unit {
      // <div>
      font-weight: map-get($text-weights, bold);
      font-size: map-get($font-sizes, '18');
      line-height: map-get($line-heights, '24');
    }
  }

  // Slider
  &__slider {
    // <div>
    line-height: 0;

    // Bottom to Top Specific
    @at-root .survey-slider--btt & {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    // Answers
    &-answers {
      $answers-font-size: map-get($font-sizes, '16');
      $answers-padding-y: 13px;

      >div {
        font-size: $answers-font-size;
        line-height: math.div(20px, $answers-font-size);
        color: map-get($co-brand-clrs, primitives-foundation-50);
        text-align: center;
      }

      // Left to Right Specific
      @at-root .survey-slider--ltr & {
        display: flex;
        justify-content: space-between;
        padding-top: $answers-padding-y;

        >div {
          $answers-width: math.div(100%, 3);
          $answers-padding-x: 10px;

          flex: 0 0 $answers-width;
          max-width: $answers-width;
          padding-inline: 10px;

          &:first-child {
            text-align: start;
            padding-inline-start: 0;
          }

          &:last-child {
            text-align: end;
            padding-inline-end: 0;
          }
        }
      }

      // Bottom to Top Specific
      @at-root .survey-slider--btt & {

        >div {
          $answers-width: 116px;

          flex: 0 0 $answers-width;
          max-width: $answers-width;

          &:first-child {
            padding-block-end: $answers-padding-y;
          }

          &:last-child {
            padding-block-start: $answers-padding-y;
          }
        }
      }
    }
  }

  // Themes

  // Basic
  &__basic {

    // Left to Right Specific
    &.survey-slider--ltr {

      .survey-slider__select {
        margin-bottom: 20px;
      }
    }
  }

  // Default
  &__default {

    // Left to Right Specific
    &.survey-slider--ltr {

      .survey-slider__select {
        margin-block-end: 70px;
      }
    }

    // Bottom to Top Specific
    &.survey-slider--btt {

      .survey-slider__select {
        margin-inline-end: 50px;
      }
    }
  }
}
</style>
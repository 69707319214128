<script setup lang="ts">
import {
  defineProps,
  type PropType
} from 'vue';
import type {SubNavItem} from '@/types/webContracts';
import type {Svg} from '@/types/assets';
import SvgComponent from '@/components/Svg.vue';

const props = defineProps({
  navItem: {
    type: Object as PropType<SubNavItem>
  }
});
</script>

<template>
  <span :class="['q-tab__icon--unique', props.navItem?.customIconClass]">
                  
  <!-- Font Awesome Icon -->
  <q-icon v-if="props.navItem?.icon && typeof props.navItem?.icon === 'string'"
          :name="props.navItem?.icon" />

    <!-- .svg Icon -->
    <SvgComponent v-if="props.navItem?.icon && typeof props.navItem?.icon === 'object'"
                  :vector-paths="(props.navItem?.icon as Svg).vectorPaths"
                  :view-box="(props.navItem?.icon as Svg).viewBox" />
  </span>
  <div class="q-tab__label">{{ props.navItem?.label }}</div>
</template>

<style scoped lang="scss"></style>
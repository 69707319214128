<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="routeToSettings" class="the-change-password">

      <!-- Title -->
      <template v-slot:title>{{ $t('settings') }}</template>

      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ $t('password_change_password_capitalized') }}</template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- Old Password -->
        <div class="row the-change-password__old q-pb-md">
          <div class="col">
            <zbm-input-with-validation v-model="oldPassword" :type="!showOldPassword ? 'password' : 'text'"
              :rules="[$zb.validators.required()]" :placeholder="$t('password_old')"
              :inputGroupModifications="$zb.enums.InputGroupModsEnum.onlyBtmBrdr">
              <template v-slot:append>
                <q-icon @click="showOldPassword = !showOldPassword" class="cursor-pointer"
                  :name="showOldPassword ? eyeClosedIcon : eyeOpenedIcon" size="20px" />
              </template>
            </zbm-input-with-validation>
          </div>
        </div>

        <!-- Change/Confirm Password -->
        <div class="row the-change-password__new q-pb-xl">
          <div class="col">
            <PasswordConfirmWithValidation ref="passwordComponent" v-on:validate="syncPasswordResult"
              v-model="passwordResult" :initialPlaceholder="hasSimplePatientPasscode ? 'forgot_passcode_create_new_code' : 'password_create'
      " :confirmationPlaceholder="hasSimplePatientPasscode ? 'activate_confirm_passcode' : 'password_confirm'
      " :hasPin="hasSimplePatientPasscode" />
          </div>
        </div>

        <!-- Continue Button -->
        <div class="row justify-center">
          <div class="col-auto">

            <zbm-btn @click="onContinueClick" class="button-primary" :color="$zb.enums.ButtonStdColorEnum.accent"
              :height="$zb.enums.ButtonHeightEnum.md">{{
      $t('activate_continue_text') }}
            </zbm-btn>

            <q-dialog ref="passwordChangeModal" v-model="modalShow">
              <q-card>
                <q-card-section>
                  <div class="row justify-center">
                    <div class="col-auto">
                      <!-- Exclamation Circle Icon -->
                      <q-icon color="dark-teal" name="fas fa-exclamation-circle" size="42px" />
                    </div>
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="row justify-center">
                    <div class="col text-center">
                      <h5 class="modal-body__heading">{{ $t('success') }}</h5>
                      <p>
                        {{ $t('password_changed') }}
                      </p>
                    </div>
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="row justify-center">
                    <div class="col-auto">
                      <zbm-btn @click="routeToSettings" :color="$zb.enums.ButtonStdColorEnum.accentText"
                        :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-dialog>
          </div>
        </div>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import PasswordConfirmWithValidation from 'src/components/form/PasswordConfirmWithValidation.vue';
import { useSessionStore } from 'src/store';
import { ClientFeatureType } from 'src/constants/enums';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { ChangePassword } from 'src/api/endpoints/userWeb';
import { makeEmptyPasswordConfirmWithValidationResult, type PasswordConfirmWithValidationResult } from 'src/utils/componentFactories';
import { useQuasar } from 'quasar';

onMounted(() => {
  showOldPassword.value = false;
  oldPassword.value = '';
  passwordResult.value = makeEmptyPasswordConfirmWithValidationResult();
});

const $q = useQuasar()
const router = useRouter();
const sessionStore = useSessionStore();
const showOldPassword = ref(false);
const passwordResult = ref(makeEmptyPasswordConfirmWithValidationResult());
const oldPassword = ref('');
const modalShow = ref(false);

const eyeClosedIcon = 'far fa-eye-slash';
const eyeOpenedIcon = 'far fa-eye';

const email = computed(() => sessionStore.user?.email || '');
const hasSimplePatientPasscode = computed(() => sessionStore.user?.clientFeatures.includes(ClientFeatureType.SimplePatientPasscode));

//Returns LoginRequest Type
const loginModel = computed(() => ({
  password: oldPassword.value,
  username: sessionStore.user?.username || '',
  countryCode: sessionStore.user?.clientCountryCode || '',
  utcOffset: sessionStore.utcOffset,
}));

async function onContinueClick() {
  if (passwordResult.value.isValid) {
    const response = await handleApiRequest(() =>
      ChangePassword({
        newPassword: passwordResult.value.password,
        email: email.value,
        loginModel: loginModel.value,
      }), null, $q
    );
    if (response) {
      modalShow.value = true;
    }
  }
}

function routeToSettings() {
  router.push({ name: 'Settings' });
}

function syncPasswordResult(result: PasswordConfirmWithValidationResult) {
  passwordResult.value.password = result.password;
  passwordResult.value.confirmPassword = result.confirmPassword;
  passwordResult.value.isValid = result.isValid;
}
</script>
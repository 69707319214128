import { type EpisodeOfCareInfo, type UserSessionData } from 'src/types/webContracts'
import { type NamedPerson } from 'src/types/interfaces';
import { Ref, computed, ref } from 'vue';
import { defineStore } from 'pinia';


export const useSessionStore = defineStore('session', () => {

  const user: Ref<UserSessionData | null> = ref(null);
  const isAuthenticated = ref(false);
  const isHeaderVisible = ref(false);
  const isFooterVisible = ref(true);
  const hasCookie = ref(false);
  const completedLogin = ref(false);
  const utcOffset = ref(Math.floor(new Date().getTimezoneOffset() / -60));
  const procedures: Ref<EpisodeOfCareInfo[] | null> = ref(null);
  const cobrandedImages: Ref<Record<string, string> | null> = ref(null)
  const cobrandedCssRules: Ref<string | null> = ref(null)
  const userId = computed(() => user.value?.userId)

  const setUserFields = (userData: UserSessionData | null) => {
    user.value = userData ? { ...userData } : null;
  }

  const setCobranding = (images: Record<string, string> | null, colorRules: string | null) => {
    cobrandedImages.value = images ? { ...images } : null
    cobrandedCssRules.value = colorRules
  }

  const setCompletedLogin = (completedLoginValue: boolean) => {
    completedLogin.value = completedLoginValue;
  }

  const setIsAuthenticated = (flag: boolean) => {
    isAuthenticated.value = flag;
  }

  const setProcedures = (proceduresValue: EpisodeOfCareInfo[] | null) => {
    procedures.value = proceduresValue;
  }

  const setSelectedEocId = (selectedEocId: number) => {
    if (user.value) {
      user.value.selectedEocId = selectedEocId;
    }
  }

  const load = (userData: UserSessionData) => {
    setUserFields(userData);
    setIsAuthenticated(true);
  }

  const loadProcedures = (proceduresValue: EpisodeOfCareInfo[]) => {
    setProcedures(proceduresValue);
  }

  const clear = () => {
    setIsAuthenticated(false);
    setUserFields(null);
    setIsHeaderVisible(false);
  }

  const setIsHeaderVisible = (isVisible: boolean) => {
    isHeaderVisible.value = isVisible;
  }

  const setIsFooterVisible = (isVisible: boolean) => {
    isFooterVisible.value = isVisible;
  }

  function namedPerson(): NamedPerson {
    if (!user.value) return {};
    return {
      firstName: user.value.firstName,
      lastName: user.value.lastName,
    } as NamedPerson
  }

  const CobrandedImage = computed(() => (key: string) => cobrandedImages.value ? cobrandedImages.value[key] : null);
  const isCobrandingEnabled = computed(() => !!(cobrandedImages.value || cobrandedCssRules.value))

  return {
    user,
    userId,
    isAuthenticated,
    isHeaderVisible,
    isFooterVisible,
    hasCookie,
    completedLogin,
    utcOffset,
    procedures,
    setUserFields,
    setCompletedLogin,
    setIsAuthenticated,
    setProcedures,
    setSelectedEocId,
    load,
    loadProcedures,
    clear,
    setIsHeaderVisible,
    setIsFooterVisible,
    namedPerson,
    cobrandedImages,
    cobrandedCssRules,
    setCobranding,
    CobrandedImage,
    isCobrandingEnabled
  }
});
<script lang="ts" setup>
import BoxContainer from 'src/components/BoxContainer.vue';
</script>

<template>
  <div class="container main main--no-header dark-background the-sys-maint">
    <div class="row justify-center">
      <BoxContainer class="col-auto text-center" :boxBkgdColor="$zb.enums.BoxBackgroundColorEnum.linearGradient"
        :boxBorderRadius="$zb.enums.BoxBorderRadiusEnum.lg" :boxDropShadow="$zb.enums.BoxDropShadowEnum.xl"
        :boxPadding="$zb.enums.BoxPaddingEnum.p505065" :boxWidth="$zb.enums.BoxWidthEnum.xs">
        <span></span>
        <p>{{ $t('error_outage_try_later') }}</p>
      </BoxContainer>
    </div>
  </div>
</template>
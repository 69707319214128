<template>
  <LegalAndPrivacyLayout headingLocalizerKey="terms_of_use_header" :policyType="policyType" scrollable
    :showTitle="false">
    <template v-slot:actions>
      <div class="row">
        <div class="col">
          <!-- Checkbox -->
          <zbm-checkbox v-model="checked" id="userPolicyCheck" name="userPolicyCheck">
            {{ $t('canary_terms_of_use') }}
          </zbm-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
        :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
        <ProgressBar :progress="20" :nextEnabled="true" @on-back-click="onBackClick" @on-next-click="onNextClick" />
      </Box>
    </template>
  </LegalAndPrivacyLayout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import { handleApiRequest } from 'src/api/handleApiRequest';

import Box from 'src/components/BoxContainer.vue';
import LegalAndPrivacyLayout from 'src/components/layouts/LegalAndPrivacyLayout.vue';
import DisplayHtmlPolicy from 'src/components/DisplayHtmlPolicy.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';

import { getNextActivationRoute } from 'src/services/piqSetupService';
import { UserPolicyType } from 'src/constants/enums';
import { updatePolicy } from 'src/api/endpoints/registrationWeb';
import { i18n } from 'src/plugins/i18n'

import { useGeneralPurposeModalStore } from 'src/store';
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const router = useRouter();

const checked = ref(false);
const $i18nt = i18n.global.t;
const policyType = ref<UserPolicyType>(UserPolicyType.CanaryPiqTermsOfUse);

onMounted(async () => {
  checked.value = false;
})

// methods
async function onNextClick() {
  if (checked.value) {
    $q.loading.show();

    const response = await handleApiRequest(() =>
      updatePolicy(policyType.value, false, true), null, $q
    ).finally(() => {
      $q.loading.hide();
    });

    if (!response?.result) {
        // error
    } else {
        var nextRoute = await getNextActivationRoute($q);
        router.push({ name: nextRoute }).catch(() => { });
    }
  } else {
    $q.loading.hide();
    const info = createDecisionModal('', $i18nt('piq_t_c_error'));
    generalPurposeModalStore.setModal(info);
  }
}

async function onBackClick() {
  router.go(-1);
}

</script>
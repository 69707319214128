<template>
  <q-page padding>
    <AuthenticateLayout withHeader>
      <template v-slot:title>{{ $t('activate_account') }}</template>
      <template v-slot:heading>{{ $t('activate_username_create_new') }}</template>

      <template v-slot:default>
        <UsernameConfirmWithValidation ref="usernameComponent" v-model="usernameResult" :initialLabel="''"
          :initialPlaceholder="'activate_username_create'" :confirmationLabel="''"
          :confirmationPlaceholder="'activate_username_confirm'" @validate="validateResult" />
      </template>

      <template v-slot:actions>
        <ProgressBar :backEnabled="false" :progress="78" :nextEnabled.sync="formIsValid" @on-next-click="onNextClick" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { createUserName, getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import { useActivationStore } from 'src/store';
import UsernameConfirmWithValidation from 'src/components/form/UsernameConfirmWithValidation.vue';
import { type UsernameConfirmWithValidationResult, makeEmptyUsernameConfirmWithValidationResult } from 'src/utils/componentFactories';
import { useQuasar } from 'quasar';

const $q = useQuasar();
const router = useRouter();
const activationStore = useActivationStore()
const usernameComponent = ref(null);
const usernameResult = ref(makeEmptyUsernameConfirmWithValidationResult());
const formIsValid = computed(() => usernameResult.value.isValid);

onMounted(() => {
  (document.querySelectorAll('input:first-child')[0] as HTMLElement).focus();
});

async function onNextClick() {
  if (!usernameResult.value.isValid) return;

  $q.loading.show()
  const userNameResponse = await handleApiRequest(() =>
    createUserName(usernameResult.value.username), null, $q
  );

  if (userNameResponse?.result) {
    activationStore.setRegistrationUsername(usernameResult.value.username);

    const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
    const nextStep = translateStepToRoute(step?.result as string);

    $q.loading.hide()
    router.push({
      name: nextStep,
    });
  } else {
    $q.loading.hide()
  }
}

async function validateResult(result: UsernameConfirmWithValidationResult) {
  usernameResult.value.isValid = result.isValid;
  usernameResult.value.username = result.username;
  usernameResult.value.confirmUsername = result.confirmUsername;
}
</script>
<template>
  <div class="row form-row">
    <div class="col" id="username-input">
      <!-- Username -->
      <zbm-input-with-validation v-model="username" v-on:keyup.enter="onSubmit" type="text"
        @update:modelValue="validate" :name="$t(initialLabel)" ref="usernameInput" :placeholder="$t(initialPlaceholder)"
        class="usernameInput" :class="usernamRulesValid ? '' : 'q-field--error'">
      </zbm-input-with-validation>
      <ValidationList v-model="usernameRules" />
    </div>
    <div class="col">
      <!-- Confirmation Username -->
      <zbm-input-with-validation v-model="confirmUsername" v-on:keyup.enter="onSubmit" type="text"
        @update:modelValue="validate" ref="confirmUsernameInput"
        :customMessages="{ required: $t('activate_username_confirm') }" :name="$t(confirmationLabel)"
        :placeholder="$t(confirmationPlaceholder)" class="usernameInput"
        :class="confirmUsernameRulesValid ? '' : 'q-field--error'">
      </zbm-input-with-validation>
      <ValidationList v-model="confirmUsernameRules" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#username-input {
  padding-bottom: 30px;
}
</style>

<script lang="ts" setup>
import { ref, type PropType, defineEmits, nextTick } from 'vue';
import { type UsernameConfirmWithValidationResult } from 'src/utils/componentFactories';
import { i18n } from 'src/plugins/i18n';
import type { ValidationRule } from 'quasar';
import ValidationList, { type ValidationListRule } from 'src/components/form/ValidationList.vue';
import * as Validators from '@/plugins/quasar-validators'

export interface UsernameConfirmWithValidationComponent {
  reset(): void;
}

const emit = defineEmits(['update:modelValue', 'validUsername', 'validate']);
const username = ref('');
const confirmUsername = ref('');
const usernameInput = ref(null);
const confirmUsernameInput = ref(null);
const $i18nt = i18n.global.t;

const usernamRulesValid = ref(true);
const confirmUsernameRulesValid = ref(true);

const props = defineProps({
  initialLabel: {
    type: String,
    default: '',
  },
  initialPlaceholder: {
    type: String,
    default: 'activate_username_create',
  },
  confirmationLabel: {
    type: String,
    default: '',
  },
  confirmationPlaceholder: {
    type: String,
    default: 'activate_username_confirm',
  },
  syncValue: {
    type: Object as PropType<UsernameConfirmWithValidationResult>,
    default: () => ({}),
  },
});

const usernameRules = ref([
  { rule: Validators.required(), displayText: $i18nt('activate_username_empty') },
  { rule: Validators.minLen(8), displayText: $i18nt('activate_reqs_min_chars') },
  { rule: Validators.noSpaces(), displayText: $i18nt('activate_reqs_no_spaces') },
  { rule: Validators.alphaNum(), displayText: $i18nt('activate_reqs_no_spec_chars') },
] as ValidationListRule[]);
const confirmUsernameRules = ref([
  { rule: Validators.required(), displayText: $i18nt('activate_username_confirm') },
  { rule: (Validators.equals()), displayText: $i18nt('activate_username_not_match') },
] as ValidationListRule[]);

function validateRule(rule: ValidationRule, value: string) {
  if (rule.toString() == Validators.equals().toString()) {
    return Validators.equals(username.value)(value) === true;
  }
  return rule(value) === true;
}

function onSubmit() {
  const isValid = validateUsernameInputs();
  if (isValid) {
    emit('validUsername', props.syncValue);
  }
}

function validate() {
  nextTick(() => {
    const isValid = validateUsernameInputs();

    emit('validate', {
      username: username.value,
      confirmUsername: confirmUsername.value,
      isValid: isValid
    } as UsernameConfirmWithValidationResult);
  });
}

function validateUsernameInputs() {
  usernameRules.value.forEach((rule) => {
    rule.isValid = validateRule(rule.rule, username.value);
  });
  confirmUsernameRules.value.forEach((rule) => {
    rule.isValid = validateRule(rule.rule, confirmUsername.value);
  });

  usernamRulesValid.value = usernameRules.value.every((rule) => rule.isValid);
  confirmUsernameRulesValid.value = confirmUsernameRules.value.every((rule) => rule.isValid);

  return usernamRulesValid && confirmUsernameRulesValid;
}
</script>
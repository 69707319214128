<template>
  <div class="pt-35 mb-50">
    <slot>
      <zbm-btn @click="emit('onNextClick')"
               :color="$zb.enums.ButtonStdColorEnum.accent"
               :height="$zb.enums.ButtonHeightEnum.lg"
               :label="nextButtonDisplayText"
               :width="$zb.enums.ButtonWidthEnum.w270" />
    </slot>
  </div>
  <div>
    <zbm-btn v-for="answer in question.permittedAnswers"
             @click="onButtonClick(answer.answerId)"
             :color="$zb.enums.ButtonStdColorEnum.accentText"
             :height="$zb.enums.ButtonHeightEnum.lg"
             :key="answer.answerId"
             :label="answer.name"
             :width="$zb.enums.ButtonWidthEnum.w270" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import type { SurveyQuestionModel, SurveyPermittedAnswerModel, SurveyProgressAnswer } from 'src/types/model';
import { i18n } from 'src/plugins/i18n';

// Define props
const props = defineProps({
  question: {
    type: Object as () => SurveyQuestionModel,
    required: true,
  },
  nextButtonText: {
    type: String,
    required: false,
  },
  modelValue: {
    type: Object as () => Record<string, unknown>,
    required: true,
  },
});

// Define emits
const emit = defineEmits(['update:modelValue', 'onNextClick', 'onDeclineClick']);

// Reactive data
const selectedValue = ref(props.modelValue);
const key = computed(() => `${props.question.questionId}-button`);
const nextButtonDisplayText = computed(() => props.nextButtonText ?? i18n.global.t('activate_continue_text'));

// Methods
const setValue = (newVal: number | null) => {
  if (typeof selectedValue.value[key.value] === 'undefined') {
    selectedValue.value[key.value] = newVal;
  } else {
    selectedValue.value[key.value] = newVal;
  }
};

const onButtonClick = (newVal: number | null) => {
  setValue(newVal);
  if (props.question.permittedAnswers.find(a => a.answerId == newVal)?.renderingConfig.isDeclining) {
    emit('onDeclineClick');
  }
};

const validate = async () => {
  return true;
};

const getResponse = (): SurveyProgressAnswer => {
  const value = selectedValue.value[key.value];
  const permittedAnswer = value ? props.question.permittedAnswers.find(a => a.answerId == value) as SurveyPermittedAnswerModel : props.question.permittedAnswers[0];
  return {
    question: props.question,
    permittedAnswer,
    value: value ? value : undefined,
  };
};

// Lifecycle hook
onMounted(() => {
  setValue(null);
});

defineExpose({
  getResponse,
  validate
});
</script>
export enum MeasurementTypeEnum {
  PiqMaxSteps = 'MaxSteps',
  PiqCadence = 'AvgCadence',
  PiqAvgStrideLength = 'AvgStrideLength',
  PiqAvgWalkSpeed = 'AvgWalkSpeed',
  PiqAvgTibiaROM = 'AvgTibiaROM',
  PiqAvgKneeROM = 'AvgKneeROM',
  PiqAvgDistance = 'AvgDistance'
}

export const DayOfWeekLocalizerMap: { [day: number]: string } = {
  0: 'day_of_week_sun',
  1: 'day_of_week_mon',
  2: 'day_of_week_tue',
  3: 'day_of_week_wed',
  4: 'day_of_week_thur',
  5: 'day_of_week_fri',
  6: 'day_of_week_sat',
}

export enum PeriodTypEnum {
  LastUpdate = 'last-update',
  OneWeekAvg = '1-week-avg',
  OneMonthAvg = '1-month-avg'
}

export enum StatTypeEnum {
  PersonaIQ = 'PersonaIQ'
}
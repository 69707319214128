<template>
  <q-page padding>
    <MultiViewLayout :show-footer="$slots.footer != null">
      <!-- Title -->
      <template v-if="showTitle" v-slot:title>{{ $t('settings') }}</template>
      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ $t(headingLocalizerKey) }}</template>
      <!-- Legal Doc -->
      <template v-slot:default>
        <div class="row document">
          <div class="col">
            <DisplayHtmlPolicy :policyType="policyType" :scrollable="scrollable" />
          </div>
        </div>
        <slot name="actions" />
      </template>
      <template v-slot:footer>
        <slot name="footer" />
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { defineProps, type PropType } from 'vue';
import { useRouter } from 'vue-router';
import { UserPolicyType } from 'src/constants/enums';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import DisplayHtmlPolicy from 'src/components/DisplayHtmlPolicy.vue';

const router = useRouter();

const props = defineProps({
  headingLocalizerKey: {
    type: String,
    required: true,
  },
  policyType: {
    type: Number as PropType<UserPolicyType>,
    required: true,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
});

</script>

<style lang="scss" scoped>
@use "sass:math";

// Policy
.document {
  // .row

  >[class*="col"] {
    line-height: math.div(map-get($line-heights, '28'), map-get($font-sizes, '16'));

    // Legal
    :deep(.legal) {

      a {
        word-break: break-word;
      }

      table {
        width: 100%;
        max-width: 100%;
        background-color: $white;
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-block: -10px 19px;

        p,
        ul,
        ol {
          margin-block-end: 0;
        }

        ul {
          padding-inline-start: 16px;
        }

        th,
        td {
          font-size: map-get($font-sizes, '14');
          line-height: $line-height-lg;
          white-space: normal;
          word-break: break-word;
          vertical-align: top;
          padding-block: 4px;
          padding-inline: 15px;
          border: 1px solid $border-clr;
        }

        thead {
          display: none;

          th {
            font-weight: map-get($text-weights, bold);
            text-align: center;
          }
        }

        tbody td {
          display: block;

          &::before {
            font-style: italic;
            font-weight: map-get($text-weights, bold);
            content: attr(data-label);
            margin-inline-end: 8px;
          }
        }

        @media (max-width: $breakpoint-sm-max) {

          tbody td:not(:last-child) {
            border-block-end: none;
          }
        }

        @media (min-width: $breakpoint-md-min) {
          border-collapse: collapse;
          border-spacing: 1px 0;
          margin-block-start: 0;

          th,
          td {
            font-size: map-get($font-sizes, '12');
          }

          thead {
            display: table-header-group;

            th {
              width: calc(100%/6);
            }
          }

          tbody td {
            display: table-cell;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
import { logout } from 'src/api/endpoints/loginWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';

import {
  useActivationStore,
  useDashboardStore,
  useGeneralPurposeModalStore,
  useEducationLibraryStore,
  useMessagesStore,
  useSessionStore,
  useSurveyStore
} from 'src/store';

export async function userLogout($q) {
  $q.loading.show();
  // hit the server to logout
  await handleApiRequest(() => logout(), null, $q)
    .catch((error) => {
      console.error("Error", error)
    })
    .finally(() => $q.loading.hide());

  // logout logics  
  useActivationStore().clear();
  useSessionStore().clear();
  useDashboardStore().clear();
  useGeneralPurposeModalStore().setModalMutation(null);
  useEducationLibraryStore().clear();
  useMessagesStore().clear();
  useSurveyStore().clear();
}

export function resetProcedureRelatedState() {
  useActivationStore().clear();
  useDashboardStore().clear();
  useGeneralPurposeModalStore().setModalMutation(null);
  useEducationLibraryStore().clear();
  useMessagesStore().clear();
  useSurveyStore().clear();
}

<template>
  <q-page padding>
    <MultiViewLayout>

      <!-- Title -->
      <template v-if="authenticated" v-slot:title>{{ $t('settings') }}</template>

      <!-- Page Title -->
      <template v-slot:heading>{{ $t('settings_about') }}</template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- About Content -->
        <div class="row">
          <div class="col">
            <pre v-html="localizedPolicy" />

            <!-- CE Mark -->
            <p class="text-center"><img src="/img/ce-mark.png" /></p>

            <!-- Labeling Button -->
            <p class="text-center">
              <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.lg"
                href="https://support.zbmymobilitysolutions.com/labeling" :label="$t('gen_string_ce_mark_labeling')"
                target="_blank" />
            </p>

            <!-- IFU Button -->
            <p class="text-center">
              <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.lg"
                :href="instructionsForUseUrl" :label="$t('gen_string_ce_mark_ifu')" target="_blank" />
            </p>
          </div>
        </div>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import { useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLocalizedPolicy } from 'src/api/endpoints/registrationWeb';
import { getInstructionsForUseUrl } from '@/api/endpoints/settingsWeb';
import { UserPolicyType } from 'src/constants/enums';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const localizedPolicy = ref('');
const sessionStore = useSessionStore();
const instructionsForUseUrl = ref('');

const authenticated = computed(() => sessionStore.isAuthenticated);

onMounted(async () => {
  const policyResponse = await handleApiRequest(() =>
    getLocalizedPolicy(UserPolicyType.UserPolicyAbout), null, $q
  );

  if (policyResponse?.result) {
    localizedPolicy.value = policyResponse.result.description;
  }

  const instructionsForUseUrlResponse = await handleApiRequest(() =>
    getInstructionsForUseUrl(), null, $q
  );

  if (instructionsForUseUrlResponse?.result) {
    instructionsForUseUrl.value = instructionsForUseUrlResponse.result;
  }
});
</script>
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">
      <!-- Title -->
      <template v-slot:title>{{ header }}</template>
      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ title }}</template>
      <!-- View Unique Content -->
      <template v-slot:default>
        <!-- Education Content -->
        <div class="row">
          <div class="col">
            <SkeletonLoader :loading="isLoading" :repeatCount="1" type="text" width="540px" height="80px"
              skeletonStyle="margin-left: 2em; margin-bottom: 1em">
              <video width="100%" controls autoplay="true">
                <source :src="url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SkeletonLoader>
          </div>
        </div>
      </template>
  </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { Ref, ref, computed, onMounted, onDeactivated } from 'vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLibraryRosaItems } from 'src/api/endpoints/calendarWeb';
import { useEducationLibraryStore } from 'src/store/educationLibraryModule';
import { RosaLibraryItem } from 'src/types/webContracts';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const educationLibraryStore = useEducationLibraryStore()
const router = useRouter()

const props = defineProps({
  itemId: { type: Number },
  sectionHeader: { type: String, default: 'gen_education' }

})

const title = ref('')
const url = ref('')
const item: Ref<RosaLibraryItem> = ref({})
const isLoading = ref(false)


const header = computed((): any => {
  return i18n.global.t(props.sectionHeader);
})


const fetchItemProps = () => {
  item.value = educationLibraryStore.rosaItems.find((i, idx, set) => {
    if (i.id == props.itemId) {
      return set[idx];
    }
  }) || {
    id: 0,
    name: '',
    url: '',
    content: '',
  };
  title.value = item.value.name;
  url.value = item.value.url;
}

const onBackClick = () => {
  router.go(-1);
}

onMounted(async () => {
  if (!educationLibraryStore.rosaItems.length) {
    isLoading.value = true;
    const response = await handleApiRequest(() => getLibraryRosaItems(), null, $q);
    if (response != undefined) {
      educationLibraryStore.setRosaItems(response.result);
      fetchItemProps();
    }
  } else {
    fetchItemProps();
  }

  isLoading.value = false;
})

onDeactivated(() => {
  title.value = ''
  url.value = ''
  item.value = {} as RosaLibraryItem
  isLoading.value = ref(false)
});
</script>
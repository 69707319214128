<template>
  <zbm-async-content :loading="loading">
    <template v-slot:loading>
      <div class="row">
        <div class="col-auto pr-16">
          <q-skeleton type="rect" width="4px" height="100%" />
        </div>
        <div class="col">
          <template v-for="i in 3" :key="i">
            <div class="row">
              <div :class="`co-12 pb-16 pt-${i > 1 ? '16' : ''}`">
                <q-skeleton type="rect" width="100px" height="20px" />
              </div>
              <div class="col-12">
                <q-skeleton type="rect" width="100%" height="120px" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- Education Library Surgery Navigation/Items -->
    <div class="nav the-dashboard-education__cat nav-education-library">
      <div v-if="rawLibraryItems.length">
        <q-timeline color="secondary">
          <!-- Educational Items -->
          <template v-for="({ subtitle, entries }, i) in libraryTimeline" :key="i">
            <q-timeline-entry :subtitle="subtitle">
              <template v-slot:title>
                <template v-for="item in entries" :key="item.id">
                  <router-link
                    :to="{ name: item.path, params: { itemId: item.id } }">
                    <div class="row items-center">
                      <div class="col">
                        {{ item.title }}
                      </div>
                      <div class="col-auto">
                        <q-icon size="34px" name="far fa-long-arrow-right" />
                      </div>
                    </div>
                  </router-link>
                </template>
              </template>
            </q-timeline-entry>
          </template>
        </q-timeline>
      </div>
      <div v-else>
        <div v-if="!hasProtocolAssigned">
          <h1>{{ $t('home_care_pathway_not_assigned') }}</h1>
          {{ $t('home_no_education') }}
        </div>
        <h2 v-else>
          {{ emptyText }}
        </h2>
      </div>
    </div>
  </zbm-async-content>
</template>

<style lang="scss">
.q-timeline {
  &__entry {

    .q-timeline {
      &__subtitle {
        margin: 0;
      }

      &__title>a {
        display: block;
        padding: 14px 0;
        border-bottom: 1px solid lightgray;
      }

      &__content {
        padding-bottom: 14px;
      }
    }

    &:last-child {
      >.q-timeline__dot:after {
        content: '' !important;
      }

      >.q-timeline__content {
        padding-bottom: 0;
      }
    }
  }
}
</style>

<script setup lang="ts">

import { i18n } from '@/plugins/i18n';
import { useDashboardStore } from '@/store';
import { type EducationLibraryItem } from 'src/types/webContracts';
import { type PropType, computed } from 'vue';

export interface DisplayLibraryItem {
  show: boolean;
  day: number;
  title: string;
  path: string;
  id: number;
}

const dashboardStore = useDashboardStore()

const props = defineProps({
  timelineTitle: {
    type: String,
    required: true,
  },
  rawLibraryItems: {
    type: Array as PropType<Array<EducationLibraryItem>>,
    required: true,
  },
  surgicalState: {
    type: String,
    required: true,
  },
  loading: Boolean,
  emptyText: String
});


const protocol = computed(() => dashboardStore.patient?.protocolId);
const hasProtocolAssigned = computed(() => protocol.value !== undefined && protocol.value !== null);

const libraryTimeline = computed(() => {
  const timelineGroupByDay = {};

  props.rawLibraryItems.forEach(({ eventId, day, name }) => {
    const dayGroup = (timelineGroupByDay[day] ||= { subtitle: '', entries: [] })

    if (!dayGroup.subtitle) {
      if (day === 0) {
        dayGroup.subtitle = i18n.global.t('day_of_surgery')
      } else {
        let subtitleKey = day < 0
          ? (day === -1 ? 'days_before_surgery_singular' : 'days_before_surgery_plural')
          : (day === 1 ? 'days_after_surgery_singular' : 'days_after_surgery_plural')

        dayGroup.subtitle = i18n.global.t(subtitleKey, [Math.abs(day)])
      }
    }

    dayGroup.entries.push({
      title: name,
      path: 'Education.' + props.surgicalState,
      id: eventId
    });
  });

  const array = Object.keys(timelineGroupByDay).map((k) => timelineGroupByDay[k])
  return array
});
</script>
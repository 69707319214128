import { createApp } from 'vue';

import App from './App.vue';
import { i18n } from '@/plugins/i18n';
import { createPinia } from 'pinia';
import { registerGuards } from './router/guards';

import { registerCustomComponents } from './component-registration';
import { registerQuasar } from './plugins/quasar';
import { registerVueTelInput } from './plugins/vue-tel-input';
import { registerVCalendar } from './plugins/v-calendar';
import * as Enums from '@/constants/enums';
import * as Validators from '@/plugins/quasar-validators'
import axios from 'src/api/axiosConfig'
import { ApiRoutes } from 'src/api/ApiRoutes'
import VueMatomo from 'vue-matomo';

const app = createApp(App);
axios.get(ApiRoutes.Settings.PreBootSettings).then(preBootOpts => {
  const router = registerGuards(app)

  if (preBootOpts.data.siteId > 0) {
    app.use(VueMatomo, {
      host: preBootOpts.data.url,
      siteId: preBootOpts.data.siteId,
      trackerFileName: 'matomo',
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      enableHeartBeatTimer: false,
      heartBeatTimerInterval: 15,
      debug: false,
      userId: undefined,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: []
    })
  }

  registerQuasar(app)
  registerCustomComponents(app)
  registerVueTelInput(app)
  registerVCalendar(app)

  function useGlobalEnums(app: any) {
    //Make console available in template bindings for debugging purposes
    app.config.globalProperties.productionTip = false;
    app.config.globalProperties.console = window.console;
    app.config.globalProperties.sysMaint = preBootOpts.data.sysMaint;
    app.config.globalProperties.$zb = {
      enums: Enums,
      validators: Validators
    };
  }

  useGlobalEnums(app);

  app.use(i18n)
  app.use(createPinia())
  app.mount('#app');
})
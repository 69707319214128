<template>
  <q-page padding>
    <AuthenticateLayout withHeader>
      <template v-slot:title>{{ $t('activate_account') }}</template>
      <template v-slot:heading>{{ heading }}</template>

      <template v-slot:default>
        <PasswordConfirmWithValidation auto-focus ref="passwordComponent" v-model="passwordResult"
          @validate="validateResult"
          :initialPlaceholder="hasSimplePatientPasscode ? 'activate_enter_passcode' : 'password_create'"
          :confirmationPlaceholder="hasSimplePatientPasscode ? 'activate_confirm_passcode' : 'password_confirm'
        " :hasPin="hasSimplePatientPasscode" />
      </template>

      <template v-slot:actions>
        <ProgressBar :backEnabled="false" :progress="91" :nextEnabled="passwordResult.isValid"
          @on-next-click="onNextClick" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { makeEmptyPasswordConfirmWithValidationResult, type PasswordConfirmWithValidationResult } from 'src/utils/componentFactories';
import { useActivationStore } from 'src/store';
import { useSessionStore } from '@/store/sessionStore';
import { ClientFeatureType } from 'src/constants/enums';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { createPassword, getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import PasswordConfirmWithValidation from 'src/components/form/PasswordConfirmWithValidation.vue';
import { i18n } from '@/plugins/i18n';
import { useQuasar } from 'quasar';

const router = useRouter();
const previousRoute = ref('');
const sessionStore = useSessionStore();
const activationStore = useActivationStore()
const $q = useQuasar()

const passwordComponent = ref(null);
const passwordResult = ref(makeEmptyPasswordConfirmWithValidationResult());

const hasSimplePatientPasscode = computed(() => sessionStore.user?.clientFeatures?.includes(ClientFeatureType.SimplePatientPasscode) ?? false);

const heading = computed(() => {
  if (hasSimplePatientPasscode.value) {
    return i18n.global.t('activate_create_passcode').toString();
  } else {
    return i18n.global.t('password_new_create').toString();
  }
});

onMounted(() => {
  sessionStore.setIsHeaderVisible(true);
});

async function onNextClick() {
  if (passwordResult.value.isValid) {
    $q.loading.show()
    const password = passwordResult.value.password;
    const response = await handleApiRequest(() => createPassword(password), null, $q);
    if (response?.result) {
      activationStore.setRegistrationPassword(passwordResult.value.password);

      const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
      const nextStep = translateStepToRoute(step?.result as string);
      $q.loading.hide()
      router.push({ name: nextStep });
    } else {
      $q.loading.hide()
    }
  }
}

async function validateResult(result: PasswordConfirmWithValidationResult) {
  passwordResult.value.password = result.password;
  passwordResult.value.confirmPassword = result.confirmPassword;
  passwordResult.value.isValid = result.isValid;
}

</script>
<script lang="ts" setup>
import {
  defineExpose,
  defineModel,
  defineProps,
  type PropType,
  ref
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import { type ValidationRule } from 'quasar';

const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off',
  },
  autogrow: {
    type: Boolean,
    default: false
  },
  class: {
    type: String
  },
  dark: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormElFull - sets width to `100%`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl125 - sets width to `125px`.
   * qFormEl300 - sets width to `300px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl300
  },
  label: {
    type: String
  },
  name: {
    type: String
  },
  outlined: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Number,
    default: 3
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  type: {
    type: String as PropType<'text' | 'password' | 'textarea' | 'email' | 'search' | 'tel' | 'file' | 'number' | 'url'
      | 'time' | 'date' | 'datetime-local'>
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  lazyRules: {
    type: String as PropType<boolean | 'ondemand'>,
    default: false
  },
  mask: {
    type: String,
  }
})

const inputRef = ref(null);
const syncValue = defineModel<string | number | null | undefined>();

defineExpose({
  validate: () => inputRef.value.validate(),
  resetValidation: () => inputRef.value.resetValidation()
});
</script>

<template>

  <!-- Label -->
  <label v-if="props.label" :for="props.for">
    {{ props.label }}
    <slot name="label-append" />
  </label>

  <!-- Field -->
  <q-input v-model="syncValue" :autocomplete="props.autocomplete" :autogrow="props.autogrow"
    :class="[props.inputWidth, props.class]" :dark="props.dark" :disable="props.disable" hide-bottom-space
    :name="props.name" no-error-icon :outlined="props.outlined" :placeholder="props.placeholder"
    :readonly="props.readonly" ref="inputRef" :rows="props.type === 'textarea' ? props.rows : null" :rules="props.rules"
    :type="props.type" :autofocus="props.autofocus" :lazyRules="props.lazyRules" :mask="mask">
    <template v-slot:append>
      <slot name="append" />
    </template>
  </q-input>
</template>
import { debounce } from 'quasar';
import { bus } from '@/utils/eventBus';

const config = {
  inactiveTimeout: (25 * 60 * 1000),  // 25 mins
  redirectPage: 'Welcome'
};

async function invokeLogout() {
  bus.emit('user-log-out', config.redirectPage);
}

const idleLogoutTimer = debounce(invokeLogout, config.inactiveTimeout);

export function setupInactivityRedirect() {
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.addEventListener(event, idleLogoutTimer);
  });
}

function teardownInactivityRedirect() {
  idleLogoutTimer.cancel();
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.removeEventListener(event, idleLogoutTimer);
  });
}
import { type CountriesListResult } from 'src/types/webContracts'
import { getListofCountries } from 'src/api/endpoints/settingsWeb'


import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'

type Phone = {
  phoneNumber: string;
  phoneCountryCode: string;
  formattedPhoneNumber: string;
  countryCode: string;
  dateOfBirth: string;
}

export const useActivationStore = defineStore('activation', () => {
  const supportedCountries: Ref<CountriesListResult> = ref({
    selectedCountryCode: '',
    countryOptions: []
  })

  const isActivatingAccount = ref(false)
  const isRecoveringUsername = ref(false)
  const isRecoveringPassword = ref(false)
  const passwordWasReset = ref(false)
  const registrationUsername = ref('')
  const registrationPassword = ref('')

  const activateStart: Ref<Phone> = ref({
    phoneNumber: '',
    phoneCountryCode: '',
    formattedPhoneNumber: '',
    countryCode: '',
    dateOfBirth: '',
  })

  const clear = () => {
    //Note: don't clear supported countries since that is used at login

    isActivatingAccount.value = false
    isRecoveringUsername.value = false
    isRecoveringPassword.value = false
    passwordWasReset.value = false
    registrationUsername.value = ''
    registrationPassword.value = ''

    activateStart.value = {
      phoneNumber: '',
      phoneCountryCode: '',
      formattedPhoneNumber: '',
      countryCode: '',
      dateOfBirth: '',
    }
  }

  const setResetState = () => {
    isActivatingAccount.value = false
    isRecoveringUsername.value = false
    isRecoveringPassword.value = false
    passwordWasReset.value = false

    activateStart.value = {
      phoneNumber: '',
      phoneCountryCode: '',
      formattedPhoneNumber: '',
      countryCode: '',
      dateOfBirth: '',
    }
  }

  const setIsActivatingAccount = (isActivatingAccountParam: boolean) => {
    isActivatingAccount.value = isActivatingAccountParam
  }

  const setActivateStartPhoneNumber = ({ phoneCountryCode, phoneNumber, formattedPhoneNumber }: {
    phoneCountryCode: string;
    phoneNumber: string;
    formattedPhoneNumber: string;
  }) => {
    activateStart.value.phoneCountryCode = phoneCountryCode
    activateStart.value.phoneNumber = phoneNumber
    activateStart.value.formattedPhoneNumber = formattedPhoneNumber
  }

  const setActivateStartCountryCode = (countryCode: string) => {
    activateStart.value.countryCode = countryCode
  }

  const setActivateStartDateOfBirth = (dateOfBirth: string) => {
    activateStart.value.dateOfBirth = dateOfBirth
  }

  const setCountries = (data: CountriesListResult) => {
    supportedCountries.value = data
  }

  const setIsRecoveringUsername = (flag: boolean) => {
    isRecoveringUsername.value = flag
  }

  const setIsRecoveringPassword = (flag: boolean) => {
    isRecoveringPassword.value = flag
  }

  const setPasswordWasReset = (flag: boolean) => {
    passwordWasReset.value = flag
  }

  const setRegistrationUsername = (username: string) => {
    registrationUsername.value = username;
  }

  const setRegistrationPassword = (password: string) => {
    registrationPassword.value = password;
  }

  const loadCountries = async () => {
    let data: CountriesListResult;
    if (!supportedCountries.value.countryOptions.length) {
      data = await getListofCountries()
      setCountries(data)
    } else {
      data = supportedCountries.value
    }
    return data
  }

  return {
    supportedCountries,
    isActivatingAccount,
    isRecoveringUsername,
    isRecoveringPassword,
    passwordWasReset,
    registrationUsername,
    registrationPassword,
    activateStart,

    clear,
    setResetState,
    setIsActivatingAccount,
    setActivateStartPhoneNumber,
    setActivateStartCountryCode,
    setActivateStartDateOfBirth,
    setIsRecoveringUsername,
    setIsRecoveringPassword,
    setPasswordWasReset,
    setRegistrationUsername,
    setRegistrationPassword,
    loadCountries
  }
})
<script lang="ts" setup>
import {
  defineExpose,
  type PropType,
  ref,
  computed
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import type { ValidationRule } from 'quasar';
import { i18n } from '@/plugins/i18n';

const props = defineProps({
  modelValue: {
    type: Object
  },
  disable: {
    type: Boolean,
    default: false
  },
  omitDefaultOption: {
    type: Boolean,
    default: false
  },
  defaultOptionText: {
    type: String,
    default: i18n.global.t('drop_down_default')
  },
  optionDisable: {
    type: String
  },
  optionLabel: {
    type: String,
    default: 'label'
  },
  options: {
    type: Array,
    default: () => []
  },
  optionValue: {
    type: String,
    default: 'value'
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl125 - sets width to `125px`.
   * qFormEl300 - sets width to `300px`.
   */
  selectWidth: {
    type: String,
    default: FormElWidthEnum.qFormElAuto,
  }
})

const emit = defineEmits(['update:modelValue'])
const qfield = ref();
const syncValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

defineExpose({
  validate: () => qfield.value.validate()
});

</script>

<template>
  <!-- Field -->
  <q-field v-model="syncValue" :class="props.selectWidth" :disable="props.disable" hide-bottom-space no-error-icon
    outlined :readonly="props.readonly" ref="qfield" :rules="props.rules" tag="div">

    <!-- Control Slot -->
    <template #control>
      <select v-model="syncValue" :class="props.selectWidth" :disabled="disable">

        <!-- Default Option -->
        <option v-if="!props.omitDefaultOption" :label="defaultOptionText" :value="undefined" />

        <!-- Options -->
        <option v-for="(opt, i) in props.options" :disabled="opt[props.optionDisable]" :key="i" :value="opt">{{
          opt[props.optionLabel] }}</option>
      </select>

      <!-- Arrow -->
      <q-icon name="fas fa-angle-down" size="14px" />
    </template>
  </q-field>
</template>

<style scoped lang="scss">

// QField
.q-field {
  $select-bkgd-size-w: 9px;

  // Native
  :deep(.q-field__native) {
    position: relative;
    padding: 0;
    border: none;
  }

  // Select
  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    line-height: $body-line-height;
    color: $dark-teal;
    padding-block: $field-padding-block $field-padding-block-outlined;
    padding-inline: 0 ($field-padding-inline + $select-bkgd-size-w);
    border: none;
    border-block-end: $generic-border-form;
    border-radius: 0; // This is to overwrite Safari's User Agent Style Sheet.
    

    &:focus {
      outline: none;
    }
  }

  // Arrow
  .q-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
    color: $dark-teal;
  }

  // Readonly
  &--readonly { // .q-field

    // Select
    select {
      pointer-events: none;
      color: $body-txt-clr;
    }

    // Arrow
    .q-icon {
      color: $input-text-color;
    }

    // Outlined
    &.q-field--outlined { // .q-field

      // Select
      select {
        border-color: transparent;
      }
    }
  }

  // Disabled
  &--disabled { // .q-field

    // Outlined
    &.q-field--outlined { // .q-field

      // Select
      select {
        border-color: rgba($body-txt-clr, 0.3);
      }
    }
  }

  // Outlined
  &--outlined { // .q-field
    $select-bkgd-pos-offset-x: 12px;
    
    // Native
    :deep(.q-field__native) {
      border: none;
    }

    // Select
    select {
      padding-block-start: $field-padding-block-outlined;
      padding-inline: $field-padding-inline ($field-padding-inline + $select-bkgd-size-w + $select-bkgd-pos-offset-x);
      border: $generic-border-form;
      border-radius: $generic-border-radius-sm;
    }

    // Arrow
    .q-icon {
      right: $select-bkgd-pos-offset-x;
    }
  }

  // Error
  &--error { // .q-field
  
    select {
      background-color: $notify-yellow;
    }
  }

  // Auto Height
  &--auto-height { // .q-field
  
    // Outlined
    &.q-field--outlined {
  
      // Native
      :deep(.q-field__native) {
        padding: 0;
      }
    }
  }

  // Dark
  &--dark { // .q-field
  
    // Native & Input
    :deep(.q-field__native) {
  
      select {
        background-color: transparent;
        color: map-get($co-brand-clrs, content-grd-bg-content);
        border-block-end-color: map-get($co-brand-clrs, content-grd-bg-content);
      }

      // Arrow
      .q-icon {
        color: map-get($co-brand-clrs, content-grd-bg-content);
      }
    }
  
    // Error
    &.q-field--error {
  
      :deep(.q-field__bottom) {
        color: $warning;
      }
    }
  }
}
</style>
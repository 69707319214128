import { defineStore } from 'pinia';
import _ from 'lodash';
import { linkify } from '@/utils/stringUtils';
import { type MessageModel, type User } from 'src/types/interfaces';

export const useMessagesStore = defineStore({
  id: 'messages',
  state: () => ({
    messages: [] as MessageModel[],
    careTeam: [] as User[],
    unreadMessagesCount: 0,
    isLoading: false,
    outOfOfficeMessageTitle: '',
    outOfOfficeMessageDescription: '',
    hasShownOooModal: false,
    userId: 0
  }),
  getters: {
    unreadMessages(state) {
      return _.filter(state.messages, m => m.fromId !== state.userId && m.readDate == null);
    },
    getAvatarforClinicianUserId(state) {
      return (userId) => {
        const clinician = state.careTeam ? state.careTeam.find(x => x.userId === userId) : null;
        return clinician?.avatarBase64 ?? null;
      };
    },
  },
  actions: {
    clear() {
      this.messages = [];
      this.careTeam = [];
      this.unreadMessagesCount = 0;
      this.isLoading = false;
      this.outOfOfficeMessageTitle = '';
      this.outOfOfficeMessageDescription = '';
      this.hasShownOooModal = false;
    },
    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
    setMessages(messages) {
      this.messages = _.sortBy(messages, ['createdDate']);
    },
    setUnreadMessagesCount(count) {
      this.unreadMessagesCount = count;
    },
    setUserId(userId: number) {
      this.userId = userId;
    },
    setOutOfOfficeMessage(outOfOfficeMessage) {
      this.outOfOfficeMessageTitle = outOfOfficeMessage?.localizedTitle || '';
      this.outOfOfficeMessageDescription = outOfOfficeMessage?.localizedDescription.length > 0 ? linkify(outOfOfficeMessage?.localizedDescription) : '';
    },
    setCareTeam(careTeam) {
      this.careTeam = careTeam;
    },
    setHasShownOooModal() {
      this.hasShownOooModal = true;
    },
    loadMessages(messageThread) {
      this.setMessages(messageThread.messages);
      this.setUnreadMessagesCount(this.unreadMessages.length);
      this.setOutOfOfficeMessage(messageThread.outOfOfficeMessage);
    },
    clearUnreadMessagesCount() {
      this.setUnreadMessagesCount(0);
    },
  },
});

<script lang="ts" setup>
import { ref, nextTick, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { createProfileInformation, getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import { useSessionStore } from 'src/store';
import { useQuasar } from 'quasar';

const router = useRouter();
const postalCode = ref('');
const postalCodeInput = ref({} as any);
const email = ref('');
const emailInput = ref({} as any);
const formIsValid = ref(false);
const sessionModule = useSessionStore();
const $q = useQuasar()

function validate() {
  nextTick(() => {
    formIsValid.value = postalCodeInput.value?.validate() && emailInput.value?.validate();
  });
}
async function onNextClick() {
  if (!formIsValid.value) return;
  $q.loading.show()
  const response = await handleApiRequest(() =>
    createProfileInformation(email.value, postalCode.value), null, $q
  );
  if (response?.result) {
    const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
    const nextStep = translateStepToRoute(step?.result as string);
    $q.loading.hide()
    router.push({
      name: nextStep,
    });
  } else {
    $q.loading.hide()
  }
}

onMounted(() => {
  sessionModule.setIsHeaderVisible(true);
  (document.querySelectorAll('input:first-child')[0] as HTMLElement).focus();
});
</script>

<template>
  <q-page padding>
    <AuthenticateLayout withHeader>

      <!-- Heading -->
      <template #heading>{{ $t('activate_profile_title') }}</template>

      <!-- View Unique Content -->
      <template #default>
        
        <!-- Email -->
        <div class="form-row">
          <div class="col-auto">
            <zbm-input-with-validation v-model="email" v-on:keyup.enter="onNextClick" ref="emailInput" type="email"
                                       @update:modelValue="validate" :placeholder="$t('email_address')"
                                       :rules="[$zb.validators.required('activate_profile_email_required'), $zb.validators.email()]" />
          </div>
        </div>

        <!-- Postal Code -->
        <div class="form-row">
          <div class="col-auto">
            <zbm-input-with-validation v-model="postalCode" v-on:keyup.enter="onNextClick" ref="postalCodeInput"
                                       type="text" @update:modelValue="validate" :placeholder="$t('activate_profile_postal_code')"
                                       :rules="[$zb.validators.maxLen(25)]" />
          </div>
        </div>
      </template>

      <!-- Progress -->
      <template v-slot:actions>
        <ProgressBar :backEnabled="false" :nextRoute="'Dashboard.Home'" :progress="100"
                     v-model:nextEnabled="formIsValid" @on-next-click="onNextClick" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<style lang="scss" scoped>
:deep(.auth-layout__content) {
  min-height: 400px;
}
</style>
<script lang="ts" setup>
import { useSessionStore } from 'src/store';
import { computed, onMounted, ref } from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { userLogout } from '@/services/userService';
import Box from 'src/components/BoxContainer.vue';
import { useQuasar } from 'quasar';
import Svg from '@/components/Svg.vue';
import {
  mymobilityFor,
  mymobilityLogo,
  poweredByZbWordmark
} from '@/assets';

const $q = useQuasar();

const sessionStore = useSessionStore();

const isLoading = ref(false);

const coBrandingEnabled = computed(() => sessionStore.isCobrandingEnabled),
  logo = computed(() => sessionStore.CobrandedImage('hero-logo'));

onMounted(async () => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  sessionStore.setIsHeaderVisible(false);

  if (sessionStore.user) {
    isLoading.value = true;
    try {
      await userLogout($q);
    } finally {
      isLoading.value = false;
    }
  }

});
</script>

<template>
  <q-page class="no-header welcome-page" padding>
    <Box class="row bs-gutter" :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.lg"
      :box-drop-shadow="$zb.enums.BoxDropShadowEnum.xl">

      <!-- Left Column -->
      <div class="col-12 col-md-6 bg-linear-gradient welcome-page__left">

        <template v-if="coBrandingEnabled">

          <!-- mymobility for Wordmark -->
          <div class="row justify-center mymo-for">
            <div class="col-auto">
              <Svg :vector-paths="mymobilityFor.vectorPaths" :view-box="mymobilityFor.viewBox" />
            </div>
          </div>

          <!-- Co-Branding Logo -->
          <div class="row flex-center co-brand-logo">
            <div class="col-auto">
              <zbm-skeleton-loader dark height="180px" :loading="isLoading" width="100%">
                <img v-if="logo" :src="logo" />
              </zbm-skeleton-loader>
            </div>
          </div>
        </template>

        <template v-else>

          <!-- mymobility Logo -->
          <div class="row justify-center mymo-logo">
            <div class="col-auto">
              <Svg :vector-paths="mymobilityLogo.vectorPaths" :view-box="mymobilityLogo.viewBox" />
            </div>
          </div>
        </template>


        <!-- Powered By w/ ZB Wordmark -->
        <div class="row justify-center powered-by">
          <div class="col-auto">
            <Svg :vector-paths="poweredByZbWordmark.vectorPaths" :view-box="poweredByZbWordmark.viewBox" />
          </div>
        </div>

        <!-- Log In Button -->
        <div class="row justify-center log-in">
          <zbm-skeleton-loader :class="$zb.enums.ButtonWidthEnum.w270" dark height="44px" :loading="isLoading">
            <zbm-btn class="glow--white" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
              :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('log_in')" :to="{ name: 'Login' }"
              :width="$zb.enums.ButtonWidthEnum.w270" />
          </zbm-skeleton-loader>
        </div>

        <!-- Activate Account Button -->
        <div class="row justify-center">
          <zbm-skeleton-loader :class="$zb.enums.ButtonWidthEnum.w270" dark height="44px" :loading="isLoading">
            <zbm-btn :color="$zb.enums.ButtonStdColorEnum.whiteText" :height="$zb.enums.ButtonHeightEnum.lg"
              :label="$t('activate_account')" :to="{ name: 'Activate' }" :width="$zb.enums.ButtonWidthEnum.w270" />
          </zbm-skeleton-loader>
        </div>
      </div>

      <!-- Right Column -->
      <div class="col-12 col-md-6 bg-white welcome-page__right">

        <!-- mymobility Mobile App Content -->
        <div class="row justify-center content">
          <div class="col">
            <h3 class="text-center mb-16">
              <zbm-skeleton-loader height="24px" :loading="isLoading" width="100%">
                {{ $t('pwe_mymobility_mobile_app') }}
              </zbm-skeleton-loader>
            </h3>
            <p class="text-center">
              <zbm-skeleton-loader height="135px" :loading="isLoading" width="100%">
                {{ $t('pwe_sidebar_app_info') }}
              </zbm-skeleton-loader>
            </p>
          </div>
        </div>

        <!-- Get iOS App Button -->
        <div class="row justify-center get-ios">
          <zbm-skeleton-loader class="q-btn--276" height="50px" :loading="isLoading">
            <zbm-btn class="q-btn--276" :color="$zb.enums.ButtonStdColorEnum.accentText"
              href="https://apps.apple.com/us/app/zb-mymobility/id1438566065" icon="fab fa-apple"
              :label="$t('pwe_get_ios_app')" target="_blank" />
          </zbm-skeleton-loader>
        </div>

        <!-- Get Android App Button -->
        <div class="row justify-center get-android">
          <zbm-skeleton-loader class="q-btn--276" height="50px" :loading="isLoading">
            <zbm-btn class="q-btn--276" :color="$zb.enums.ButtonStdColorEnum.accentText"
              href="https://play.google.com/store/apps/details?id=com.mymobility" icon="fab fa-android"
              :label="$t('pwe_get_android_app')" target="_blank" />
          </zbm-skeleton-loader>
        </div>

        <!-- Illustration -->
        <div class="row justify-center illustration">
          <img src="/img/mobile-illustration.png" />
        </div>
      </div>
    </Box>
  </q-page>
</template>

<style scoped lang="scss">
.welcome-page {
  // .q-page

  // Left
  &__left {
    // [class*="col"]
    padding-block: 65px 90px;

    // mymobility for Wordmark,
    // Co-Brand Logo,
    // mymobility Logo
    // & Powered By w/ ZB Wordmark
    .mymo-for,
    .co-brand-logo,
    .mymo-logo,
    .powered-by {
      line-height: 0;
    }

    // mymobility for Wordmark,
    // mymobility Logo
    // & Powered By w/ ZB Wordmark
    .mymo-for,
    .mymo-logo,
    .powered-by {

      svg {
        width: 100%;
        height: auto;
      }
    }

    // mymobility for Wordmark
    .mymo-for {
      // .row
      color: map-get($co-brand-clrs, content-grd-bg-content);
      margin-block-end: 50px;

      svg {
        max-width: 180px;
      }
    }

    // Co-Brand Logo
    .co-brand-logo {
      margin-block-end: 50px;

      >[class*="col"] {
        $co-brand-logo-size: 180px;

        width: 100%;
        max-width: $co-brand-logo-size;
        height: 100%;
        max-height: $co-brand-logo-size;

        img {
          width: 100%;
          max-width: $co-brand-logo-size;
          height: auto;
          max-height: $co-brand-logo-size;
        }
      }

      &+.powered-by {
        margin-block-end: 128px;
      }
    }

    // mymobility Logo
    .mymo-logo {
      // .row
      color: map-get($co-brand-clrs, content-grd-bg-content);
      margin-block-end: 80px;

      svg {
        max-width: 242px;
      }

      &+.powered-by {
        margin-block-end: 150px;
      }
    }

    // Powered By w/ ZB Wordmark
    .powered-by {
      // .row
      color: map-get($co-brand-clrs, content-grd-bg-content);

      svg {
        max-width: 242px;
      }
    }

    // Log In button
    .log-in {
      // .row
      margin-block-end: 45px;
    }
  }

  // Right
  &__right {
    // [class*="col"]
    padding-block: 61px 65px;

    h3 {
      line-height: $line-height-lg;
    }

    p {
      line-height: 45px;
    }

    // iOS & Android Buttons
    .q-btn {
      font-size: 18px;
      padding-inline: 24px;
      
      :deep(.on-left) {
        margin-inline-end: 20px;
      }

      &--276 {
        min-width: 276px;
        max-width: 100%;
      }
    }

    // mymobility Mobile App Content
    .content {
      // .row
      margin-block-end: 25px;
    }

    // Get iOS App Button
    .get-ios {
      // .row
      margin-block-end: 25px;

      .q-btn {
        padding-block: 9px;

        // Apple icon
        :deep(.q-icon) {
          font-size: 30px;
        }
      }
    }

    // Get Android App Button
    .get-android {
      // .row
      margin-block-end: 54px;

      .q-btn {
        padding-block: 12px;

        // Android icon
        :deep(.q-icon) {
          font-size: 24px;
        }
      }
    }

    // Illustration
    .illustration {
      // .row

      img {
        width: 100%;
        max-width: 380px;
      }
    }
  }

  @media (max-width: 315px) {

    // Right
    .bs-gutter>&__right {
      // [class*="col"]

      // iOS & Android Buttons
      .q-btn--276 {
        width: 100%;
      }
    }
  }

  @media (min-width: 520px) {

    // Right
    .bs-gutter>&__right {
      // [class*="col"]
      padding-inline: 50px;
    }
  }

  @media (min-width: 620px) {

    // Right
    .bs-gutter>&__right {
      // [class*="col"]
      padding-inline: 100px;
    }
  }

  @media (min-width: $breakpoint-md-min) {

    // Right
    .bs-gutter>&__right {
      // [class*="col"]
      padding-inline: 100px;
    }
  }

  @media (min-width: $breakpoint-lg-min) {
    max-width: 1400px;

    // Right
    .bs-gutter>&__right {
      // [class*="col"]
      padding-inline: 100px;
    }
  }
}
</style>
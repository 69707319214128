<script lang="ts" setup>
import {
  computed,
  defineModel,
  defineProps,
  ref,
  type PropType
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import type { ValidationRule } from 'quasar';
import { betweenVal } from '@/plugins/quasar-validators';

const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off'
  },
  class: {
    type: String
  },
  dark: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormElFull - sets width to `100%`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl125 - sets width to `125px`.
   * qFormEl300 - sets width to `300px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl125
  },
  label: {
    type: String,
    default: '',
  },
  max: {
    type: Number,
    default: undefined
  },
  min: {
    type: Number,
    default: undefined
  },
  name: {
    type: String
  },
  outlined: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  step: {
    type: Number,
    default: 1
  }
});

const localRules = computed(() => {
  const rules = props.rules;
  if (props.min !== undefined && props.max !== undefined) {
    rules.push(betweenVal(props.min, props.max));
  }
  return rules;
});

const syncValue = defineModel();
const inputRef = ref(null)

defineExpose({
  validate: () => inputRef.value.validate(),
  resetValidation: () => inputRef.value.resetValidation()
});
</script>

<template>
  <!-- Label -->
  <label v-if="props.label" :for="props.for">
    {{ props.label }}
    <slot name="label-append" />
  </label>

  <!-- Field -->
  <q-input v-model.number="syncValue" :autocomplete="props.autocomplete" :class="[props.inputWidth, props.class]"
    :dark="props.dark" :disable="props.disable" hide-bottom-space :name="props.name" no-error-icon
    :outlined="props.outlined" :placeholder="props.placeholder" :rules="localRules" :min="props.min" :max="props.max" ref="inputRef"
    :step="step" type="number" />
</template>

<style lang="scss" scoped>
.survey__number {
  // .q-field

  &.q-field {
    margin-inline: auto;

    :deep(.q-field__native) {
      font-size: 56px;
      line-height: 80px;
      color: $secondary;
      text-align: center;
      padding-block: 0;

      &::placeholder {
        font-size: $input-font-size;
      }
    }
  }
}
</style>
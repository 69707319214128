<script lang="ts" setup>
import {
  defineProps,
  type PropType
} from 'vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  contentClass: {
    type: String
  },
  tag: {
    type: String,
    default: "div"
  }
});
</script>

<template>
  <component :is="tag">
    <!-- QSkeleton -->
    <slot name="loading" v-if="loading" />
    <!-- Content -->
    <div v-show="!loading" :class="contentClass">
      <slot name="default" />
    </div>
  </component>
</template>
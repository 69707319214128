<template>
  <SurgeryEduLibLayout :loading="isLoading" :timelineTitle="'days_since_surgery_pluralization_many'"
    :rawLibraryItems="educationItems" :surgicalState="'PostSurgery'" :class="'the-dashboard-education__post'"
    :empty-text="$t('education_no_post')" />
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import SurgeryEduLibLayout from 'src/components/layouts/SurgeryEduLibLayout.vue';
import { useEducationLibraryStore } from 'src/store/educationLibraryModule';
import { useDashboardStore } from 'src/store/dashboardModule';
import { useSessionStore } from '@/store/sessionStore';

import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLibraryEdItems } from 'src/api/endpoints/calendarWeb';
import { type EducationLibraryItem } from 'src/types/webContracts';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const sessionStore = useSessionStore();
const educationLibraryStore = useEducationLibraryStore()
const dashboardStore = useDashboardStore()

const isLoading = ref(true);
const educationItems = ref([] as Array<EducationLibraryItem>);

const preferredLanguage = computed(() => sessionStore.user?.preferredLanguageId);
const protocol = computed(() => dashboardStore.patient?.protocolId);
const hasProtocolAssigned = computed(() => protocol.value !== undefined && protocol.value !== null);

onMounted(async () => {
  if (protocol.value === undefined || preferredLanguage.value === undefined) return;
  await getTheData();
});

async function getTheData() {
  if (!hasProtocolAssigned.value) {
    isLoading.value = false;
    return;
  }

  const response = await handleApiRequest(() =>
    getLibraryEdItems(
      protocol.value,
      preferredLanguage.value,
      dashboardStore.patient?.procedureDate
    ), null, $q
  );

  if (response != undefined) {
    educationLibraryStore.setPostSurgeryItems(response.result);
    educationItems.value = educationLibraryStore.postSurgeryItems;
    isLoading.value = false;
  }
}

watch(protocol, () => {
  if (preferredLanguage.value == undefined) return;
  getTheData();
});

watch(preferredLanguage, () => {
  if (protocol.value == undefined) return;
  getTheData();
});
</script>

import axios from 'axios'
import { bus } from '@/utils/eventBus';

const csrf_token = (function () {
  let csrfToken;

  if (document.cookie.indexOf('CSRF-TOKEN') > -1) {
    const v = document.cookie.match('(^|;) ?' + 'CSRF-TOKEN' + '=([^;]*)(;|$)');

    csrfToken = v ? v[2] : '';
  } else {
    csrfToken = '';
  }
  return csrfToken;
}());
const axiosInstance = axios.create({
  timeout: 60000,
  headers: {
      get: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      post: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': csrf_token,
        'X-Requested-With': 'XMLHttpRequest'
      },
      put: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': csrf_token,
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  })

axiosInstance.interceptors.response.use(function (response) {
    if (response && response.status === 200 && response.headers?.location) {
        window.location.href = response.headers.location;
    return Promise.reject();
    }
  return response;
}, function (error) {
  if (error.response && error.response.status === 401) {
    const requestUrl = error.config.url.toLowerCase();
    // redirect user to welcome page, but not on Login controller
    if (requestUrl.indexOf('login') < 0) {
      bus.emit('user-log-out', 'Welcome')
    }
  }
  return Promise.reject(error);
});

export default axiosInstance
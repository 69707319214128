// import { updateAddress } from 'src/api/endpoints/patientWeb';

export const ApiRoutes = {
  Login: {
    GetUserSession: '/Login/GetUserSession',
    ValidateActivationCode: '/Login/ValidateActivationCode',
    SendActivationCode: '/Login/SendActivationCode',
    LoginPost: '/Login/Login',
    LogOutPost: '/Login/Logout',
    GetListOfCountries: '/Login/GetSupportedCountriesList',
    GetForgottenUserName: '/Login/GetForgottenUsername',
  },

  GetLocalizedStrings: '/Localization/GetLocalizedStrings',

  GetSettings: '',
  Settings: {
    PreBootSettings: '/Settings/PreBootSettings',
    GetBestFitCultureProvider: '/Settings/GetBestFitCultureProvider',
    GetInstructionsForUseUrl: '/Settings/GetInstructionsForUseUrl',
  },

  Home: {
    GetPatientProcedures: '/Home/GetPatientProcedures',
    SetSelectedProcedure: '/Home/SetSelectedProcedure',
  },
  
  Message: {
    GetMessages: '/Message/GetMessages',
    SendMessage: '/Message/SendMessage',
    MarkAllMessagesAsRead: 'Message/MarkAllMessagesAsRead',
    GetCareTeam: '/Message/GetCareTeam'
  },

  Patient: {
    GetPatientProfileData: '/Patient/GetPatientProfileData',
    LinkCanaryAccount: '/Patient/LinkCanaryAccount',
    Alerts: '/Patient/GetAlerts',
    Tasks: '/Patient/GetTasks',
    ChangePassword: '/Patient/ChangePassword',
    GetPatientConfigAsync: '/Patient/GetPatientConfig',
    PIQSetup: {
      CanaryAddress: '/Patient/CanaryMailingAddress',
      CanaryHeightWeight: 'Patient/CanaryHeightWeight',
      CanarySampleTimezone: 'Patient/CanarySampleTimezone',
      CanaryCaregiverDetail: 'Patient/CanaryCaregiverDetail',
      NextCanaryPatientActivationStep: 'Patient/NextCanaryPatientActivationStep',
      CanaryCompleteBaseStationSetup: 'Patient/CanaryCompleteBaseStationSetup',
      CanaryCompleteBaseStationPrecheck: 'Patient/CanaryCompleteBaseStationPrecheck',
    },
    SetProcedureConsent: '/Patient/SetProcedureConsent',
    GetCommunicationPreferences: '/Patient/GetCommunicationPreferences',
    SetCommunicationPreferences: '/Patient/SetCommunicationPreferences'
  },

  Statistics: {
    PatientDataSources: '/Statistics/PatientDataSources',
    PatientMeasurementStats: '/Statistics/PatientMeasurementStats',
    PatientProgress: 'Statistics/PatientProgress',
    PatientStatisticSummaries: '/Statistics/GetPatientStatisticSummaries',
  },

  Calendar: {
    GetSurveyDefinition: '/Calendar/GetSurveyDefinition',
    GetEducationAsset: '/Calendar/GetEducationAsset',
    GetRosaVideoAsset: '/Calendar/GetRosaVideoAsset',
    UpdateSurveyProgress: '/Calendar/UpdateSurveyProgress',
    UpdateEducationStatus: '/Calendar/UpdateEducationStatus',
    GetLibraryEdItems: '/Patient/GetEducationLibraryItems',
    GetLibraryRosaItems: '/Calendar/GetRosaLibraryItems'
  },

  Routine: {
    GetRoutine: '/Routine/GetRoutine',
    UpdateExerciseCompletion: '/Routine/UpdateExerciseCompletion',
    SetRoutineCompleteRequest: '/Routine/SetRoutineComplete'
  },

  Registration: {
    ValidatePassword: '/Registration/ValidatePassword',
    CreatePassword: '/Registration/CreatePassword',
    SetPassword: '/Registration/SetPassword',
    PutProfileInformation: '/Registration/ProfileInformation',
    UserName: '/Registration/UserName',
    GetLocalizedUserPolicy: '/Registration/GetLocalizedUserPolicy',
    UpdatePolicy: '/Registration/UpdateUserPolicySignOff',
    UploadProfilePhoto: '/Registration/UploadProfilePhoto',
    GetRegistrationNextStep: '/Registration/GetNextRegistrationStep'
  }
}
<template>
  <q-page id="about-your-communication-preferences" padding>
    <MultiViewLayout :showBackArrow="false" :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
                     :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.default" :box-width="$zb.enums.BoxWidthEnum.xs">

      <!-- Cancel Icon -->
      <div class="communication-preferences-cancel">
        <q-btn flat label="x" size="20px" @click="onBackClick" />
      </div>

      <!-- Heading -->
      <h2>{{ $t('communication') }}</h2>

      <!-- Comments Icon -->
      <div class="communication-preferences-comments">
        <q-icon name="fas fa-comments" size="100px" />
      </div>

      <!-- Content -->
      <h3>{{ $t('communication') }}</h3>
      <p class="last">{{ $t('communication_description') }}</p>

      <!-- Okay Button -->
      <zbm-btn :color="$zb.enums.ButtonStdColorEnum.darkTeal" @click="onOkayClicked"
               :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" />

      <!-- Skip For Now Dialog -->
      <q-dialog v-model="skipForNowDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('skip_for_now') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('update_communication_status')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn class="skipForNowButton" flat :label="$t('gen_okay')" @click="skipForNowClick" v-close-popup />
            <q-btn class="goBackButton" flat :label="$t('go_back')" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useSessionStore } from 'src/store';
  import { useDashboardStore } from 'src/store/dashboardModule';
  import MultiViewLayout from '@/components/layouts/MultiViewLayout.vue';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';

  const sessionStore = useSessionStore();
  const dashboardStore = useDashboardStore();

  const router = useRouter();
  const $q = useQuasar();

  let skipForNowDialog = ref(false)

  onMounted(() => {
    document.querySelectorAll('html')[0].scrollTop = 0;
    sessionStore.setIsHeaderVisible(true);
  })

  const onOkayClicked = async () => {
    router.push({ name: 'CommunicationPreferences', params: { interrupt: 'true' } });
  }

  const onBackClick = async () => {
    skipForNowDialog.value = true;
  }

  const skipForNowClick = async () => {
    dashboardStore.setSkipCommunicationPreferencesInterrupt(true);
    router.push({ name: 'Interrupts', params: { reload: 'false' } });
  }

</script>
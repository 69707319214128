<script lang="ts" setup>
import {computed} from 'vue';
import {useSessionStore} from 'src/store';
import {useRoute} from 'vue-router';

const sessionStore = useSessionStore(),
  route = useRoute();

const displayLegalLinks = computed(() => {
  return sessionStore.isAuthenticated
    && sessionStore.user?.hasFullAccess
    && route.name !== 'SelectCarePlan'
    && route.name !== 'AboutYourData';
});
</script>

<template>
  <q-footer>
    
    <!-- Copyright -->
    {{ $t('footer_copyright_pwe', [new Date().getFullYear().toString()]) }}<br class="lt-sm" />
    <span class="gt-xs">|</span>
    
    <template v-if="displayLegalLinks">
      
      <!-- Privacy Policy -->
      <router-link :to="{ name: 'Settings.PrivacyPolicy' }">{{ $t('activate_privacy_policy') }}</router-link>
      <span>|</span>

      <!-- EULA -->
      <router-link :to="{ name: 'Settings.EULA' }">{{ $t('settings_eula') }}</router-link>
      <span>|</span>
      
      <!-- Global Consent -->
      <a href="https://support.zbmymobilitysolutions.com/patients/global-consent/?lang=en&set_lang=en" target="_blank">{{ $t('consent_global_consent') }}</a>
      <span>|</span>
    </template>
    
    <!-- Support -->
    <a href="https://support.zbmymobilitysolutions.com/patients/" target="_blank">{{ $t('settings_help_support') }}</a>
  </q-footer>
</template>

<style scoped lang="scss">
  .q-footer {
    background-color: map-get($co-brand-clrs, primitives-brand-100);
    font-size: 12px;
    text-align: center;
    padding-block: 16px;
    padding-inline: $bs-grid-gutter-xs;
    
    a {
      color: map-get($co-brand-clrs, content-grd-bg-content);
    }
    
    span {
      margin-inline: 4px;
    }

    @media (min-width: $breakpoint-sm-min) {
      text-align: start;
    }
    
    @media (min-width: $breakpoint-md-min) {
      padding-inline: $bs-grid-gutter-md;
    }
  }
</style>
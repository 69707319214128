<template>
  <LegalAndPrivacyLayout headingLocalizerKey="privacy_policy_header" :policyType="policyType" scrollable
    :showTitle="false">
    <template v-slot:actions>
      <div class="row">
        <div class="col">
          <!-- Checkbox -->
          <zbm-checkbox v-model="checked" id="userPolicyCheck" name="userPolicyCheck">
            {{ $t('canary_privacy_policy') }}
          </zbm-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
        :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
        <ProgressBar :progress="30" :nextEnabled="true" @on-back-click="onBackClick" @on-next-click="onNextClick" />
      </Box>
    </template>
  </LegalAndPrivacyLayout>
</template>

<script setup lang="ts">
import { UserPolicyType } from 'src/constants/enums';
import Box from 'src/components/BoxContainer.vue';
import { getNextActivationRoute } from 'src/services/piqSetupService';

import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import LegalAndPrivacyLayout from 'src/components/layouts/LegalAndPrivacyLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import DisplayHtmlPolicy from 'src/components/DisplayHtmlPolicy.vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { updatePolicy } from 'src/api/endpoints/registrationWeb';
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory';
import { useGeneralPurposeModalStore } from 'src/store';
import { i18n } from 'src/plugins/i18n';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const router = useRouter();

const policyType = ref<UserPolicyType>(UserPolicyType.CanaryPiqPrivacyPolicy);
const checked = ref(false);

async function onNextClick() {
  if (checked.value) {
    $q.loading.show();
    const response = await handleApiRequest(() => updatePolicy(policyType.value, false, true), null, $q
    ).finally(() => {
      $q.loading.hide();
    });

    if (!response?.result) {
        // error
    } else {
        var nextRoute = await getNextActivationRoute($q);
        router.push({ name: nextRoute }).catch(() => { });
    }
  
  } else {
    const info = createDecisionModal('', i18n.global.t('piq_t_c_error'));
    generalPurposeModalStore.setModal(info);
  }
}

async function onBackClick() {
  router.go(-1);
}

</script>
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">
      <!-- Title -->
      <template v-slot:title>{{ titleLocalized }}</template>
      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ headingLocalized }}</template>
      <!-- View Unique Content -->
      <template v-slot:default>
        <!-- Education Content -->
        <zbm-async-content :loading="isLoading">
          <template v-slot:loading>
            <div class="row justify-center">
              <template v-for="i in 5" :key="i">
                <div :class="`col-auto pb-12 pt-${i > 1 ? '12' : ''}`">
                  <q-skeleton type="rect" width="540px" height="20px" />
                </div>
              </template>
            </div>
          </template>
          <div v-html="content"></div>
        </zbm-async-content>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, Ref, computed, onMounted } from 'vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLibraryRosaItems } from 'src/api/endpoints/calendarWeb';
import { useEducationLibraryStore } from 'src/store/educationLibraryModule';
import { RosaLibraryItem } from 'src/types/webContracts';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const educationLibraryStore = useEducationLibraryStore()
const router = useRouter()

const props = defineProps({
  itemId: { type: Number, },
  title: { type: String },
  heading: String
})

const content = ref('')
const isLoading = ref(false)
const headingOverride = ref('')
const item: Ref<RosaLibraryItem> = ref({} as RosaLibraryItem)

const headingLocalized = computed((): any => {
  return headingOverride.value || props.heading ? i18n.global.t(props.heading) : 'UNKNOWN';
})

const titleLocalized = computed((): any => {
  return props.title;
})

const fetchItemProps = () => {
  item.value = educationLibraryStore.rosaItems.find((i, idx, set) => {
    if (i.id == props.itemId) {
      return set[idx];
    }
  }) || {
    id: 0,
    name: '',
    url: '',
    content: '',
  };
  headingOverride.value = item.value.name;
  content.value = item.value.content?.replace(/\\n/g, '<br>').replace(/\\/g, '') || '';
}

const onBackClick = () => {
  router.go(-1);
}

onMounted(async () => {
  if (!educationLibraryStore.rosaItems.length) {
    isLoading.value = true;
    const response = await handleApiRequest(() => getLibraryRosaItems(), null, $q);
    if (response != undefined) {
      educationLibraryStore.setRosaItems(response.result);
      fetchItemProps();
    }
  } else {
    fetchItemProps();
  }
  isLoading.value = false;
});
</script>
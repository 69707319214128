<template>
  <q-page padding>
    <UserPolicySignOff :policyType="policyType" :headingLocalizerKey="'china_activate_cross_border'"
      :checkBoxLabelLocalizerKey="'china_activate_cross_border_accepted'"
      :modalBodyLocalizerKey="'china_activate_cross_border_error'" :progress="65"
      :nextRoute="'Activate.CreateUsername'" />
  </q-page>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { UserPolicyType } from 'src/constants/enums';
import UserPolicySignOff from 'src/pages/Activation/components/UserPolicySignOff.vue';

const policyType = ref(UserPolicyType.CrossBorderDataTransfer);
</script>
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">
      <!-- Title -->
      <template v-slot:title>{{ header }}</template>
      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ title }}</template>
      <!-- View Unique Content -->
      <template v-slot:default>
        <!-- Education Content -->
        <SkeletonLoader type="text" width="100%" height="400px" :loading="isLoading">
          <div id="renderBox" />
        </SkeletonLoader>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, computed } from 'vue';

import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getEducationAsset, updateEducationStatus } from 'src/api/endpoints/calendarWeb';
import { useDashboardStore, useGeneralPurposeModalStore } from 'src/store';
import { useSessionStore } from '@/store/sessionStore';
import { GeneralPurposeVariant, TaskType } from 'src/constants/enums';
import { getPatientStatisticSummaries } from 'src/api/endpoints/statisticsWeb';
import type { EducationCompleteRequest, Task } from 'src/types/webContracts';
import { loadEpub } from 'src/utils/zbdev.epub';
import { reformatString } from 'src/utils/stringUtils';
import { i18n } from '@/plugins/i18n';
import { useRoute, useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();
const sessionStore = useSessionStore();
const generalPurposeModalStore = useGeneralPurposeModalStore()
const dashboardStore = useDashboardStore()

const isLoading = ref(false);
const title = ref('');

const header = computed(() => {
  return i18n.global.t(props.sectionHeader);
});

const props = defineProps(
  {
    tickSignature: { type: String },
    itemId: {
      type: Number,
      required: true,
    },
    redirectPage: {
      type: String
    },
    sectionHeader: { type: String, default: 'gen_education' },
  }
);

onMounted(async () => {
  const user = sessionStore.user;
  if (user) {
    isLoading.value = true;
    const response = await handleApiRequest(() =>
      getEducationAsset(props.itemId, user.preferredLanguageId), null, $q
    );

    if (response) {
      loadEpub(response.result.epub, 'renderBox');
      title.value = response.result.name;
      if (typeof props.tickSignature !== 'undefined') {
        markCompleted();
      }
    } else {
      generalPurposeModalStore.setModal({
        variant: GeneralPurposeVariant.Decision,
        title: i18n.global.t('gen_error_sorry').toString(),
        template: reformatString(i18n.global.t('education_error_epub').toString()),
      });

      dashboardStore.markTaskCompleted({ id: props.itemId, taskType: TaskType.education });
    }
  }
  isLoading.value = false;
});

async function markCompleted() {
  const user = sessionStore.user;
  if (user) {
    const tasks = dashboardStore.tasks;
    const { eventDate, isComplete } =
      tasks?.find((item) => item.tickSignature === props.tickSignature) ||
      ({} as Task);

    if (isComplete === false) {
      const updateObject = {
        userId: user.userId,
        eventDate,
        tickSignature: props.tickSignature,
      } as EducationCompleteRequest;
      const edResponse = await handleApiRequest(() =>
        updateEducationStatus(updateObject), null, $q
      );

      if (edResponse) {
        dashboardStore.markTaskCompleted({ id: props.itemId, taskType: TaskType.education });

        const statsResponse = await handleApiRequest(() => getPatientStatisticSummaries(), null, $q);
        if (statsResponse) {
          dashboardStore.loadPatientWearables(statsResponse.result);
        }
      }
    }
  }
}

function onBackClick() {
  let redirectPage = 'Dashboard.Home';
  const redirectFlag = props.redirectPage;

  switch (redirectFlag) {
    case 'PreSurgery':
      redirectPage = 'Dashboard.Education.PreSurgery';
      break;
    case 'PostSurgery':
      redirectPage = 'Dashboard.Education.PostSurgery';
      break;
  }

  router.push({ name: redirectPage });
}
</script>
import axios from 'src/api/axiosConfig'
import { ApiResponse } from 'src/types/apiTypes'
import { RoutineModel, SetRoutineCompleteRequest, UpdateExerciseCompleteRequest } from 'src/types/webContracts'
import { AxiosResponse } from 'axios'
import { ApiRoutes } from '../ApiRoutes'

export async function getRoutine(eventId: number, languageId: number, tickSignature: string): Promise<AxiosResponse<ApiResponse<RoutineModel>>> {
  const response = await axios.get(ApiRoutes.Routine.GetRoutine, { params: { eventId, languageId, tickSignature } });
  return response;
}

export async function updateExerciseCompletion(model: UpdateExerciseCompleteRequest): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Routine.UpdateExerciseCompletion, model)
  return response;
}

export async function setRoutineComplete(model: SetRoutineCompleteRequest): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Routine.SetRoutineCompleteRequest, model)
  return response;
}
<script setup lang="ts">
import {
  defineProps,
  type PropType
} from 'vue';
import {
  ButtonHeightEnum,
  ButtonStdColorEnum
} from '@/constants/enums';

const props = defineProps({
  class: {
    type: String
  },
  color: {
    type: String as PropType<ButtonStdColorEnum>
  },
  disable: {
    type: Boolean
  },
  /* Controls the height of the Button. Options are:
   * "sm" - sets the `height` to 30px with `padding-inline` to 20px.
   * "md" - sets the `height` to 40px with `padding-inline` to 40px.
   * "lg" - sets the `height` to 44px with `padding-inline` to 40px.
   */
  height: {
    type: String as PropType<ButtonHeightEnum>
  },
  icon: {
    type: String,
    required: true
  },
  outline: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  size: {
    type: String
  },
  square: {
    type: Boolean,
    default: false
  },
  textColor: {
    type: String,
  }
});
</script>

<template>
  <q-btn :class="['q-btn--icon', props.height, props.class]"
         :color="props.color"
         :disable="props.disable"
         :icon="props.icon"
         no-caps
         :outline="props.outline"
         :ripple="false"
         :rounded="props.rounded"
         :size="props.size"
         :square="props.square"
         :text-color="props.textColor"
         unelevated />
</template>

<style scoped lang="scss"></style>
<script setup lang="ts">
import { ref, computed, type PropType, type Ref } from 'vue';
import type {
  SurveyPermittedAnswerModel,
  SurveyProgressAnswer,
  SurveyQuestionModel,
} from 'src/types/model';
import { useSurveyStore } from 'src/store';
import { SurveyComponentTypeEnum } from 'src/constants/survey';
import Checkbox from 'src/components/form/Checkbox.vue';
import Radio from 'src/components/form/Radio.vue';
import InputWithValidation from 'src/components/form/InputWithValidation.vue';
import NumberInputWithValidation from 'src/components/form/NumberInputWithValidation.vue';
import SelectWithValidation from 'src/components/form/SelectWithValidation.vue';

const props = defineProps({
  depth: {
    type: Number,
    required: true
  },
  question: {
    type: Object as PropType<SurveyQuestionModel>,
    required: true
  },
  permittedAnswer: {
    type: Object as PropType<SurveyPermittedAnswerModel>,
    required: true
  },
});

const surveyModule = useSurveyStore();
const multipleChoiceSubAnswers = ref();
const syncValue = defineModel() as Ref<Record<string, unknown>>;


const showOptions = computed(() => {
  const selectedValues = syncValue.value[props.depth] as Record<string, unknown>;
  if (props.permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.radio) {
    return selectedValues[`${props.permittedAnswer.renderingConfig.groupIdentifier}`] == props.permittedAnswer.answerId.toString();
  } else if (props.permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.checkbox) {
    return selectedValues[`${props.permittedAnswer.answerId}-checked`];
  }
  else if (props.permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.heading) {
    return true;
  }
});

const value = computed(() => {
  let value;
  const selectedValues = syncValue.value[props.depth] as Record<string, unknown>;

  const radioValue =
    selectedValues[props.permittedAnswer.renderingConfig.groupIdentifier as string];

  const textValue = selectedValues[`${props.permittedAnswer.answerId}-text`];
  const checkedValue = selectedValues[`${props.permittedAnswer.answerId}-checked`];
  const numberValue = selectedValues[`${props.question.questionId}-number`];
  const dropdownValue = selectedValues[`${props.permittedAnswer.answerId}-dropdown`];

  switch (props.permittedAnswer.renderingConfig.type) {
    case SurveyComponentTypeEnum.checkbox:
      value = checkedValue ? checkedValue : undefined;
      break;
    case SurveyComponentTypeEnum.radio:
      value = parseInt(radioValue as string, 10) === props.permittedAnswer.answerId || undefined;
      break;
    case SurveyComponentTypeEnum.textbox:
      value = textValue ? textValue : undefined;
      break;
    case SurveyComponentTypeEnum.number:
      value = typeof (numberValue) == 'number' ? numberValue : undefined;
      break;
    case SurveyComponentTypeEnum.dropdown:
      value = dropdownValue ? dropdownValue : undefined;
  }
  return value;
});


function onCheckboxChanged() {
  const selectedValues = syncValue.value[props.depth] as Record<string, unknown>;
  const currentValueKey = `${props.permittedAnswer.answerId}-checked`;
  const isChecked = selectedValues[currentValueKey];

  //if an exclusive none option exists, it's behavior is "checkability" is mutually exclusive to all other checboxes in its depth
  //If we are checking an exclusive none anwswer, change all other answers to not checked
  //If we are checking a regular answer, change the exlcusive none answer to not checked
  const isExclusiveNoneAnswerChecked = props.permittedAnswer.renderingConfig.exclusiveChoice;

  //behavior remove any granchildren answers that may have been given prior to choosing an Exclusive None answer
  //reason: prevents secret submission of child answers if they were specified previously for regular checkbox parents that are no longer selected
  if (isExclusiveNoneAnswerChecked) {
    const childrenState = syncValue.value[props.depth + 1] as Record<string, unknown>;
    for (const key in childrenState) {
      childrenState[key] = undefined;
    }
  }

  //behavior: clean up any descendeant children state since props parent was unselected
  //reason: prevents secret submission of child answers if they were specified previously
  if (!isChecked && props.permittedAnswer.subAnswers?.length) {
    const childrenState = syncValue.value[props.depth + 1] as Record<string, unknown>;
    props.permittedAnswer.subAnswers.forEach((subItem) => {
      if (childrenState[`${subItem.answerId}-text`]) {
        childrenState[`${subItem.answerId}-text`] = undefined;
      }
      if (childrenState[`${subItem.answerId}-checked`]) {
        childrenState[`${subItem.answerId}-checked`] = undefined;
      }
      if (childrenState[`${props.question.questionId}-number`]) {
        childrenState[`${props.question.questionId}-number`] = undefined;
      }
      if (childrenState[`${subItem.answerId}-dropdown`]) {
        childrenState[`${subItem.answerId}-dropdown`] = undefined;
      }
    });
  }

  //behavior: props section handles special checkmark behavior between regular checkboxes and a ExclusiveNone checkbox.
  //reason: if you select an ExclusiveNone checkbox, unselect the rest and if you have ExclusiveNone checkbox selected then selected a regular checkbox, then
  //        unselect the ExclusiveNone
  for (const key in selectedValues) {
    const answerId = parseInt(key.split('-')[0]);
    const permittedAnswer = surveyModule.allPermittedAnswersOnCurrentPage.find(
      (pa) => pa.answerId === answerId
    );

    if (isChecked && key !== currentValueKey) {
      if (isExclusiveNoneAnswerChecked || permittedAnswer?.renderingConfig.exclusiveChoice)
        selectedValues[key] = false;
    }
  }
}

function onRadioChanged() {
  const selectedValues = syncValue.value[props.depth];
  const currentValueKey = `${props.permittedAnswer.renderingConfig.groupIdentifier}`;
  const currentValue = selectedValues[currentValueKey];
  const permittedAnswer = props.permittedAnswer;

  const siblingPermittedAnswers = surveyModule.allPermittedAnswersOnCurrentPage.filter(
    (pa) =>
      pa.parentAnswerId === permittedAnswer.parentAnswerId &&
      pa.answerId.toString() !== currentValue
  );

  //behavior: clean up any descendent children state since props parent was unselected
  //reason: prevents secret submission of child answers if they were specified previously
  siblingPermittedAnswers.forEach((sibling) => {
    if (sibling.subAnswers?.length) {
      const childrenState = syncValue.value[props.depth + 1] as Record<string, unknown>;
      sibling.subAnswers.forEach((subItem) => {
        if (childrenState[`${subItem.answerId}-text`]) {
          childrenState[`${subItem.answerId}-text`] = undefined;
        }
        if (childrenState[`${subItem.answerId}-checked`]) {
          childrenState[`${subItem.answerId}-checked`] = undefined;
        }
        if (childrenState[`${props.question.questionId}-number`]) {
          childrenState[`${props.question.questionId}-number`] = undefined;
        }
        if (childrenState[`${subItem.answerId}-dropdown`]) {
          childrenState[`${subItem.answerId}-dropdown`] = undefined;
        }
      });
    }
  });
}

async function validate() {
  // let valid = await props.fieldObserver.validate();
  let valid = true
  //only validate children if the "validateChildren property's value matches the current value of the component"
  if (
    multipleChoiceSubAnswers.value &&
    (typeof props.permittedAnswer.renderingConfig.validateChildrenIfValueMatches === 'undefined' ||
      props.permittedAnswer.renderingConfig.validateChildrenIfValueMatches === value.value)
  ) {
    const results: boolean[] = await Promise.all(
      multipleChoiceSubAnswers.value.map((mc: any) => mc.validate())
    );
    valid = valid && results.every((childIsValid) => childIsValid);
  }
  return valid;
}

function getResponses(): SurveyProgressAnswer[] {
  const data = {
    question: props.question,
    permittedAnswer: props.permittedAnswer,
    value: value.value,
  };

  if (multipleChoiceSubAnswers.value) {
    const subChoiceResponses = multipleChoiceSubAnswers.value
      .map((mca: any) => mca.getResponses())
      .flat();
    return [data, ...subChoiceResponses];
  }

  return [data];
}

defineExpose({
  validate,
  getResponses,
});
</script>

<template>
  <div :class="[
    permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.heading ||
      permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.label
      ? 'survey__answer-group'
      : 'survey__answer-option',
    { footer: permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.footer },
  ]">
    
    <!-- Section (heading) -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.heading">
      <h4 class="survey__answer-heading">{{ permittedAnswer.name }}</h4>
    </template>
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.label">
      <div class="survey__answer-label">{{ permittedAnswer.name }}</div>
    </template>
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.footer">
      <span class="italic">{{ permittedAnswer.name }}</span>
    </template>
    
    <!-- Checkbox Input -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.checkbox">
      <Checkbox v-model="syncValue[depth][`${permittedAnswer.answerId}-checked`]"
                @update:modelValue="onCheckboxChanged"
                :variant="$zb.enums.CustomCheckboxVariantEnum.survey"
                :label="permittedAnswer.name" />
    </template>

    <!-- Radio Input -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.radio">
      <Radio v-model="syncValue[depth][permittedAnswer.renderingConfig.groupIdentifier]"
             @update:modelValue="onRadioChanged"
             :id="`${question.questionId}-${permittedAnswer.answerId}`"
             :label="permittedAnswer.name"
             :name="permittedAnswer.renderingConfig.groupIdentifier"
             :radio-variation="$zb.enums.CustomRadioVariantEnum.survey"
             :val="permittedAnswer.answerId.toString()" />
    </template>

    <!-- Text Input -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.textbox">
      <InputWithValidation v-model="syncValue[depth][`${permittedAnswer.answerId}-text`]"
                           :for="permittedAnswer.renderingConfig.vid"
                           :input-width="permittedAnswer.renderingConfig.range.max > 5 ? $zb.enums.FormElWidthEnum.qFormEl300 : $zb.enums.FormElWidthEnum.qFormEl100"
                           :label="$t(permittedAnswer.renderingConfig.labelKey).toString() || ''"
                           :placeholder="permittedAnswer.renderingConfig.placeholder !== null ? permittedAnswer.renderingConfig.placeholder : ''"
                           :rules="permittedAnswer.renderingConfig.validationRules" />
    </template>

    <!-- Number Input -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.number">
      <NumberInputWithValidation v-model="syncValue[depth][`${question.questionId}-number`]"
                                 class="survey__number"
                                 :for="permittedAnswer.renderingConfig.vid"
                                 :label="$t(permittedAnswer.renderingConfig.labelKey).toString() || ''"
                                 :input-width="permittedAnswer.renderingConfig.range.max > 100 ? $zb.enums.FormElWidthEnum.qFormEl300 : $zb.enums.FormElWidthEnum.qFormEl125"
                                 :max="permittedAnswer.renderingConfig.range?.max"
                                 :min="permittedAnswer.renderingConfig.range?.min"
                                 :placeholder="permittedAnswer.renderingConfig.range.min + ' - ' + permittedAnswer.renderingConfig.range.max"
                                 :step="permittedAnswer.renderingConfig.range.scale ?? 1" />
      <div class="help-text">{{ typeof (syncValue[depth][`${question.questionId}-number`]) == 'number' ? '&nbsp;' :
    $t('survey_range_tap') }}</div>
    </template>
    
    <!-- Select Input -->
    <template v-if="permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.dropdown">
      <SelectWithValidation v-model="syncValue[depth][`${permittedAnswer.answerId}-dropdown`]"
                            :default-option-text="permittedAnswer.renderingConfig.dropdownText"
                            option-label="text"
                            :options="permittedAnswer.renderingConfig.dropdownItems"
                            option-value="value" />
    </template>
    
    <!-- Sub-Choice -->
    <TheSurveyMultipleChoice v-show="showOptions"
                             v-for="subChoice in permittedAnswer.subAnswers"
                             v-model="syncValue"
                             :class="[{ 'last-tier': !subChoice.subAnswers }]"
                             :depth="depth + 1"
                             :key="subChoice.answerId"
                             :permittedAnswer="subChoice"
                             :question="question"
                             ref="multipleChoiceSubAnswers" />
  </div>
</template>

<style scoped lang="scss">
.survey__answer {
  $survey-answer-option-brdr: 1px solid $border-clr;
  
  // Option
  > .survey__answer-option:not(.footer),
  .survey__answer-group > .survey__answer-option {
    padding-block: 20px;
    border-block-end: $survey-answer-option-brdr;
  }

  .survey__answer-option:first-child,
  .survey__answer-group > .survey__answer-option:first-child,
  .survey__answer-group h4 + .survey__answer-option {
    border-block-start: $survey-answer-option-brdr;
  }

  > .survey__answer-option .survey__answer-option,
  .survey__answer-group > .survey__answer-option .survey__answer-option {
    padding-block-end: 15px;

    &.last-tier {
      padding-inline-start: 50px;
    }
  }

  // Group

  &-group {
    margin-block-end: 25px;


    // Heading
    .survey__answer-heading {
      color: $body-txt-clr;
    }

    .survey__answer-label {
      font-size: map-get($font-sizes, '22');
      margin-block-start: 25px;
    }
  }
}

.help-text {
  font-style: italic;
  font-weight: map-get($text-weights, bold);
  font-size: 26px;
  line-height: 56px;
  color: map-get($co-brand-clrs, primitives-foundation-50);
  text-align: center;
}
</style>
<script setup lang="ts">
import {
  defineModel,
  defineProps,
  type PropType
} from 'vue';
import {DialogType} from '@/constants/enums';

const props = defineProps({
  class: {
    type: String
  },
  closeButton: {
    type: Boolean,
    default: false
  },
  noBackdropDismiss: {
    type: Boolean,
    default: false
  },
  noEscDismiss: {
    type: Boolean,
    default: false
  },
  noRefocus: {
    type: Boolean,
    default: true
  },
  noRouteDismiss: {
    type: Boolean,
    default: false
  },
  persistent: {
    type: Boolean,
    default: false
  },
  type: {
    type: String as PropType<DialogType>,
    default: DialogType.default
  }
});

const showDialog = defineModel({
  type: Boolean,
  required: true
});
</script>

<template>
  <q-dialog v-model="showDialog"
            :class="[props.class, props.type]"
            :no-backdrop-dismiss="props.noBackdropDismiss"
            :no-esc-dismiss="props.noEscDismiss"
            :no-refocus="props.noRefocus"
            :no-route-dismiss="props.noRouteDismiss"
            :persistent="props.persistent">
    <div>
      
      <!-- Close (X) Button -->
      <zbm-btn-icon v-if="props.closeButton"
                    @click="showDialog = !showDialog"
                    class="close"
                    color="close"
                    icon="far fa-times"
                    size="20px"
                    :square="true"
                    text-color="body" />
      
      <!-- Dialog Content -->
      <slot />
    </div>
  </q-dialog>
</template>

<style scoped lang="scss">
.bg-close {
  background: $nav-separator-clr;
}
</style>
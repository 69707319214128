<script setup lang="ts">
import {useDashboardStore} from 'src/store';
import {computed} from 'vue';
import Box from 'src/components/BoxContainer.vue';

const dashboardStore = useDashboardStore();

const isLoading = computed(() => !dashboardStore.patient);
</script>

<template>
  <zbm-skeleton-loader height="578px"
                       :loading="isLoading"
                       width="100%">
    <Box :boxPadding="$zb.enums.BoxPaddingEnum.p2018"
         class="mobile-app-promo">
      
      <!-- Title -->
      <h5>{{ $t('pwe_mymobility_mobile_app') }}</h5>
      
      <!-- Copy -->
      <p>{{ $t('pwe_sidebar_app_info') }}</p>
      
      <!-- Illustration -->
      <img src="/img/mobile-illustration.png" alt="mymobility" />
      
      <!-- Get iOS App Button -->
      <zbm-btn class="get-ios mb-20"
               :color="$zb.enums.ButtonStdColorEnum.accentText"
               :height="$zb.enums.ButtonHeightEnum.md"
               href="https://apps.apple.com/us/app/zb-mymobility/id1438566065"
               icon="fab fa-apple"
               :label="$t('pwe_get_ios_app')"
               target="_blank"
               :width="$zb.enums.ButtonWidthEnum.wFull" />
      
      <!-- Get Android App Button -->
      <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText"
               :height="$zb.enums.ButtonHeightEnum.md"
               href="https://play.google.com/store/apps/details?id=com.mymobility"
               icon="fab fa-android"
               :label="$t('pwe_get_android_app')"
               target="_blank"
               :width="$zb.enums.ButtonWidthEnum.wFull" />
    </Box>
  </zbm-skeleton-loader>
</template>

<style lang="scss" scoped>
.mobile-app-promo { // .box
  line-height: 0;
  border: 1px solid $border-clr;
  
  p {
    line-height: $body-line-height;
  }
  
  // Illustration
  img {
    width: 100%;
    height: auto;
    margin-block-end: 60px;
  }
  
  // Buttons
  .q-btn {
    padding-inline: 18px;
    
    :deep(.on-left) {
      margin-inline-end: 12px;
    }
    
    // Get iOS App
    &.get-ios {
      padding-block: 7px;

      :deep(.fa-apple) {
        font-size: 24px;
      }
    }
    
    // Get Android App
    :deep(.fa-android) {
      font-size: 20px;
    }
  }
}
</style>
import { localizedNumberValue } from 'src/utils/stringUtils';
import { localizedStatDate } from 'src/utils/dateUtil';
import { determineTrend } from 'src/utils/interfaceUtils';
import type { PatientPiqStatisticTypeModel, PatientStatisticModel, PatientWearStatisticTypeModel } from 'src/types/model';
import type { PatientStatisticsResponse } from 'src/types/webContracts'
import { MeasurementTypeEnum, PeriodTypEnum } from 'src/constants/measurementTypes';
import { useLocalizationStore } from '@/store';

function emptyStatDetails(): PatientStatisticModel {
  return {
    headingTotal: undefined,
    headingDate: undefined,
    lastMonth: undefined,
    lastWeek: undefined,
    avgPerDay: undefined,
    avgPerDayTrending: null,
    fiveDayAvg: undefined,
    fiveDayAvgTrending: null,
    rankedComparison: null
  }
}

export function toPiqStatSummary(response: PatientStatisticsResponse) {
  const localizationStore = useLocalizationStore();

  const result: PatientPiqStatisticTypeModel = {
    loaded: response.patientId > 0,
    piqSteps: emptyStatDetails() as PatientStatisticModel,
    piqDistance: emptyStatDetails() as PatientStatisticModel,
    piqRom: emptyStatDetails() as PatientStatisticModel,
    piqSpeed: emptyStatDetails() as PatientStatisticModel,
    piqStride: emptyStatDetails() as PatientStatisticModel,
    piqCadence: emptyStatDetails() as PatientStatisticModel,
  }

  const stepsLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqMaxSteps && p.periodType === PeriodTypEnum.LastUpdate)
  const stepsOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqMaxSteps && p.periodType === PeriodTypEnum.OneWeekAvg)
  const stepsOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqMaxSteps && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (stepsLastUpdate) {
    result.piqSteps.headingDate = localizedStatDate(stepsLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqSteps.headingTotal = localizedNumberValue(stepsLastUpdate?.dataValue as number);
  result.piqSteps.lastWeek = localizedNumberValue(stepsOneWeekAvg?.dataValue as number);
  result.piqSteps.lastMonth = localizedNumberValue(stepsOneMonthAvg?.dataValue as number);
  result.piqSteps.rankedComparison = stepsLastUpdate?.rankedComparison

  const distanceLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgDistance && p.periodType === PeriodTypEnum.LastUpdate)
  const distanceOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgDistance && p.periodType === PeriodTypEnum.OneWeekAvg)
  const distanceOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgDistance && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (distanceLastUpdate) {
    result.piqDistance.headingDate = localizedStatDate(distanceLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqDistance.headingTotal = localizedNumberValue(distanceLastUpdate?.dataValue as number);
  result.piqDistance.lastWeek = localizedNumberValue(distanceOneWeekAvg?.dataValue as number);
  result.piqDistance.lastMonth = localizedNumberValue(distanceOneMonthAvg?.dataValue as number);

  const romLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgKneeROM && p.periodType === PeriodTypEnum.LastUpdate)
  const romOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgKneeROM && p.periodType === PeriodTypEnum.OneWeekAvg)
  const romOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgKneeROM && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (romLastUpdate) {
    result.piqRom.headingDate = localizedStatDate(romLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqRom.headingTotal = localizedNumberValue(romLastUpdate?.dataValue as number);
  result.piqRom.lastWeek = localizedNumberValue(romOneWeekAvg?.dataValue as number);
  result.piqRom.lastMonth = localizedNumberValue(romOneMonthAvg?.dataValue as number);
  result.piqRom.rankedComparison = romLastUpdate?.rankedComparison

  const speedLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgWalkSpeed && p.periodType === PeriodTypEnum.LastUpdate)
  const speedOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgWalkSpeed && p.periodType === PeriodTypEnum.OneWeekAvg)
  const speedOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgWalkSpeed && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (speedLastUpdate) {
    result.piqSpeed.headingDate = localizedStatDate(speedLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqSpeed.headingTotal = localizedNumberValue(speedLastUpdate?.dataValue as number);
  result.piqSpeed.lastWeek = localizedNumberValue(speedOneWeekAvg?.dataValue as number);
  result.piqSpeed.lastMonth = localizedNumberValue(speedOneMonthAvg?.dataValue as number);

  const strideLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgStrideLength && p.periodType === PeriodTypEnum.LastUpdate)
  const strideOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgStrideLength && p.periodType === PeriodTypEnum.OneWeekAvg)
  const strideOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqAvgStrideLength && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (strideLastUpdate) {
    result.piqStride.headingDate = localizedStatDate(strideLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqStride.headingTotal = localizedNumberValue(strideLastUpdate?.dataValue as number);
  result.piqStride.lastWeek = localizedNumberValue(strideOneWeekAvg?.dataValue as number);
  result.piqStride.lastMonth = localizedNumberValue(strideOneMonthAvg?.dataValue as number);

  const cadenceLastUpdate = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqCadence && p.periodType === PeriodTypEnum.LastUpdate)
  const cadenceOneWeekAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqCadence && p.periodType === PeriodTypEnum.OneWeekAvg)
  const cadenceOneMonthAvg = response.details.find(p => p.dataType == MeasurementTypeEnum.PiqCadence && p.periodType === PeriodTypEnum.OneMonthAvg)
  if (cadenceLastUpdate) {
    result.piqCadence.headingDate = localizedStatDate(cadenceLastUpdate.createdDate, localizationStore.locale);
  }
  result.piqCadence.headingTotal = localizedNumberValue(cadenceLastUpdate?.dataValue as number);
  result.piqCadence.lastWeek = localizedNumberValue(cadenceOneWeekAvg?.dataValue as number);
  result.piqCadence.lastMonth = localizedNumberValue(cadenceOneMonthAvg?.dataValue as number);

  return result;
}

export function toWearStatSummary(response: PatientStatisticsResponse) {
  const result: PatientWearStatisticTypeModel = {
    wearSteps: emptyStatDetails() as PatientStatisticModel,
    wearStand: emptyStatDetails() as PatientStatisticModel,
    wearFlights: emptyStatDetails() as PatientStatisticModel,
  }

  result.wearSteps.headingTotal = localizedNumberValue(response.details[0].dataValue);
  result.wearSteps.avgPerDay = localizedNumberValue(response.details[1].dataValue);
  result.wearSteps.avgPerDayTrending = determineTrend(response.details[1].deltaFromPrevious);
  result.wearSteps.fiveDayAvg = localizedNumberValue(response.details[2].dataValue);
  result.wearSteps.fiveDayAvgTrending = determineTrend(response.details[2].deltaFromPrevious);

  result.wearStand.headingTotal = localizedNumberValue(response.details[3].dataValue);
  result.wearStand.avgPerDay = localizedNumberValue(response.details[4].dataValue);
  result.wearStand.avgPerDayTrending = determineTrend(response.details[4].deltaFromPrevious);
  result.wearStand.fiveDayAvg = localizedNumberValue(response.details[5].dataValue);
  result.wearStand.fiveDayAvgTrending = determineTrend(response.details[5].deltaFromPrevious);

  result.wearFlights.headingTotal = localizedNumberValue(response.details[6].dataValue);
  result.wearFlights.avgPerDay = localizedNumberValue(response.details[7].dataValue);
  result.wearFlights.avgPerDayTrending = determineTrend(response.details[7].deltaFromPrevious);
  result.wearFlights.fiveDayAvg = localizedNumberValue(response.details[8].dataValue);
  result.wearFlights.fiveDayAvgTrending = determineTrend(response.details[8].deltaFromPrevious);

  return result;
}
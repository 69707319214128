import {
  AnswerTypeEnum,
  QuestionCategoryResponseEnum,
  SurveyComponentTypeEnum,
  SurveyTypeEnum,
} from 'src/constants/survey';
import type {
  SurveyPermittedAnswerModel,
  SurveyPermittedAnswerRenderingConfigModel,
} from 'src/types/model';
import type {
  DropdownItem,
  SurveyDefinitionWrapperResponse,
  SurveyPermittedAnswerResponse,
  SurveyQuestionResponse
} from 'src/types/webContracts';
import { i18n } from 'src/plugins/i18n'
import { useLocalizationStore } from 'src/store';
import * as Validators from '@/plugins/quasar-validators'
import type { ValidationRule } from 'quasar';

export function toSurveyPermittedAnswerModel(
  parentSurveyPermittedAnswerModel: SurveyPermittedAnswerModel | undefined,
  permittedAnswers: SurveyPermittedAnswerResponse[],
  question: SurveyQuestionResponse,
  response: SurveyDefinitionWrapperResponse): SurveyPermittedAnswerModel[] {

  const painRatingSurveyTypes = [
    SurveyTypeEnum.Pain_and_Opioid_Pre_Op,
    SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ]

  const permittedAnswerModels: SurveyPermittedAnswerModel[] = []
  const { questionCategory } = question
  const surveyType = response.survey.surveyType

  for (let i = 0; i < permittedAnswers.length; i++) {
    const { id, name, answerType } = permittedAnswers[i]
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const renderingConfig = mapPermittedAnswerRenderingBehavior(parentSurveyPermittedAnswerModel, permittedAnswers[i], question, response)

    const model = <SurveyPermittedAnswerModel>{
      answerId: id,
      name,
      renderingConfig,
    }

    if (parentSurveyPermittedAnswerModel) {
      model.parentAnswerId = parentSurveyPermittedAnswerModel.answerId
    }

    //behavior: recurses conditionally to build answer hierarchies
    //reason: certain question types use hierarchies to conditonally display qualifying choices to a previous choice
    if (questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseMany
      || questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseOne
      || questionCategory === QuestionCategoryResponseEnum.MultipleManyChoiceType
      || questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseOneSingleNest
      || questionCategory === QuestionCategoryResponseEnum.MultipleChoice) {

      const recursiveTypes = [AnswerTypeEnum.Heading.toString(), AnswerTypeEnum.DetailHeading.toString()]

      if (recursiveTypes.includes(answerType)) {
        //take a copy of the items except for the current one, it should be included in any recursive calls to build its children
        const slicedWithoutCurrentItem = permittedAnswers.slice(i + 1);
        //find the point in the flat array where another parent element of the same answerType begins, this is the end of the children for the current item
        //ExclusiveNone is a special case that will have no paired marker with it as it appears at the end of the list
        let endSliceIndex = slicedWithoutCurrentItem.findIndex(p => p.answerType === answerType
          || p.answerType === AnswerTypeEnum.ExclusiveNone
          || p.answerType === AnswerTypeEnum.Item)

        //behavior: performs a lookback when it encounter the special case of ExclusiveNone or Item to see if a Heading type answer appeared before it, if so make the 
        //          ExclusiveNone/Item answer a child of the Heading
        //reason for ExclusiveNone: Yet another specific data scenario with ExclusiveNone that is unique to the join surgeries page of the PMH survey. It should appear under a parent
        //        checkbox titled Other that has an answerType of Heading
        //reason for Item: There isn't a good way to terminate a nested answer right now so we're just hard-coding it 😞
        const lastItemInSlice = slicedWithoutCurrentItem[endSliceIndex]
        if ((lastItemInSlice?.answerType === AnswerTypeEnum.ExclusiveNone ||
          (questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseOneSingleNest && lastItemInSlice?.answerType === AnswerTypeEnum.Item)) &&
          permittedAnswers[i].answerType === AnswerTypeEnum.Heading) {
          endSliceIndex = permittedAnswers.indexOf(lastItemInSlice)
        }

        //guard against bad configuration, handle empty children case
        const sliceLength = endSliceIndex > -1 ? i + endSliceIndex + 1 : undefined

        //slice of just the flattened list of items that the current element is an anscestor of
        const flatSubAnswers = permittedAnswers.slice(i + 1, sliceLength)

        //recurse and process items of this current anscestor
        model.subAnswers = toSurveyPermittedAnswerModel(model, flatSubAnswers, question, response)
        //skip past items that this current answer is an anscestor as they have been converted into a hierarchy under `subAnswers`
        i += flatSubAnswers.length
      } else if (painRatingSurveyTypes.includes(surveyType)) {
        //behavior: add a subanswer that will be a textbox
        //reason: Pain Rating surveys have a subanswer UI behavior for AllowOther types to render a text box underneath the parent checkbox
        //        but the data model doesn't represent the child answer as a real answer.
        if (answerType === AnswerTypeEnum.AllowOther) {
          model.subAnswers = toSurveyPermittedAnswerModel(model, [{
            allowOther: true,
            id: 0,
            answerType: AnswerTypeEnum.None,
            name: 'add comment',
            range: null,
            sequence: 10,
            value: '',
          }], question, response)
        }
      }
    }

    permittedAnswerModels.push(model)
  }

  return permittedAnswerModels
}

function mapPermittedAnswerRenderingBehavior(
  parentSurveyPermittedAnswerModel: SurveyPermittedAnswerModel | undefined,
  permittedAnswer: SurveyPermittedAnswerResponse,
  question: SurveyQuestionResponse,
  response: SurveyDefinitionWrapperResponse): SurveyPermittedAnswerRenderingConfigModel {



  const checboxAnswerTypes = [
    AnswerTypeEnum.DetailHeading,
    AnswerTypeEnum.Item,
    AnswerTypeEnum.ExclusiveNone,
  ]
  const notRecordedAnswerTypes = [
    AnswerTypeEnum.DetailHeading,
    AnswerTypeEnum.ExclusiveNone,
    AnswerTypeEnum.Heading,
    AnswerTypeEnum.Footer,
    AnswerTypeEnum.SubHeading,
  ]
  const intValueTypes = [
    AnswerTypeEnum.IntValue,
    AnswerTypeEnum.SubIntValue
  ]
  const painRatingSurveyTypes = [
    SurveyTypeEnum.Pain_and_Opioid_Pre_Op,
    SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ]

  const { questionId, questionCategory, canSkip } = question
  const { id: answerId, answerType, sequence, allowOther } = permittedAnswer
  let { range } = permittedAnswer
  const surveyType = response.survey.surveyType

  const exclusiveChoice = answerType === AnswerTypeEnum.ExclusiveNone
  const validationRules: Array<ValidationRule> = []
  let isDeclining = false
  let validateChildrenIfValueMatches = false
  let isRecorded = false
  let type = SurveyComponentTypeEnum.unknown
  let labelKey = ''
  let placeholder: string | null = null
  let dropdownItems: Array<DropdownItem> | null = null;
  let dropdownText: string | undefined = undefined;

  const localizationStore = useLocalizationStore()
  const isImperial = localizationStore.locale.toLowerCase().includes('us');

  //behavior: calcuate what type of ui component should be rendered {checkbox, textbox, radio button, label }
  //reason: There are many different flavors of input types and this section handles those various cases in determining what to display
  if (answerType === AnswerTypeEnum.Footer) {
    //behavior: will appear as italized text 
    type = SurveyComponentTypeEnum.footer
  }
  else if (answerType == AnswerTypeEnum.Height) {
    type = SurveyComponentTypeEnum.dropdown

    const longNameKey = isImperial ? 'unit_plural_name_distance_feet_imperial' : 'unit_plural_name_distance_centimeters_metric'
    const primaryLengthKey = isImperial ? 'unit_abbrev_distance_feet_imperial' : 'unit_abbrev_distance_centimeter_metric'
    const secondaryLengthKey = isImperial ? 'unit_abbrev_distance_inch_imperial' : ''
    response.heightOptions?.forEach(opt => opt.text = opt.text.replace('{0}', i18n.global.t(primaryLengthKey).toString()).replace('{1}', i18n.global.t(secondaryLengthKey).toString()))

    dropdownItems = response.heightOptions
    dropdownText = `${permittedAnswer.name} (${i18n.global.t(longNameKey)})`
  }
  else if (answerType == AnswerTypeEnum.Weight) {
    type = SurveyComponentTypeEnum.dropdown
    const longNameKey = isImperial ? 'unit_plural_name_weight_pounds_imperial' : 'unit_plural_name_weight_kilograms_metric'
    const primaryMassKey = isImperial ? 'unit_abbrev_weight_pounds_imperial' : 'unit_abbrev_weight_kilogram_metric'

    response.weightOptions?.forEach(opt => opt.text = opt.text.replace('{0}', i18n.global.t(primaryMassKey).toString()))

    dropdownItems = response.weightOptions
    dropdownText = `${permittedAnswer.name} (${i18n.global.t(longNameKey)})`
  }
  else if (answerType === AnswerTypeEnum.SubHeading) {
    //behavior: will appear as plain text
    type = SurveyComponentTypeEnum.label
  }
  else if (questionCategory === QuestionCategoryResponseEnum.DataEntry) {
    type = SurveyComponentTypeEnum.textbox
    //reason: If the page cannot be skipped, then this field must be required
    if (!canSkip) {
      validationRules.push(Validators.required())
    }
  }
  else if (questionCategory === QuestionCategoryResponseEnum.MultipleChoice) {
    type = SurveyComponentTypeEnum.radio
  }
  else if (questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseOne) {
    //reason: These questions can be in hierachies such that selecting a parent checkbox displays a sub element radio button with qualifying answer choice
    if (checboxAnswerTypes.includes(answerType)) {
      type = SurveyComponentTypeEnum.checkbox
    } else if (answerType === AnswerTypeEnum.SubItem) {
      type = SurveyComponentTypeEnum.radio
    }
  }

  else if (questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseMany) {
    if (checboxAnswerTypes.includes(answerType)) {
      type = SurveyComponentTypeEnum.checkbox

      //behavior: stores a value that if found to match the user's choice, will enforce validating the elements children in its hierarchy
      //reason: there are cases where a user will select a checkbox, which makes a child element visible and must be validated only if it is visible
      if (answerType === AnswerTypeEnum.DetailHeading) {
        validateChildrenIfValueMatches = true
      }
    } else if (answerType === AnswerTypeEnum.SubItem) {
      type = SurveyComponentTypeEnum.radio
    } else if (answerType === AnswerTypeEnum.SubIntValue) {
      type = SurveyComponentTypeEnum.textbox
      placeholder = permittedAnswer.name

      //reason: configures the element to be required if it belongs under a parent element. However, 'validateChildrenIfValueMatches' controls 
      //        whether or not this validation is triggered.
      if (parent) {
        validationRules.push(Validators.required())
      }
    } else if (answerType === AnswerTypeEnum.Heading) {
      type = SurveyComponentTypeEnum.heading
    }
  }
  else if (questionCategory === QuestionCategoryResponseEnum.MultipleChoiceChooseOneSingleNest) {
    type = SurveyComponentTypeEnum.radio

    //behavior: stores a value that if found to match the user's choice, will enforce validating the elements children in its hierarchy
    //reason: there are cases where a user will select a radio, which makes a child element visible and must be validated only if it is visible
    if (answerType === AnswerTypeEnum.DetailHeading) {
      validateChildrenIfValueMatches = true
    } else if (answerType === AnswerTypeEnum.SubIntValue || answerType === AnswerTypeEnum.SubStringValue) {
      type = SurveyComponentTypeEnum.textbox
      placeholder = permittedAnswer.name

      //reason: configures the element to be required if it belongs under a parent element. However, 'validateChildrenIfValueMatches' controls 
      //        whether or not this validation is triggered.
      if (parent) {
        validationRules.push(Validators.required())
      }
    } else if (answerType === AnswerTypeEnum.Heading) {
      type = SurveyComponentTypeEnum.heading
    }
  }
  else if (answerType === AnswerTypeEnum.None && questionCategory === QuestionCategoryResponseEnum.NumericRangeFAAM) {
    type = SurveyComponentTypeEnum.number
    validationRules.push(Validators.required())
  }
  else if (painRatingSurveyTypes.includes(surveyType)) {
    if (answerType === AnswerTypeEnum.None) {
      type = SurveyComponentTypeEnum.checkbox

      if (permittedAnswer.allowOther) {
        type = SurveyComponentTypeEnum.textbox
        labelKey = 'checkin_add_comment'
        range =
        {
          max: 255,
        }
      }
    } else if (answerType === AnswerTypeEnum.AllowOther) {
      type = SurveyComponentTypeEnum.checkbox
    }
  }
  else if (answerType === AnswerTypeEnum.DeclineToComplete) {
    type = SurveyComponentTypeEnum.button
    isDeclining = true;
  }


  //behavior: Answer won't be recorded as it is treated since it isn't modeled by the api (no id). Instead, its answer will be hoisted into the parent's free text field value
  //reason: AllowOther requires a UI child answer for "add comment" to appear undearneath "Other issue" checkbox that isn't modeled. 
  //        Its value should be attached to the parent answer's free text field when submitted.
  const hoistAnswerToParentFreeResponseField = !answerId && allowOther

  //behavior: will determine whether or not an answer should be submitted to the api
  //reason: some parent/child/grandchildren hierarchies should not  submit the parent/child answers either because the answer is implied by
  //        the presence of the grandchild answer or because the answer is a label which shouldn't be submitted.
  isRecorded = !notRecordedAnswerTypes.includes(answerType) || exclusiveChoice

  if (validationRules.every(r => r !== Validators.number())) {
    if (intValueTypes.includes(answerType))
    {
      validationRules.push(Validators.number())
    }
  }

  //behavior: will enforce min/max validation on elements
  if (range) {
    if (answerType == AnswerTypeEnum.TextNoSpecialCharsValue || answerType === AnswerTypeEnum.SubStringValue || answerType === AnswerTypeEnum.None) {
      validationRules.push(Validators.minLen(range.min ?? 0))
      validationRules.push(Validators.maxLen(range.max ?? 255))
    }
    else if (typeof range.min === 'number' && typeof range.max === 'number') {
      validationRules.push(Validators.betweenLen(range.min, range.max))
    }
    else {
      validationRules.push(Validators.minVal(range.min))
      validationRules.push(Validators.maxVal(range.max))
    }
  }

  //behavior: Used to associate `for` field for q-inputs
  const vid = `${answerId}-${type}`

  //behavior: creates a unique identifier that is used to identify a group of elements that have a relationship
  //reason: radio button groups use this as their name to preserve selecting behavior on radio button groups. 
  //        additionally, when you bind radio button groups, each button's v-model binding must map to the some state property to function
  let groupIdentifier = `group-${questionId}`
  if (parentSurveyPermittedAnswerModel) {

    //reason: when hierachies are involved, multiple permitted answers will share the same questionId, so ensure the field is unique by
    //        appending the permitted answer's id to the name
    groupIdentifier += `-${parentSurveyPermittedAnswerModel.answerId}`
  }

  return {
    type,
    vid,
    groupIdentifier,
    validationRules,
    validateChildrenIfValueMatches,
    isRecorded,
    hoistAnswerToParentFreeResponseField,
    order: sequence,
    exclusiveChoice,
    labelKey,
    placeholder,
    range: {
      ...range
    },
    dropdownItems,
    dropdownText,
    isDeclining
  }
}
<template>
  <Plotly :data="dataConfig" :layout="layoutConfig" ref="graph"></Plotly>
</template>

<script setup lang="ts">
import { computed, defineProps, type PropType, ref } from 'vue';

import Plotly from 'src/components/vue-plotly/Plotly.vue';
import type { Data, PlotData, Layout, Shape } from 'plotly.js';
import { dynamicCeiling } from 'src/utils/mathUtil';

const props = defineProps({
  dateOfSurgery: {
    type: String,
  },
  plotData: {
    type: Array as PropType<Data[]>,
    default: () => [],
  },
  height: {
    type: Number,
    default: 400,
  },
  unitLabel: {
    type: String,
    default: '',
  },
});

const graph = ref();

function yRange(min: number, max: number): [number, number] {
  return [0, max || 100];
}

const dataConfig = computed<Data[]>(() => {
  return props.plotData.map(
    (pd) =>
    ({
      type: 'bar',
      fillcolor: '#acd3e866',
      hoverlabel: {
        bgcolor: '#acd3e866',
        bordercolor: '#007BBC',
        font: {
          family: 'Noto Sans, sans-serif',
          color: '#5f5f5f',
        },
      },
      marker: {
        color: '#00A79D',
      },
      hovertemplate: '%{y}' + props.unitLabel + '<br>' + '%{x}' + '<extra></extra>',
      ...pd,
    } as PlotData)
  );
});

const layoutConfig = computed<Partial<Layout>>(() => {
  const allYData = props.plotData
    .map((value) => (value as Partial<PlotData>).y)
    .flat() as number[];

  const minValueY = Math.min(...allYData);
  const maxValueY = Math.max(...allYData);

  const newMax = dynamicCeiling(maxValueY);
  const [minY, maxY] = yRange(minValueY, newMax);

  const shapes: Partial<Shape>[] = [];
  if (props.dateOfSurgery) {
    shapes.push({
      type: 'line',
      xref: 'x',
      yref: 'y',
      x0: props.dateOfSurgery,
      x1: props.dateOfSurgery,
      y0: minY,
      y1: maxY,
      line: {
        color: '#E0E0E0',
        width: 1,
      },
    });
  }

  return {
    hovermode: 'closest',
    margin: {
      t: 12,
      b: 0,
      l: 0,
      r: 0,
      pad: 10,
    },
    height: props.height,
    shapes,
    xaxis: {
      fixedrange: true,
      showticklabels: true,
      showgrid: false,
      showspikes: false,
      type: 'category',
      automargin: true,
    },
    yaxis: {
      autorange: false,
      showgrid: false,
      fixedrange: true,
      range: [minY, maxY],
      nticks: 3,
      type: 'linear',
      tickfont: {
        family: 'NotoSans, sans-serif',
        size: 14,
        color: '#5f5f5f',
      },
      zerolinewidth: 1,
      color: '#E0E0E0',
      automargin: true,
    },
  };
});


defineExpose({
  init() {
    graph.value.init()
  },
  update() {
    graph.value.update()
  },
  reset() {
    if (graph.value) {
      graph.value.reset()
    }
  }
})
</script>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import {
  useActivationStore,
  useLocalizationStore
} from 'src/store';
import {
  computed,
  onMounted,
  ref
} from 'vue';
import { Workflow } from 'src/constants/enums';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import moment from 'momentCultured';
const router = useRouter();

const activationStore = useActivationStore()
const localizationStore = useLocalizationStore()

const forgotUsernameForm = ref();

const countryCode = ref(''),
  dateOfBirth = ref(''),
  isLoading = ref(true),
  phoneTelNumberInputModel = ref({
    value: '',
    phoneObject: {}
  });

const countryOptions = computed(() => activationStore.supportedCountries.countryOptions),
  derivedCountryCode = computed(() => localizationStore.region);

async function onNextClick() {
  const isValid = await forgotUsernameForm.value?.validate();

  if (isValid) {
    router.push({
      name: 'ConfirmIdentity',
      query: {
        workflow: Workflow.forgotUsername.toString(),
        backRoute: 'ForgotUsername',
        nextRoute: 'Login',
        progress: '100',
        phoneCountryCode: phoneTelNumberInputModel.value.phoneObject.countryCallingCode,
        phoneNumber: phoneTelNumberInputModel.value.phoneObject.nationalNumber,
        country: countryCode.value,
        dateOfBirth: dateOfBirth.value
      },
    });
  }
}

onMounted(async () => {
  countryCode.value = activationStore.supportedCountries.selectedCountryCode;

  document.querySelectorAll('html')[0].scrollTop = 0;
  (document.querySelectorAll('input')[0] as HTMLElement).focus();

  isLoading.value = false;
});
</script>

<template>
  <q-page class="not-auth-header forgot-username" padding>
    <AuthenticateLayout :loading="isLoading" withHeader>

      <!-- Heading -->
      <template #heading>{{ $t('forgot_username') }}</template>

      <!-- View Unique Content -->
      <template #default>

        <!-- Instructions -->
        <p class="font-size-20 mb-30">
          <zbm-skeleton-loader height="30px" :loading="isLoading" width="100%">
            {{ $t('forgot_username_title') }}
          </zbm-skeleton-loader>
        </p>

        <!-- Form -->
        <q-form @submit.prevent="onNextClick" ref="forgotUsernameForm">

          <!-- Phone Number -->
          <div class="row form-row">
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-telephone-with-validation v-model="phoneTelNumberInputModel"
                  :patient-country-code="derivedCountryCode" :placeholder="$t('activate_phone_number')"
                  :rules="[$zb.validators.phoneRequired()]" />
              </zbm-skeleton-loader>
            </div>
          </div>

          <!-- Date of Birth -->
          <div class="row form-row">
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-date-picker v-model="dateOfBirth" :placeholder="$t('activate_date_of_birth')"
                  :rules="[$zb.validators.required()]" :maxDate="moment().toDate()"/>
              </zbm-skeleton-loader>
            </div>
          </div>

          <!-- Country Selector -->
          <div class="row items-center country">

            <!-- Label -->
            <div class="col-auto font-size-20 text-no-wrap">
              <zbm-skeleton-loader height="30px" :loading="isLoading" width="107px">
                {{ $t('provider_select_country_button') }}
              </zbm-skeleton-loader>
            </div>
            <div class="flex-br" />

            <!-- Select -->
            <div class="col">
              <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" height="40px" :loading="isLoading">
                <zbm-select-with-validation v-model="countryCode" :name="$t('provider_select_country_button')"
                  option-label="text" :options="countryOptions" option-value="value"
                  :rules="[$zb.validators.required()]" />
              </zbm-skeleton-loader>
            </div>
          </div>
        </q-form>
      </template>

      <!-- Actions/Progress -->
      <template #actions>
        <ProgressBar @on-next-click="onNextClick" backRoute="Login" :loading="isLoading" :nextEnabled="true"
          :progress="50" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<style lang="scss" scoped>
.forgot-username {
  padding-inline: 0;

  @media (min-width: 600px) {

    // Form
    .q-form {

      // Country Selector
      .country {

        .flex-br {
          display: none;
        }

        [class*=col]:first-child {
          padding-inline-end: 10px;
        }
      }
    }
  }
}
</style>
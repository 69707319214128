import { SurveyTypeEnum } from 'src/constants/survey'
import { SurveyDefinitionModel, SurveyPageModel } from 'src/types/model'

export function emptySurveyDefinition(): SurveyDefinitionModel {
  return {
    surveyId: 0,
    surveyType: SurveyTypeEnum.Unknown,
    name: '',
    description: '',
    header: '',
    footer: '',
    pages: []
  }
}

export function emptySurveyPageModel(): SurveyPageModel {
  return {
    pageId: 0,
    header: '',
    questions: [],
    renderingConfig: {
      hasButtons: false,
      order: 0,
      combineIntoSingleQuestion: false,
      hasCondition: false,
      hidePage: false,
    },
    footer: ''
  }
}
import { NamedPerson } from 'src/types/interfaces';

export function formatName(person: NamedPerson): string {
  if (!person.lastName) return '';
  const { prefix, firstName, lastName, suffix } = person;
  let result = `${firstName} ${lastName}`;
  if (prefix) result = `${prefix} ${result}`;
  if (suffix) result = `${result} ${suffix}`;
  return result;
}

export function formatAbbreviatedOrientation(orientation?: string): string {
  switch (orientation) {
    case 'Left':
      return '(L)';
    case 'Right':
      return '(R)';
    case 'Bilateral':
      return '(B)';
    default:
      return '';
  }
}

export function formatSingularPlural(counter: number, singular: string, plural: string): string {
  return counter === 1 ? singular : plural
}

export function translateStepToRoute(nextStep?: string): string {
  switch (nextStep) {
    case 'PrivacyPolicy':
      return 'Activate.PrivacyPolicy';
    case 'AcceptEULA':
      return 'Activate.EULA';
    case 'CrossBorderDataTransfer':
      return 'Activate.CrossBorderDataTransfer'
    case 'GlobalConsent':
      return 'Activate.GlobalConsent';
    case 'UserName':
      return 'Activate.CreateUsername';
    case 'Password':
      return 'Activate.CreatePassword';
    case 'EmailPostalCode':
      return 'Activate.ProfileInformation';
    case 'RegistrationComplete':
      return 'Activate.Complete';
    case 'UploadPhoto':
      return 'Activate.ProfilePhoto'
    case 'SecurityQuestions_Unused':
    case 'UserNamePassword':
    case 'PhoneNumber':
    case 'Verification':
    default:
      return '';
  }
}

export function determineTrend(value: number | null): string {
  if (value === null) return '';

  if (value > 0) {
    return 'up';
  } else if (value < 0) {
    return 'down';
  } else {
    return '';
  }
}
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  vectorPaths: {
    type: String,
    required: true
  },
  viewBox: {
    type: String,
    required: true
  }
});
</script>

<template>
  <svg v-html="props.vectorPaths"
       fill="currentColor"
       :viewBox="props.viewBox"
       xmlns="http://www.w3.org/2000/svg" />
</template>

<style lang="scss" scoped></style>
<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">

      <!-- Title -->
      <template v-slot:title>{{ $t('settings') }}</template>

      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ $t('settings_help_support') }}</template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- Support Content -->
        <div class="row">
          <div class="col">
            <pre v-html="localizedPolicy.description" />

            <!-- Visit Support Button -->
            <p class="text-center">
              <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accentText" :height="$zb.enums.ButtonHeightEnum.lg"
                href="http://support.zbmymobilitysolutions.com/" :label="$t('settings_help_site')" target="_blank" />
            </p>
          </div>
        </div>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getLocalizedPolicy } from 'src/api/endpoints/registrationWeb';
import { UserPolicyType } from 'src/constants/enums';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import { type UserPolicy } from 'src/types/webContracts';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();

const localizedPolicy = ref<UserPolicy>({
  id: 0,
  header: '',
  description: '',
  type: '',
  dismissed: false,
  consented: false
});

onMounted(async () => {
  const response = await handleApiRequest(() =>
    getLocalizedPolicy(UserPolicyType.UserPolicySupport), null, $q
  );
  if (response?.result) {
    localizedPolicy.value = response.result;
  }
});

function onBackClick() {
  router.push({ name: 'Settings' });
}
</script>
<script lang="ts" setup>
// non-project
import axios from 'axios';
import { type PropType, onMounted, ref } from 'vue';

// project
import { handleApiRequest } from 'src/api/handleApiRequest';
import { sendActivationCode, verifyCode } from 'src/api/endpoints/userWeb';
import { useActivationStore, useGeneralPurposeModalStore } from 'src/store';
import { Workflow } from 'src/constants/enums';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import { getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { createActionModal } from 'src/utils/generalPurposeModalFactory';
import { useRouter } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { useQuasar } from 'quasar';
import moment from 'momentCultured';

const router = useRouter();
const activationStore = useActivationStore()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const $q = useQuasar();

const confirmIdentityForm = ref();
const requestGuid = ref('');
const textedIdentityCode = ref('');
const attemptsRemaining = ref(0);
const isLoading = ref(true);

const props = defineProps({
  backRoute: {
    type: String,
    default: '',
  },
  nextRoute: {
    type: String,
    default: '',
  },
  workflow: {
    type: Number as PropType<Workflow>,
    default: Workflow.forgotUsername,
  },
  username: {
    type: String,
    default: '',
  },
  phoneNumber: {
    type: String,
    default: '',
  },
  country: {
    type: String,
    default: '',
  },
  phoneCountryCode: {
    type: String,
    default: '',
  },
  progress: {
    type: Number,
    default: 0,
  },
  dateOfBirth: {
    type: String,
    default: '',
  }
});

onMounted(() => {
  handleSendCode();

  attemptsRemaining.value = 5;

  document.querySelectorAll('html')[0].scrollTop = 0;
  (document.querySelectorAll('input')[0] as HTMLElement).focus();

  isLoading.value = false;
});

async function onSubmit() {
  if (!textedIdentityCode.value) {
    return;
  }

  $q.loading.show();
  const model = {
    code: textedIdentityCode.value,
    requestGUID: requestGuid.value as string,
    workflow: props.workflow,
  };

  const response = await handleApiRequest(
    () => verifyCode(model),
    (error) => {
      attemptsRemaining.value--;

      if (attemptsRemaining.value === 0) {
        if (props.workflow.toString() == Workflow.registration.toString()) {
          router.replace({
            name: 'Activate.Start',
          });
        }
      }
    },
    $q
  );

  $q.loading.hide();

  if (response?.result) {
    const currentWorkFlow = props.workflow.toString();
    let nextStep = props.nextRoute as string;
    //forgot password forgot username
    if (
      currentWorkFlow == Workflow.forgotUsername.toString() ||
      currentWorkFlow == Workflow.forgotPassword.toString()
    ) {
      if (currentWorkFlow == Workflow.forgotUsername.toString()) {
        activationStore.setIsRecoveringUsername(true);
      }
      if (currentWorkFlow == Workflow.forgotPassword.toString()) {
        activationStore.setIsRecoveringPassword(true);
      }
    } else if (currentWorkFlow == Workflow.registration.toString()) {
      activationStore.setIsActivatingAccount(true);
      const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
      nextStep = translateStepToRoute(step?.result as string);

      //reason: if the next step comes back as complete, then this user has already been registered
      if (nextStep === 'Activate.Complete') {
        const info = createActionModal('', i18n.global.t('gen_restrict_login_alert').toString(), () => {
          router.replace({ name: 'Login' });
        });
        generalPurposeModalStore.setModal(info);
        $q.loading.hide();
        return;
      }
    }

    $q.loading.hide();
    router.replace({
      name: 'Authenticate',
      query: { returnRouteName: nextStep },
    });
  }
}

async function handleSendCode() {
  $q.loading.show();

  const dob = moment(props.dateOfBirth);
  const formattedDob = dob.isValid()
    ? dob.startOf('day').format('YYYY-MM-DD')
    : null;

  try {
    const model = {
      workflow: props.workflow,
      country: props.country as string,
      countryCode: props.phoneCountryCode as string,
      username: props.username as string,
      phone: props.phoneNumber,
      dateOfBirth: formattedDob
    };
    const result = await sendActivationCode(model);

    requestGuid.value = result ?? '';
    $q.loading.hide();
  } catch (error) {
    $q.loading.hide();
    if (axios.isAxiosError(error)) {
      $q.notify({ type: 'error', message: i18n.global.t(error.response?.data?.errorMessageKey).toString() })
    }
  }
}

</script>

<template>
  <q-page class="confirm-identity" padding>
    <AuthenticateLayout :loading="isLoading"
                        withHeader>
      
      <!-- Heading -->
      <template #heading>{{ $t('activate_confirm_identity_title') }}</template>
  
      <!-- View Unique Content -->
      <template #default>
        
        <!-- Instructions -->
        <p class="font-size-20 mb-30">
          <zbm-skeleton-loader height="30px"
                               :loading="isLoading"
                               width="100%">
            {{ $t('vue3_activate_confirm_identity_desc', [phoneNumber]) }}
          </zbm-skeleton-loader>
        </p>
  
        <!-- Form -->
        <q-form ref="confirmIdentityForm" @submit="onSubmit">

          <!-- Enter Code -->
          <div class="row">
            <div class="col">
              <zbm-skeleton-loader height="40px"
                                   :loading="isLoading"
                                   width="125px">
                <zbm-input-with-validation v-model="textedIdentityCode"
                  :input-width="$zb.enums.FormElWidthEnum.qFormEl125" :label="$t('activate_confirm_identity_code')"
                  :rules="[$zb.validators.required(), $zb.validators.number(), $zb.validators.minLen(6), $zb.validators.maxLen(6)]" />
              </zbm-skeleton-loader>
            </div>
          </div>
        </q-form>
        
        <!-- Try Again -->
        <div class="row confirm-identity__again">
          <div class="col">
            <p class="font-size-20">
              <zbm-skeleton-loader height="30px"
                                   :loading="isLoading"
                                   width="100%">
                {{ $t('activate_confirm_identity_resend_text') }}
              </zbm-skeleton-loader>
            </p>
            <zbm-skeleton-loader height="24px"
                                 :loading="isLoading"
                                 width="84px">
            <zbm-btn-link @click="handleSendCode"
                          class="text-weight-bold text-underline"
                          color="body"
                          :label="$t('activate_confirm_identity_resend_btn')" />
            </zbm-skeleton-loader>
          </div>
        </div>
        
      </template>
  
      <!-- Actions/Progress -->
      <template #actions>
        <ProgressBar @on-next-click="onSubmit"
                     :backRoute="backRoute"
                     :nextEnabled="true"
                     :progress="progress" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<style lang="scss" scoped>
.confirm-identity {
  
  // Form
  .q-form {
    margin-block-end: 67px;
  }
  
  // Try Again
  &__again {
    
    p {
      margin-block-end: 11px
    }
  }
}
</style>
<script setup lang="ts">
import { defineModel, type PropType } from 'vue';
import NameAvatar from '@/components/NameAvatar.vue';

const props = defineProps({
  avatarUrl: {
    type: String
  },
  hasAvatar: {
    type: Boolean,
    default: false
  },
  isPiqAndDisabled: {
    type: Boolean
  },
  myStatsRouteName: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  unreadMsgCt: {
    type: Number as PropType<number | null>
  }
});

const showMenu = defineModel({
  type: Boolean,
  required: true
});
</script>

<template>
  <q-menu v-model="showMenu" anchor="bottom start" auto-close class="header-nav" no-parent-event self="top start">
    <q-list>

      <!-- User -->
      <NameAvatar avatar-icon-font-size="22px" avatar-icon-top-margin="4px" avatar-size="28px"
        :avatar-url="props.avatarUrl" color="content-trg-secondary-def" class="q-item q-item--clickable user"
        :has-avatar="props.hasAvatar" href="#/Settings" is="a" margin="mr-22" :name="props.name" :on-right="true" />
      <q-separator />

      <!-- Home -->
      <q-item clickable :to="{ name: 'Dashboard.Home' }">
        <q-item-section>{{ $t('pwe_home') }}</q-item-section>
      </q-item>
      <q-separator />

      <!-- My Stats Button-->
      <q-item clickable :to="{ name: props.myStatsRouteName }">
        <q-item-section>{{ $t('stats') }}</q-item-section>
      </q-item>


      <template v-if="!props.isPiqAndDisabled">
        <q-separator />

        <!-- Education Button-->
        <q-item clickable :to="{ name: 'Dashboard.Education' }">
          <q-item-section>{{ $t('gen_education') }}</q-item-section>
        </q-item>
        <q-separator />

        <!-- Messages Button-->
        <q-item class="messages" clickable :to="{ name: 'Dashboard.Messages' }">

          <!-- Label -->
          <q-item-section>{{ $t('pwe_messages') }}</q-item-section>

          <!-- Count -->
          <q-item-section v-if="props.unreadMsgCt" avatar>
            <div class="unread-msg-ct">{{ props.unreadMsgCt }}</div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </q-menu>
</template>

<style scoped lang="scss"></style>
<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useActivationStore, useLocalizationStore } from 'src/store';
import { type CanaryPatientCaregiver } from 'src/types/webContracts';
import { getCanaryCaregiverDetail, updateCanaryCaregiverDetail } from 'src/api/endpoints/patientWeb';
import { getNextActivationRoute } from 'src/services/piqSetupService';
import { handleApiRequest } from 'src/api/handleApiRequest';

// components
import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import moment from 'momentCultured';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const localStore = useLocalizationStore();
const activationStore = useActivationStore();
const $q = useQuasar()

const defaulCountryCode = 'US';
const defaulCountryCallingCode = '1';
const patientCaregiver = ref<CanaryPatientCaregiver>(getDefaultCareGiver());
const formIsValid = ref<boolean>(true);
const maxDate: Date = moment().add(-18, 'year').toDate();

const careGiverForm = ref();
const birthdate = ref<Date | null>(null);
const countryList = computed(() => activationStore.supportedCountries);
const phoneNumber = ref({
  value: '',
  phoneObject: {}
});

const altPhoneNumber = ref({
  value: '',
  phoneObject: {}
});

const router = useRouter();

onMounted(async () => {
  $q.loading.show()
  await getCanaryCaregiverDetail()
    .then(async (response) => {
      const careGiver = await toCanaryPatientCaregiver(response?.data?.result);
      patientCaregiver.value = (careGiver && careGiver?.email && careGiver?.lastName) ? careGiver : getDefaultCareGiver();
    })
    .finally(() => {
      $q.loading.hide()
    });
});

// methods
async function onNextClick() {

  let isFormValid = await careGiverForm.value.validate();
  if (!isFormValid) return;


  // values from phone and date pickers
  patientCaregiver.value.phone = phoneNumber.value?.value;
  patientCaregiver.value.phoneCountry = phoneNumber.value?.phoneObject?.countryCallingCode;

  if (altPhoneNumber.value?.phoneObject?.valid) {
    patientCaregiver.value.alternatePhone = altPhoneNumber.value?.value;
    patientCaregiver.value.alternatePhoneCountry = altPhoneNumber.value?.phoneObject?.countryCallingCode;
  } else {
    patientCaregiver.value.alternatePhone = '';
    patientCaregiver.value.alternatePhoneCountry = '';
  }
 
  patientCaregiver.value.birthdate = moment(birthdate.value).format('YYYY-MM-DD');;

  $q.loading.show()
  const response = await handleApiRequest(() =>
    updateCanaryCaregiverDetail(patientCaregiver.value), null, $q
  ).finally(() => {
    $q.loading.hide()
  });

  if (!response?.result) {
    //error return
  } else {
    var nextRoute = await getNextActivationRoute($q);
    router.push({ name: nextRoute }).catch(() => { });
  }
};

async function onBackClick() {
  router.go(-1);
}

async function toCanaryPatientCaregiver(response: CanaryPatientCaregiver) {
  if (!response) return null;

  const phoneCountryCallingCode = Number(response.phoneCountry) > 0
    ? response.phoneCountry
    : defaulCountryCallingCode;
  const countryCodePhoneNumber = `+${phoneCountryCallingCode}${response.phone ?? ''}`;
  phoneNumber.value.value = countryCodePhoneNumber;

  const alternatePhoneCountryCallingCode = Number(response.alternatePhoneCountry) > 0
    ? response.alternatePhoneCountry
    : defaulCountryCallingCode;
  const altCountryCodePhoneNumber = `+${alternatePhoneCountryCallingCode}${response.alternatePhone ?? ''}`;
  altPhoneNumber.value.value = altCountryCodePhoneNumber;

  const responseBirthDate = new Date(response?.birthdate);
  if (responseBirthDate.getFullYear() > 1900) {
    birthdate.value = responseBirthDate;
  }

  return {
    firstName: response?.firstName,
    middleName: response?.middleName,
    lastName: response?.lastName,
    email: response?.email,
    phoneCountry: phoneCountryCallingCode,
    phone: phoneNumber.value.value,
    alternatePhoneCountry: alternatePhoneCountryCallingCode,
    alternatePhone: altCountryCodePhoneNumber,
    city: response?.city,
    country: response?.country,
    birthdate: response?.birthdate,
    relationshipToPatient: response?.relationshipToPatient,
  } as CanaryPatientCaregiver;
}

function getDefaultCareGiver(): CanaryPatientCaregiver {
  return {
    firstName: '',
    lastName: '',
    city: '',
    email: '',
    country: localStore.region,
    phone: '',
    phoneCountry: defaulCountryCallingCode,
    relationshipToPatient: '',
    alternatePhone: '',
    alternatePhoneCountry: defaulCountryCallingCode,
    birthdate: '',
  };

}
</script>

<template>
  <q-page padding>
    <q-form ref="careGiverForm" greedy>
      <MultiViewLayout :on-back-click="onBackClick"
                       showFooter>
        
        <!-- Title -->
        <template #heading>
          {{ $t('piq_caregiver_details') }}
        </template>

        <!-- View Unique Content -->
        <template #default>
          
          <!-- Name -->
          <div class="row form-row">
            
            <!-- First -->
            <div class="col">
              <zbm-input-with-validation v-model="patientCaregiver.firstName"
                                         autofocus
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('FirstName')"
                                         :rules="[$zb.validators.required('survey_answer_required'), $zb.validators.maxLen(64)]" />
            </div>
            
            <!-- Middle -->
            <div class="col">
              <zbm-input-with-validation v-model="patientCaregiver.middleName"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_middlename')"
                                         :rules="[$zb.validators.maxLen(64)]" />
            </div>
            
            <!-- Last -->
            <div class="col">
              <zbm-input-with-validation v-model="patientCaregiver.lastName"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('LastName')"
                                         :rules="[$zb.validators.required('survey_answer_required'), $zb.validators.maxLen(64)]" />
            </div>
          </div>

          <!-- Phones -->
          <div class="row form-row">
            
            <!-- Mobile -->
            <div class="col-auto">
              <zbm-telephone-with-validation v-model="phoneNumber"
                                             :label="$t('activate_phone_number')"
                                             :patient-country-code="defaulCountryCode"
                                             :rules="[$zb.validators.phoneRequired('survey_answer_required')]" />
            </div>
            
            <!-- Additional -->
            <div class="col-auto">
              <zbm-telephone-with-validation v-model="altPhoneNumber"
                                             :label="$t('piq_additionalphone')"
                                             :patient-country-code="defaulCountryCode" />
            </div>
          </div>

          <!-- Email -->
          <div class="row form-row">
            <div class="col-auto">
              <zbm-input-with-validation v-model="patientCaregiver.email"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormEl300"
                                         :label="$t('email_address')"
                                         :rules="[$zb.validators.required('activate_profile_email_required'), $zb.validators.email()]"
                                         type="email" />
            </div>
          </div>

          <!-- DOB, Relationship -->
          <div class="row form-row">
            
            <!-- Date of Birth -->
            <div class="col-auto">
              <zbm-date-picker v-model="birthdate"
                               :label="$t('activate_date_of_birth')"
                               :maxDate="maxDate"
                               placeholder=" "
                               :rules="[$zb.validators.required()]" />
            </div>
            
            <!-- Relationship to Patient -->
            <div class="col">
              <zbm-input-with-validation v-model="patientCaregiver.relationshipToPatient"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_relationtopatient')"
                                         :rules="[$zb.validators.maxLen(128), $zb.validators.required('survey_answer_required')]" />
            </div>
          </div>

          <!-- City, State -->
          <div class="row form-row">

            <!-- City -->
            <div class="col">
              <zbm-input-with-validation v-model="patientCaregiver.city"
                                         :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                         :label="$t('piq_address_city')"
                                         :rules="[$zb.validators.maxLen(25)]" />
            </div>

            <!-- State -->
            <div class="col-auto">
              <zbm-select-with-validation v-model="patientCaregiver.country"
                                          :label="$t('piq_country')"
                                          option-label="text"
                                          option-value="value"
                                          :options="countryList.countryOptions"
                                          :rules="[$zb.validators.required()]" />
            </div>

          </div>
        </template>

        <template v-slot:footer>
          <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
               :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
            <ProgressBar :backEnabled="true" :progress="80" v-model:nextEnabled="formIsValid"
                         @on-back-click="onBackClick" @on-next-click="onNextClick" />
          </Box>
        </template>
      </MultiViewLayout>
    </q-form>
  </q-page>
</template>

<style lang="scss" scoped></style>
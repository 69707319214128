<script lang="ts" setup>
import { ref } from 'vue';
import { UserPolicyType } from 'src/constants/enums';
import UserPolicySignOff from 'src/pages/Activation/components/UserPolicySignOff.vue';

const policyType = ref(UserPolicyType.UserPolicyEndUserLicenseAgreement);
</script>

<template>
  <q-page padding>
    <UserPolicySignOff :checkBoxLabelLocalizerKey="'activate_eula_read'"
                       :headingLocalizerKey="'activate_eula'"
                       :modalBodyLocalizerKey="'activate_eula_checkbox_text'"
                       :nextRoute="'Activate.GlobalConsent'"
                       :policyType="policyType"
                       :progress="52"
                       :scrollToRead="true"/>
  </q-page>
</template>
<script lang="ts" setup>
import { EngagementSurveyTypeEnum } from '@/constants/survey';
import { resetProcedureRelatedState } from '@/services/userService';
import { useQuasar } from 'quasar';
import { getPatientProfileData } from 'src/api/endpoints/userWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { loadPatientInfoAndTasks } from 'src/pages/TheDataHydrator';
import { useMessagesStore, useSessionStore } from 'src/store';
import { useDashboardStore } from 'src/store/dashboardModule';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const dashboardStore = useDashboardStore();
const $q = useQuasar()
const router = useRouter()

const sessionStore = useSessionStore();
const messagesStore = useMessagesStore();

const props = defineProps({
  reload: {
    type: String,
    default: 'true'
  }
})

onMounted(async () => {
  if (props.reload === 'true') {
    // When procedure is changed we reset procedure data
    resetProcedureRelatedState()
  } else {
    // Reload Interrupts for later usages
    const patientResponse = await handleApiRequest(() => getPatientProfileData(true), null, $q);

    if (patientResponse) {
      dashboardStore.loadPatient(patientResponse.result);
    }
  }

  $q.loading.show()

  try {

    await loadPatientInfoAndTasks(sessionStore, messagesStore, dashboardStore, $q);

    $q.loading.hide()

    // some() can't be used with async/await directly, so we need to use a for loop
    var hasInterruptRedirect = false;
    for (const interrupt of dashboardStore.patient?.interruptData.interrupts) {
      if (interrupt.type === 'careteam-data-share-consent') {
        router.replace({ name: 'AboutYourData', params: { reload: props.reload } });
        hasInterruptRedirect = true;
        break;
      } else if (interrupt.type === 'Pain_and_Opioid_Pre_Op' || interrupt.type === 'Pain_and_Opioid_Post_Op') {
        router.replace({ name: 'PainRating', params: { reload: props.reload } });
        hasInterruptRedirect = true;
        break;
      } else if (interrupt.type === EngagementSurveyTypeEnum.day42 || interrupt.type === EngagementSurveyTypeEnum.day42NoPredictions) {
        router.replace({ name: 'Engagement', params: { surveyId: interrupt.surveyId } });
        hasInterruptRedirect = true;
        break;
      } else if (interrupt.type === 'Communication_Preference' && !dashboardStore.skipCommunicationPreferencesInterrupt) {
        router.replace({ name: 'AboutYourCommunicationPreferences' });
        hasInterruptRedirect = true;
        break;
      }
    }

    if (!hasInterruptRedirect) {
      router.replace({ name: 'Dashboard.Home', params: { reload: props.reload } });
    }
  } catch (error) {
    $q.loading.hide()
  }
})
</script>

<template>
  <div></div>
</template>
<template>
  <q-page padding>
    <AuthenticateLayout withHeader>
      <template v-slot:title>{{ $t('login_password_forgot') }}</template>
      <template v-slot:heading>{{ $t(hasSimplePatientPasscode ? 'forgot_passcode_create_new_code' : 'password_create')
        }}</template>
  
      <template v-slot:default>
        <PasswordConfirmWithValidation ref="passwordComponent" v-model="passwordResult" :initialPlaceholder="hasSimplePatientPasscode ? 'forgot_passcode_create_new_code' : 'password_create'
        " :confirmationPlaceholder="hasSimplePatientPasscode ? 'activate_confirm_passcode' : 'password_confirm'
        " :hasPin="hasSimplePatientPasscode" @on-valid-password="onNextClick" @validate="validateResult" />
      </template>
  
      <template v-slot:actions>
        <ProgressBar @on-back-click="$router.go(-1)" :progress="100" :nextEnabled="passwordResult.isValid"
          @on-next-click="onNextClick" />
      </template>
    </AuthenticateLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { makeEmptyPasswordConfirmWithValidationResult, type PasswordConfirmWithValidationResult } from 'src/utils/componentFactories';
import { useActivationStore, useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { setPassword } from 'src/api/endpoints/registrationWeb';
import { ClientFeatureType } from 'src/constants/enums';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import PasswordConfirmWithValidation from 'src/components/form/PasswordConfirmWithValidation.vue';
import {computed, ref} from 'vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter()
const activationStore = useActivationStore()
const sessionStore = useSessionStore()

const previousRoute = ref('')
const passwordResult = ref(makeEmptyPasswordConfirmWithValidationResult())

const onNextClick = async () => {
  if (passwordResult.value.isValid) {
    const password = passwordResult.value.password;
    const response = await handleApiRequest(() => setPassword(password), null, $q);
    if (response?.result) {
      activationStore.setIsRecoveringPassword(true);
      activationStore.setPasswordWasReset(true);
      router.replace({ name: 'Authenticate', query: { returnRouteName: 'Login' } });
    }
  }
}

const hasSimplePatientPasscode = computed( () => {
  return sessionStore.user !== null && sessionStore.user.clientFeatures.includes(ClientFeatureType.SimplePatientPasscode);
});

onMounted(() => {
  sessionStore.setIsHeaderVisible(true);
  (document.querySelectorAll('input:first-child')[0] as HTMLElement).focus();
})

async function validateResult(result: PasswordConfirmWithValidationResult) {
  passwordResult.value.password = result.password;
  passwordResult.value.confirmPassword = result.confirmPassword;
  passwordResult.value.isValid = result.isValid;
}
</script>
<script lang="ts" setup>
import {
  defineProps,
  type PropType
} from 'vue';

const props = defineProps({
  class: {
    type: String
  },
  dark: {
    type: Boolean
  },
  height: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String
  },
  square: {
    type: Boolean
  },
  tag: {
    type: String
  },
  type: {
    type: String as PropType<'text' | 'rect' | 'circle' | 'QBtn' | 'QBadge' | 'QChip' | 'QToolbar' | 'QCheckbox'
      | 'QRadio' | 'QToggle' | 'QSlider' | 'QRange' | 'QInput' | 'QAvatar' | undefined>
  },
  //Obslete 
  skeletonStyle: {
    type: String,
    required: false
  },
  //Obslete 
  repeatCount: {
    type: Number,
    required: false
  },
  width: {
    type: String
  }
});
</script>

<template>

  <!-- QSkeleton -->
  <q-skeleton v-if="loading" animation-speed="3000" :class="props.class" :dark="props.dark" :height="props.height"
    :size="props.size" :square="props.square" :tag="props.tag" :type="props.type" :width="props.width" />

  <!-- Content -->
  <div v-show="!loading" :class="props.class" v-if="$slots.default">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.text-center>.q-skeleton {
  margin-inline: auto;
}
</style>
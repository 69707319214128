<template>
  <div :id="id" ref="$el" />
</template>
<script setup>
import Plotly from 'plotly.js-basic-dist-min';
import events from './events.ts';
import { camelize } from './helper.ts';
import { getCurrentInstance, onUnmounted, useAttrs, ref, computed, watch, nextTick } from 'vue';

const instance = getCurrentInstance()
const attrs = useAttrs()

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  data: {
    type: Array,
  },
  layout: {
    type: Object,
  },
  id: {
    type: String,
    required: false,
    default: null,
  },
})

const $el = ref()
const scheduled = ref(null)
const innerLayout = ref({ ...props.layout })

const options = computed(() => {
  const optionsFromAttrs = Object.keys(attrs).reduce((acc, key) => {
    acc[camelize(key)] = attrs[key];
    return acc;
  }, {});
  return {
    displayModeBar: false,
    responsive: true,
    ...optionsFromAttrs,
  };
})

const init = () => {
  Plotly.newPlot($el.value, props.data, innerLayout.value, options.value);
  events.forEach((evt) => {
    $el.value.on(evt.completeName, evt.handler(instance));
  });
}

onUnmounted(() => {
  if (!$el.value) return;

  events.forEach((event) => $el.value.removeAllListeners(event.completeName));
  Plotly.purge($el.value);
})

watch(() => props.data, (nV, oV) => {
  schedule({ replot: true });
}, { deep: true })


watch(() => options.value, (nV, oV) => {
  if (JSON.stringify(nV) === JSON.stringify(oV)) {
    return;
  }
  schedule({ replot: true });
}, { deep: true })

watch(() => props.layout, (nV, oV) => {
  innerLayout.value = { ...props.layout };
  schedule({ replot: false });
})

const onResize = () => {
  Plotly.Plots.resize($el.value);
}

const schedule = (context) => {
  if (scheduled.value) {
    scheduled.value.replot = scheduled.value.replot || context.replot;
    return;
  }
  scheduled.value = context;
  nextTick(() => {
    const replot = scheduled.value.replot
    scheduled.value = null;
    if (replot) {
      react();
      return;
    }
    relayout(innerLayout.value);
  });
}

const toImage = (options) => {
  const allOptions = { ...getPrintOptions(), ...options }
  return Plotly.toImage($el.value, allOptions);
}

const downloadImage = (options) => {
  const filename = `plot--${new Date().toISOString()}`;
  const allOptions = {
    ...getPrintOptions(),
    ...{ filename },
    ...options
  }
  return Plotly.downloadImage($el.value, allOptions);
}

const getPrintOptions = () => {
  return {
    format: 'png',
    width: $el.value.clientWidth,
    height: $el.value.clientHeight,
  };
}

const react = () => {
  Plotly.react($el.value, props.data, innerLayout.value, options.value);
}

defineExpose({
  init,
  update() {
    onResize()
  },
  reset() {
    Plotly.purge($el.value);
  }
})
</script>

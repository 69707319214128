import type {
  SurveyProgressModel,
  SurveyDefinitionModel,
  SurveyProgressDenormalizedQuestionAndAnswerModel,
} from 'src/types/model';
import type {
  SurveyDefinitionWrapperResponse,
  SurveyPageResponse,
  SurveyProgressResponse,
  SurveyQuestionResponse
} from 'src/types/webContracts';

import { toSurveyPageModel } from './surveyPageAdapter'
import { SurveyTypeEnum } from '@/constants/survey';

type DenormalizedQuestion = {
  page: SurveyPageResponse;
  question: SurveyQuestionResponse;
}

const engagementSurveyTypes = [
  SurveyTypeEnum.Predicted_Progress_Comparisons_Engagement,
  SurveyTypeEnum.Predicted_Progress_Predictions_Engagement,
  SurveyTypeEnum.Predicted_Progress_Day_42_Engagement,
  SurveyTypeEnum.Predicted_Progress_Day_42_Engagement_No_Predictions
];

export function toSurveyProgressModel(
  surveyId: number,
  eventDate: string,
  tickSignature: string,
  surveyDefinition: SurveyDefinitionModel,
  response: SurveyProgressResponse): SurveyProgressModel {

  const pageDefinitions = surveyDefinition.pages
  const questionDefinitions = surveyDefinition.pages.map(p => p.questions).flat()

  const result = {
    surveyId,
    eventDate,
    progressPercentage: 0,
    isComplete: false,
    isDeclining: false,
    tickSignature: tickSignature,
    surveyResponseId: response.surveyResponseId,
    actualAnswers: response.surveyData.questionnaire.pageList
      .map(page => page.questions.map(question => <DenormalizedQuestion>{ page, question }))
      .flat()
      .map(dq => dq.question.actualAnswers.map(a => <SurveyProgressDenormalizedQuestionAndAnswerModel>{
        page: pageDefinitions.find(p => p.pageId === dq.page.pageId),
        question: questionDefinitions.find(q => q.questionId === dq.question.questionId),
        answerId: a.answerId,
        answerFreeformText: a.answerText,
        skipped: a.skipped
      }))
      .flat()
  } as SurveyProgressModel

  return result;
}

export function toSurveyDefinitionModel(response: SurveyDefinitionWrapperResponse): SurveyDefinitionModel {
  const { survey } = response
  const result: SurveyDefinitionModel = {
    surveyId: survey.id,
    surveyType: survey.surveyType,
    name: survey.name,
    description: survey.description,
    header: survey.header,
    footer: survey.footer,
    pages: survey.pageList.map(p => toSurveyPageModel(p, response)),
    isEngagementSurvey: engagementSurveyTypes.some(t => t == survey.surveyType)
  }
  return result
}
<template>
  <div />
</template>

<script lang="ts" setup>
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getRegistrationNextStep, uploadProfilePhoto } from 'src/api/endpoints/registrationWeb';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import { onMounted } from 'vue';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';

const $q = useQuasar()
const router = useRouter()

onMounted(async () => {
  $q.loading.show()
  const uploadResponse = await handleApiRequest(() => uploadProfilePhoto(), null, $q);
  if (uploadResponse?.result) {
    const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
    const nextStep = translateStepToRoute(step?.result as string);
    $q.loading.hide()
    router.replace({
      name: nextStep,
    });
  } else {
    $q.loading.hide()
  }
})
</script>
<template>
  <LegalAndPrivacyLayout :headingLocalizerKey="$t('routine_safety')" :policyType="policyType" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { UserPolicyType } from 'src/constants/enums';
import LegalAndPrivacyLayout from 'src/components/layouts/LegalAndPrivacyLayout.vue';

const policyType = ref(UserPolicyType.UserPolicySafetyInfo);
</script>
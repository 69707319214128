import { getLocalizedStrings } from '../api/endpoints/userWeb';
import { getBestFitCultureProvider } from '../api/endpoints/settingsWeb';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { i18n } from 'src/plugins/i18n'
import moment from 'momentCultured';
import { registerSyntheticMomentCultures } from 'momentCultured';

export const useLocalizationStore = defineStore('localization', () => {
  const locale = ref('en-US');
  const region = ref('US');

  const setLocaleMutation = (newLocale: string) => {
    locale.value = newLocale;
  }

  const setRegionMutation = (newRegion: string) => {
    region.value = newRegion;
  }

  const setLocale = (newLocale: string) => {
    setLocaleMutation(newLocale);
  }

  const setRegion = (newRegion: string) => {
    setRegionMutation(newRegion);
  }

  const loadBestFitCulture = async () => {
    const response = await getBestFitCultureProvider();
    if (response) {
      setLocale(response);
      setRegionMutation(response.substr(3, 2));
    }
  }

  const loadLocalizedStrings = async () => {
    const result = await getLocalizedStrings()

    if (result.locale != null) {
      const strings = result.strings;

      const anyStrings = strings as any
      anyStrings.piq_bs_step_eight = (anyStrings.piq_bs_step_eight?.toString()).replace(/\\/g, '');
      anyStrings.piq_bs_step_four = anyStrings.piq_bs_step_four?.toString().replace(/\\/g, '');
      anyStrings.password_requirements_invalid_chars_ios = anyStrings.password_requirements_invalid_chars_ios.replace('{}', '{ 0 }');

      replaceLegacyString(anyStrings, '%1$d', '{ 0 }');  // TODO: MM-28810, remove replaceLegacyString if kvp have been updated
      replaceLegacyString(anyStrings, '%2$d', '{ 1 }');
      replaceLegacyString(anyStrings, '%3$d', '{ 2 }');
      replaceLegacyString(anyStrings, '%4$d', '{ 3 }');
      replaceLegacyString(anyStrings, '%5$d', '{ 4 }');
      replaceLegacyString(anyStrings, '%1$@', '{ 0 }');
      replaceLegacyString(anyStrings, '%2$@', '{ 1 }');
      replaceLegacyString(anyStrings, '%3$@', '{ 2 }');
      replaceLegacyString(anyStrings, '%4$@', '{ 3 }');
      replaceLegacyString(anyStrings, '%5$@', '{ 4 }');
      replaceLegacyString(anyStrings, '%1$s', '{ 0 }');
      replaceLegacyString(anyStrings, '%2$s', '{ 1 }');
      replaceLegacyString(anyStrings, '%3$s', '{ 2 }');
      replaceLegacyString(anyStrings, '%4$s', '{ 3 }');
      replaceLegacyString(anyStrings, '%5$s', '{ 4 }');
      replaceLegacyString(anyStrings, '%@', '{ 0 }');
      replaceLegacyString(anyStrings, '%d', '{ 0 }');
      replaceLegacyString(anyStrings, '%s', '{ 0 }');

      i18n.global.setLocaleMessage(result.locale, anyStrings);
      i18n.global.locale = result.locale;

      setLocale(result.locale)
      updatePageTitle();
      registerSyntheticMomentCultures(result.locale);
    }
  }

  function replaceLegacyString(obj, oldString, newString) {
    // Iterate over each key in the object
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(oldString, newString);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively call replaceInObject
        replaceLegacyString(obj[key], oldString, newString);
      }
    }
  }

  const routeTitleKeys = ref([]);
  const siteTitle = ref('');

  function updatePageTitle() {
    if (!routeTitleKeys.value && !siteTitle.value) return;
    document.title = [...routeTitleKeys.value.map(key => i18n.global.t(key)), siteTitle.value].join(' - ');
  }

  watch([locale, routeTitleKeys, siteTitle], () => updatePageTitle());

  return {
    locale,
    region,
    loadLocalizedStrings,
    setRegion,
    setLocale,
    loadBestFitCulture,
    routeTitleKeys,
    siteTitle
  }
})
<script lang="ts" setup>
import {
  defineProps,
  onMounted,
  type PropType
} from 'vue';
import {
  BoxWidthEnum,
  BoxBackgroundColorEnum,
  BoxPaddingEnum,
  BoxBorderRadiusEnum,
  BoxMarginEnum,
  BoxDropShadowEnum,
} from 'src/constants/enums';
import { useSessionStore } from 'src/store';
import Box from 'src/components/BoxContainer.vue';

const props = defineProps({
  /*
   * Controls the background color of the box. Options are:
   * accent - sets background color to `#fafafa`; common light grey
   *     used as a background color.
   * linearGradient - sets background color to a linear gradient from
   *     light teal (0%) to blue (100%).
   * secondary - sets background color to blue.
   * transparent - sets background color to transparent.
   * white - sets background color to white.
   */
  contentBoxBkgdColor: {
    type: String as PropType<BoxBackgroundColorEnum>
  },
  /*
   * Controls the border radius of the box. Options are:
   * none - does not set a border radius.
   * default - sets the border radius to 12px, which is the the most
   *     common border radius size for PWE.
   * lg - sets the border radius to 20px.
   */
  contentBoxBrdrRadius: {
    type: String as PropType<BoxBorderRadiusEnum>
  },
  /*
   * Controls the box shadow of the box. Options are:
   * normal - sets the dropshadow to: 0 0 8px 0 rgba($black, 0.2).
   * lg - sets the dropshadow to: 0 0 15px 0 rgba($black, 0.12).
   * xl - sets the dropshadow to: 0 2px 50px 0 rgba($black, 0.23).
   */
  contentBoxDropShadow: {
    type: String as PropType<BoxDropShadowEnum>,
    default: BoxDropShadowEnum.lg
  },
  /*
   * Controls the margin of the box. Options are:
   * mb20 - sets the bottom margin to 20px.
   * mb40 - sets the bottom-margin to 40px.
   */
  contentBoxMargin: {
    type: String as PropType<BoxMarginEnum>,
    default: BoxMarginEnum.mb40
  },
  /*
   * Controls the padding of the box. Options are:
   * p20 - sets the padding to 20px on all sides.
   * p40 - sets the padding to 40px on all sides.
   * p2018 - sets the padding of the vertical sides to 25px and the
   *     horixontal sides to 20px.
   * p303055 - sets the top-padding to 30px, the horizontal sides to
   *     30px and the bottom-padding to 55px.
   * p453565 - sets the top-padding to 50px, the horizontal sides to
   *     35px and the bottom-padding to 65px.
   * p504535 - sets the top-padding to 50px, the horizontal sides to
   *     45px and the bottom-padding to 35px.
   * p505065 - sets the top-padding to 50px, the horizontal sides to
   *     50px and the bottom-padding to 65px.
   */
  contentBoxPadding: {
    type: String as PropType<BoxPaddingEnum>,
    default: BoxPaddingEnum.p504535,
  },
  /*
   * Controls the width value of the box. Options are:
   * xs - sets width to 386px; common width for Exercise Reps in
   *     Exercise views.
   * md - sets width to 700px; common width for Login, Activate, &
   *     Forgot workflows.
   * lg - sets width to 750px; common width for Exercise Videos in
   *     Exercise views, Survey views & Video Visit Start workflow.
   * xl - sets width to 1024px; common width for Dashboard views.
   */
  contentBoxWidth: {
    type: String as PropType<BoxWidthEnum>,
    default: BoxWidthEnum.md,
  },
  dark: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean
  },
  /*
   * Controls if the header component shows or doesn't show. You need to
   * explicitly state `true` or `false`.
   */
  withHeader: {
    type: Boolean,
    default: false
  }
});

const sessionModule = useSessionStore();

onMounted(() => {
  sessionModule.setIsHeaderVisible(props.withHeader);
});
</script>

<template>
  
  <!-- Content -->
  <div class="row justify-center">
    <Box class="col-auto auth-layout__content"
         :box-bkgd-color="props.contentBoxBkgdColor"
         :box-brdr-radius="props.contentBoxBrdrRadius"
         :box-drop-shadow="props.contentBoxDropShadow"
         :box-margin="props.contentBoxMargin"
         :box-padding="props.contentBoxPadding"
         :box-width="props.contentBoxWidth">
      
      <!-- Workflow Title -->
      <h5 v-if="$slots.title"
          class="mb-none">
        <zbm-skeleton-loader :dark="props.dark"
                             height="26px"
                             :loading="props.loading"
                             width="100%">
          <slot name="title" />
        </zbm-skeleton-loader>
      </h5>

      <!-- Heading -->
      <h1 v-if="$slots.heading">
        <zbm-skeleton-loader :dark="props.dark"
                             height="34px"
                             :loading="props.loading"
                             width="100%">
          <slot name="heading" />
        </zbm-skeleton-loader>
      </h1>

      <!-- View Unique Content -->
      <slot />
    </Box>
    <div class="flex-br" />

    <!-- Actions/Progress -->
    <Box class="col-auto auth-layout__actions"
         :box-drop-shadow="props.contentBoxDropShadow"
         :box-padding="$zb.enums.BoxPaddingEnum.p20"
         :box-width="props.contentBoxWidth">
      <slot name="actions" />
    </Box>
  </div>
</template>

<style scoped lang="scss">
  .auth-layout {
    
    // Content
    &__content { // [class*="col"].box
      min-height: 609px;
      
      h1 {
        margin-block-end: 22px;
      }
    }
    
    // Actions/Progress
    &__actions { // [class*="col"].box
      font-size: 20px;
    }
  }
</style>
import axios from 'src/api/axiosConfig'
import type { ApiResponse } from 'src/types/apiTypes';
import type {
  LocalizationResult,
  ValidateActivationCodeModel,
  SendActivationCodeModel,
  PatientProfileData,
  CredentialRequest,
  Alert,
  PatientTasksResult,
  ChangePasswordRequest,
  CommunicationPreferencesRequest,
  CommunicationPreferencesResponse,
  PatientProcedures,
  SessionData,
} from 'src/types/webContracts'
import type { AxiosResponse } from 'axios';
import { ApiRoutes } from '../ApiRoutes'

export async function getUserSession(): Promise<SessionData> {
  const response = await axios.get(ApiRoutes.Login.GetUserSession)
  return response.data.result;
}

export async function getPatientProfileData(isPatientEocLogin : boolean): Promise<AxiosResponse<ApiResponse<PatientProfileData>>> {
  return await axios.get(ApiRoutes.Patient.GetPatientProfileData, { params: { isPatientEocLogin }});
}

export async function getForgottenUserName(): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.get(ApiRoutes.Login.GetForgottenUserName)
  return response;
}

export async function getLocalizedStrings(): Promise<LocalizationResult> {
  const response = await axios.get(ApiRoutes.GetLocalizedStrings)
  return response.data.result;
}

export async function sendActivationCode(model: SendActivationCodeModel): Promise<string> {
  const response = await axios.post(ApiRoutes.Login.SendActivationCode, model)
  return response.data.result;
}

export async function verifyCode(model: ValidateActivationCodeModel): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Login.ValidateActivationCode, model)
  return response;
}

export async function getPatientProcedures(): Promise<AxiosResponse<ApiResponse<PatientProcedures>>> {
  return await axios.get(ApiRoutes.Home.GetPatientProcedures);
}

export async function setSelectedProcedure(eocId: number): Promise<AxiosResponse<ApiResponse<boolean>>> {
  return await axios.post(ApiRoutes.Home.SetSelectedProcedure, eocId);
}

export async function linkCanaryAccount(model: CredentialRequest): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Patient.LinkCanaryAccount, model)
  return response
}

export async function getAlerts(): Promise<AxiosResponse<ApiResponse<Array<Alert>>>> {
  const response = await axios.get(ApiRoutes.Patient.Alerts);
  return response;
}

export async function getTasks(): Promise<AxiosResponse<ApiResponse<PatientTasksResult>>> {
  const response = await axios.get(ApiRoutes.Patient.Tasks);
  return response;
}

export async function ChangePassword(model: ChangePasswordRequest): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Patient.ChangePassword, model);
  return response;
}

export async function setProcedureConsent(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  return await axios.post(ApiRoutes.Patient.SetProcedureConsent);
}

export async function getCommunicationPreferences(): Promise<AxiosResponse<ApiResponse<CommunicationPreferencesResponse>>> {
  return await axios.get(ApiRoutes.Patient.GetCommunicationPreferences);
}

export async function setCommunicationPreferences(model: CommunicationPreferencesRequest) : Promise<AxiosResponse<ApiResponse<string>>> {
  return await axios.post(ApiRoutes.Patient.SetCommunicationPreferences, model);
}

export async function getClientStyles(clientId: number): Promise<AxiosResponse<ApiResponse<string>>> {
  return await axios.get('Login/GetClientStyleCSS', { params: { clientId } });
}
<template>
  <div>
    <!-- Details Graph Paging Navigation -->
    <GraphNav v-model:dateRange="dateRange" :disablePrev="loading" :disableNext="loading" ref="graphNav" />
    <div class="row" v-if="loading">
      <div class="col-12 text-center">
        <SkeletonLoader :loading="loading" width="25%" height="350px" type="rect" />
      </div>
    </div>
    <!-- Details Graph -->
    <div class="row justify-center data__details-graph" v-if="!loading">
      <div class="col">
        <!-- Plotly Bar Graph -->
        <BarGraph v-if="isBarGraph" :plotData="plotData" :unitLabel="unitLabel" ref="graph" />

        <!-- Plotly Scatter Plot -->
        <FilledScatter v-if="isFilledScatter" :plotData="plotData" :unitLabel="unitLabel" ref="graph" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, defineProps, ref, watch, computed, defineExpose, nextTick } from 'vue';

import { type PatientDailyStatisticType } from 'src/types/webContracts';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getPatientMeasurementStats } from 'src/api/endpoints/statisticsWeb';
import { DayOfWeekLocalizerMap, MeasurementTypeEnum } from 'src/constants/measurementTypes';
import moment from 'momentCultured';
import GraphNav from './GraphNav.vue';
import BarGraph from 'src/components/graphs/BarGraph.vue';
import FilledScatter from 'src/components/graphs/FilledScatter.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { i18n } from '@/plugins/i18n';
import { useQuasar, debounce } from 'quasar';

const $q = useQuasar()

const props = defineProps({
  measurementType: {
    type: String as PropType<MeasurementTypeEnum>,
    required: true
  },
  unitLabel: { type: String, default: '' },
  source: { type: String, required: true },
  device: { type: Number, required: true },
});

const dateRange = ref();

const graph = ref();
const graphNav = ref();
const loading = ref(false);
const measurementData = ref({
  measurementDataTypeName: '',
  localizedMeasurementDataTypeName: '',
  values: [],
} as PatientDailyStatisticType);

let isInitialized = false

watch(() => dateRange.value, () => {
  if (isInitialized) {
    load();
  }
});


const isBarGraph = computed(() => {
  switch (props.measurementType) {
    case MeasurementTypeEnum.PiqMaxSteps:
    case MeasurementTypeEnum.PiqCadence:
    case MeasurementTypeEnum.PiqAvgStrideLength:
    case MeasurementTypeEnum.PiqAvgDistance:
      return true;
    default:
      return false;
  }
});

const isFilledScatter = computed(() => {
  switch (props.measurementType) {
    case MeasurementTypeEnum.PiqAvgWalkSpeed:
    case MeasurementTypeEnum.PiqAvgKneeROM:
      return true;
    default:
      return false;
  }
});

const plotData = computed(() => {
  const x: string[] = [];
  const y: (number | null)[] = [];

  measurementData.value.values.forEach((m) => {
    const dayNumber = moment(m.recordedDate, 'YYYY-MM-DD').day();
    const localizedDayAbbrev = i18n.global.t(DayOfWeekLocalizerMap[dayNumber]).toString();
    x.push(localizedDayAbbrev);
    y.push(m.dataValue || 0);
  });

  return [
    {
      x,
      y,
    },
  ];
});

const load = debounce(async () => {
  if (dateRange.value.startDate) {
    loading.value = true;
    const response = await handleApiRequest(() =>
      getPatientMeasurementStats(
        dateRange.value.startDate,
        dateRange.value.endDate,
        props.measurementType,
        props.source,
        props.device
      ), null, $q
    );

    const measurementTypeStats = response?.result[props.measurementType];
    if (measurementTypeStats) {
      measurementData.value = measurementTypeStats;
    } else {
      measurementData.value = {
        measurementDataTypeName: props.measurementType,
        localizedMeasurementDataTypeName: '',
        values: [],
      };
    }

    loading.value = false
    nextTick(() => graph.value.init())
  }
}, 250)

defineExpose({
  async initialize() {
    if (!isInitialized) {
      graphNav.value.init()
      await load()
      isInitialized = true
    }
  },
  reset() {
    isInitialized = false
    graph.value.reset()
  }
});


</script>
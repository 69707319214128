import { i18n } from './i18n'
import {
  hasValue, isNumber, minLength, maxLength, hasInvalidSpecialCharacter, hasLowercase, hasNum,
  isPoBox, hasUppercase, hasValidSpecialCharacter, isAlphaNumeric, isEmailFormat,
  hasNoSpaces
} from './regex-functions';
import moment from '@/plugins/moment-cultured';

export const required = (errorMessageKey = null) => (val) => {
  return hasValue(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const phoneRequired = (errorMessageKey) => (phoneObject: { number: string }) => {
  if (!phoneObject) return true
  if (hasValue(phoneObject.number)) return true;

  const key = errorMessageKey || 'validations_messages_required',
    message = i18n.global.t(key);

  return (key === message) ? true : message;
}

export const requiredIf = (conditionCallback, errorMessageKey = null) => (val) => {
  return (!conditionCallback() || hasValue(val)) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const alphaNum = (errorMessageKey = null) => (val) => {
  return isAlphaNumeric(val) || i18n.global.t(errorMessageKey || 'activate_reqs_no_spec_chars')
}

export const number = (errorMessageKey = null) => (val) => {
  return isNumber(val) || i18n.global.t(errorMessageKey || 'survey_integer')
}

export const email = (errorMessageKey = null) => (val) => {
  return isEmailFormat(val) || i18n.global.t(errorMessageKey || 'activate_profile_email_invalid')
}

export const date = (errorMessageKey = null) => (val) => {
  //form_errors_invalid_date
}

export const minLen = (len, errorMessageKey = null) => (val) => {
  return minLength(val, len) || i18n.global.t(errorMessageKey || 'survey_min_length', [len]) //
}

export const maxLen = (len, errorMessageKey = null) => (val) => {
  return maxLength(val, len) || i18n.global.t(errorMessageKey || 'survey_max_length', [len])
}

export const betweenLen = (min, max, errorMessageKey = null) => (val) => {
  return minLength(val, min) && maxLength(val, max) || i18n.global.t(errorMessageKey || 'survey_range_bounds', [minLength, maxLength])
}

export const minVal = (min, errorMessageKey = null) => (val) => {
  return (val >= min) || i18n.global.t(errorMessageKey || 'form_errors_parsley_min_val', [min])
}

export const maxVal = (max, errorMessageKey = null) => (val) => {
  return (val <= max) || i18n.global.t(errorMessageKey || 'form_errors_parsley_max_val', [max])
}

export const betweenVal = (min, max, errorMessageKey = null) => (val) => {
  return (val >= min && val <= max) || i18n.global.t(errorMessageKey || 'survey_range_bounds', [min, max])
}

export const phoneTelInput = (errorMessageKey = null) => (phoneObject) => {
  if (!phoneObject || !phoneObject.number) return true;
  return (phoneObject.valid) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const dateInput = (errorMessageKey = null) => (dateObject) => {
  if (!dateObject) return true;

  const momentDate = moment(dateObject);
  const isValid = momentDate.isValid() && momentDate.year().toString().length === 4;

  return isValid || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const isNotPOBox = (errorMessageKey = null) => (val) => {
  return !isPoBox(val) || i18n.global.t(errorMessageKey || 'validations_messages_required');
}

export const postalCode = (errorMessageKey = null) => (val) => {
  const regex = /^\d{5}(?:[-\s]\d{4})?$/;     // regular expression for zip code
  return regex.test(val) || i18n.global.t(errorMessageKey || 'activate_profile_postal_code_invalid');
};

export const equals = (val2, errorMessageKey = null) => (val) => {
  return val === val2 || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const containsValidSpecialCharacter = (errorMessageKey = null) => (val) => {
  return hasValidSpecialCharacter(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const omitsInvalidSpecialCharacter = (errorMessageKey = null) => (val) => {
  return !hasInvalidSpecialCharacter(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const omitsExpression = (exclusions = [] as Array<string>, errorMessageKey = null) => (val: string) => {
  const regExTest = exclusions.every(c => {
    const b = new RegExp(c, 'gi');
    return !b.test(val);
  });
  return regExTest || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const noSpaces = (errorMessageKey = null) => (val) => {
  return hasNoSpaces(val) || errorMessageKey || 'activate_reqs_no_spec_chars';
}

export const containsNum = (errorMessageKey = null) => (val) => {
  return hasNum(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const containsLowercase = (errorMessageKey = null) => (val) => {
  return hasLowercase(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const containsUppercase = (errorMessageKey = null) => (val) => {
  return hasUppercase(val) || i18n.global.t(errorMessageKey || 'validations_messages_required')
}

export const dummyRule = (errorMessageKey = null) => () => { errorMessageKey || 'Overwrite this rule with custom logic' } 
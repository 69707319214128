import axios from 'src/api/axiosConfig'
import { ApiResponse } from 'src/types/apiTypes'
import { MessageThread, User } from 'src/types/interfaces';
import { CreateMessage } from 'src/types/webContracts';
import { AxiosResponse } from 'axios'
import { ApiRoutes } from '../ApiRoutes'


export async function getMessages(): Promise<AxiosResponse<ApiResponse<MessageThread>>> {
  const response = await axios.get(ApiRoutes.Message.GetMessages);
  return response;
}

export async function sendMessage(model: CreateMessage): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Message.SendMessage, model);
  return response;
}

export async function markAllMessagesAsRead(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.put<ApiResponse<boolean>>(ApiRoutes.Message.MarkAllMessagesAsRead);
  return response;
}

export async function getCareTeam(careTeamId: number): Promise<AxiosResponse<ApiResponse<User[]>>> {
  const params = { careTeamId: careTeamId };
  const response = await axios.get(ApiRoutes.Message.GetCareTeam, { params })
  return response;
}
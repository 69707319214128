import { ApiMockModesEnum } from 'src/constants/enums';
import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', () => {
  const apiMockMode: ApiMockModesEnum = (import.meta.env.VUE_APP_MOCK_API as ApiMockModesEnum) || ApiMockModesEnum.NoMocks;
  const apiMockDelayResponse: number = parseInt(import.meta.env.VITE_VUE_APP_MOCK_API_DELAY as string, 10) || 0;
  const appTitle: string = import.meta.env.VITE_VUE_APP_TITLE as string;
  const cweUrl = ''; // Fetched from server

  return {
    apiMockMode,
    apiMockDelayResponse,
    appTitle,
    cweUrl
  }
})
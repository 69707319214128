<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import Box from 'src/components/BoxContainer.vue';
import { useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getRoutine, updateExerciseCompletion } from 'src/api/endpoints/routineWeb';
import type { ExerciseModel, RoutineModel, UpdateExerciseCompleteRequest } from 'src/types/webContracts';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { useRouter } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { useQuasar } from 'quasar';

const sessionStore = useSessionStore();
const router = useRouter();
const $q = useQuasar()

const props = defineProps({
  tickSignature: {
    type: String,
    required: true,
  },
  itemId: {
    type: String,
    required: true,
  },
  exerciseId: {
    type: String,
    required: true,
  },
})

const countRepsEntered = ref<number | null>(null);
const selectedRoutine = ref<RoutineModel | null>(null);
const selectedExercise = ref<ExerciseModel | null>(null);
const isLoading = ref<boolean>(true);
const repCountInput = ref(null)

// ######## methods ########
function onBackClick() {
  redirectToRoutine();
}

function buildRepsDescription(exercise: ExerciseModel | null) {
  let result = '';

  if (exercise) {
    const maxReps = exercise.maxReps;

    if (exercise.unitOfMeasure === 1) {
      result = (
        maxReps === 1
          ? i18n.global.t('vue3_routine_rep_count_pluralization_one', [maxReps])
          : i18n.global.t('vue3_routine_rep_count_pluralization_many', [maxReps])
      ).toString();
    } else {
      result = (
        maxReps === 1
          ? i18n.global.t('vue3_routine_min_count_pluralization_one', [maxReps])
          : i18n.global.t('vue3_routine_min_count_pluralization_many', [maxReps])
      ).toString();
    }
  }

  return result;
}

async function updateExerciseCompletionClick() {
  const isValid = repCountInput.value.validate();

  if (!isValid) {
    return;
  }

  if (
    countRepsEntered.value != null &&
    selectedExercise.value?.title &&
    selectedExercise.value?.exerciseContentId
  ) {
    $q.loading.show()
    const request: UpdateExerciseCompleteRequest = {
      eventId: parseInt(props.itemId, 10),
      exerciseRecordId: parseInt(props.exerciseId, 10),
      exerciseContentId: selectedExercise.value.exerciseContentId,
      numReps: countRepsEntered.value,
      maxReps: selectedExercise.value.maxReps,
      exerciseName: selectedExercise.value.title,
      tickSignature: props.tickSignature,
      completedWorkoutId: selectedExercise.value.completedWorkoutId,
      sequence: selectedExercise.value.sequence,
    };
    const response = await handleApiRequest(() => updateExerciseCompletion(request), null, $q);

    if (response?.result) {
      redirectToRoutine();
    }

    $q.loading.hide()
  }
}

function redirectToRoutine() {
  router.push({
    name: 'Routine.Routine',
    params: {
      tickSignature: props.tickSignature,
      itemId: props.itemId,
    },
  });
}

const fieldLabelGenerator = computed((): string => {
  return selectedExercise.value?.unitOfMeasure == 1
    ? i18n.global.t('routine_reps_enter')
    : i18n.global.t('routine_mins_enter');
});

const saveText = computed((): string => {
  return selectedExercise.value?.unitOfMeasure == 1
    ? i18n.global.t('pwe_save_reps_routines')
    : i18n.global.t('pwe_save_time_routines');
});

onMounted(async () => {
  const languageId = sessionStore.user?.preferredLanguageId || 0;

  const routine = await handleApiRequest(() =>
    getRoutine(parseInt(props.itemId), languageId, props.tickSignature), null, $q
  );

  selectedRoutine.value = routine?.result ?? null;

  selectedExercise.value =
    routine?.result?.exercises.find(
      (x) => parseInt(x.id.toString(), 10) === parseInt(props.exerciseId, 10)
    ) ?? null;

  isLoading.value = false;
});
</script>

<template>
  <q-page padding>
    <MultiViewLayout class="exercise"
                     :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.transparent"
                     :box-padding="$zb.enums.BoxPaddingEnum.none"
                     :onBackClick="onBackClick">

      <!-- Section Title -->
      <template #title>{{ $t('gen_routine') }}</template>

      <!-- Title -->
      <template #heading>{{ selectedExercise?.title }}</template>

      <!-- View Unique Content -->
      <template #default>
        
        <!-- Video -->
        <div class="row exercise__video">
          <div class="col">
            <zbm-skeleton-loader height="423px" :loading="isLoading" width="100%">
              <video v-if="selectedExercise?.videoUrl" controls controlslist="nodownload nofullscreen"
                disablepictureinpicture :poster="selectedExercise?.videoPoster">
                <source v-bind:src="selectedExercise?.videoUrl" type="video/mp4" />
              </video>
            </zbm-skeleton-loader>
          </div>
        </div>

        <!-- Reps -->
        <zbm-skeleton-loader height="332px"
                             :loading="isLoading"
                             width="100%" >
          <div class="row justify-center exercise__reps">
            <Box :box-padding="$zb.enums.BoxPaddingEnum.p40"
                 :box-width="$zb.enums.BoxWidthEnum.xs"
                 class="col column items-center">
              
              <!-- Assigned -->
              <h4 class="mb-20">{{ buildRepsDescription(selectedExercise) }}</h4>

              <!-- Record -->
              <zbm-number-input-with-validation v-model="countRepsEntered"
                                                :input-width="$zb.enums.FormElWidthEnum.qFormEl100"
                                                :label="fieldLabelGenerator"
                                                :max="selectedExercise?.maxReps"
                                                :min="0"
                                                outlined
                                                ref="repCountInput"
                                                :rules="[$zb.validators.required()]" />
              
              <!-- Help -->
              <p :class="countRepsEntered == null ? 'visible' : 'invisible'">{{ $t('survey_range_tap') }}</p>
              
              <!-- Save -->
              <zbm-btn @click="updateExerciseCompletionClick"
                       :color="$zb.enums.ButtonStdColorEnum.accentText"
                       :height="$zb.enums.ButtonHeightEnum.lg"
                       :label="saveText" />
            </Box>
          </div>
        </zbm-skeleton-loader>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<style scoped lang="scss">
.exercise {
  
  // Video
  &__video {
    margin-bottom: 40px;

    >[class*="col"] {
      font-size: 0;

      video {
        $width: 750px;

        width: 100%;
        max-width: $width;
        height: auto;
        max-height: calc($width * .564);
        object-fit: contain;
      }
    }
  }

  // Reps
  &__reps {
    text-align: center;
    
    // Assigned
    h4 {
      font-weight: map-get($text-weights, medium);
    }
    
    
    // Record
    label:not(.q-field) {
      padding-inline-start: 0;
    }
    :deep(.q-field__native) {
      text-align: center;
    }

    // Help
    p {
      font-style: italic;
      font-weight: map-get($text-weights, bold);
      font-size: 26px;
      line-height: 56px;
      color: map-get($co-brand-clrs, primitives-foundation-50);
      margin-block-end: 40px;
    }
  }
}
</style>
import axios from 'src/api/axiosConfig'
import { UserPolicyType } from 'src/constants/enums';
import { ApiResponse } from 'src/types/apiTypes';
import { AxiosResponse } from 'axios';
import { ApiRoutes } from '../ApiRoutes'
import { UserPolicy, ValidateUserNameResult } from 'src/types/webContracts'

export async function createPassword(password: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post<ApiResponse<boolean>>(ApiRoutes.Registration.CreatePassword, JSON.stringify(password))
  return response
}

export async function setPassword(password: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post<ApiResponse<boolean>>(ApiRoutes.Registration.SetPassword, JSON.stringify(password))
  return response
}

export async function updatePolicy(policyType: UserPolicyType, optOut: boolean, dismissed = false): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const model = { policyType, optOut, dismissed };
  const response = await axios.post<ApiResponse<boolean>>(ApiRoutes.Registration.UpdatePolicy, model)
  return response
}

export async function getLocalizedPolicy(policyType: UserPolicyType): Promise<AxiosResponse<ApiResponse<UserPolicy>>> {
  const params = { policyType };
  const response = await axios.get<ApiResponse<UserPolicy>>(ApiRoutes.Registration.GetLocalizedUserPolicy, { params })
  return response
}

export async function createProfileInformation(email: string, postalCode?: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.put<ApiResponse<boolean>>(ApiRoutes.Registration.PutProfileInformation, { email, postalCode });
  return response
}

export async function createUserName(username: string): Promise<AxiosResponse<ApiResponse<ValidateUserNameResult>>> {
  const response = await axios.post(ApiRoutes.Registration.UserName, JSON.stringify(username))
  return response
}

export async function uploadProfilePhoto(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Registration.UploadProfilePhoto)
  return response
}

export async function getRegistrationNextStep(): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.post(ApiRoutes.Registration.GetRegistrationNextStep)
  return response
}
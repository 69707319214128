<script setup lang="ts">
import {defineProps} from 'vue';

const props = defineProps({
  avatarUrl: {
    type: String
  },
  color: {
    type: String
  },
  displayName: {
    type: String
  },
  hasAvatarImage: {
    type: Boolean,
    required: true
  },
  iconBorder: {
    type: Boolean,
    default: true,
  },
  iconFontSize: {
    type: String
  },
  iconName: {
    type: String,
    default: 'fas fa-user'
  },
  iconTopMargin: {
    type: String
  },
  size: {
    type: String,
    required: true
  }
});
</script>

<template>
  <q-avatar :class="{ 'no-border': !props.iconBorder }"
            :text-color="props.color"
            :size="props.size">
    <img v-if="props.hasAvatarImage"
         :alt="props.displayName"
         :src="props.avatarUrl" />
    <q-icon v-else
            :name="props.iconName"
            :size="props.iconFontSize" />
  </q-avatar>
</template>

<style scoped lang="scss">
.q-icon {
  margin-block-start: v-bind(iconTopMargin);
}
</style>